import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private api: ApiService
  ) {}

    // GET /api/trakkers
    public getAllIcons(): Observable<any> {
      return this.api.get(
        'icons/'
      );
    }
  
}
