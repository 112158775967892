import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSOS'
})
export class IsSOSPipe implements PipeTransform {

  transform(object: any): any {
    if (object.hasOwnProperty("AlertType")) {
      return object.AlertType === 'SOS';
    }
    if (object.hasOwnProperty("TrakkerId")) {
      object.forEach(element => {
        if (element.TrakkerEvent.AlertType === 'SOS') {
          return true;
        }
      });
      return false;
      // return object.ActiveAlerts.some(a => a.AlertType === 'SOS');
    }

    return false;
  }

}
