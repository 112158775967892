import { Injectable } from '@angular/core';
import { LocalstoreService } from '../_shared/localstore.service';
import { Constants } from '../app.config';
import { Router } from '@angular/router';



@Injectable()
export class JwtService {

  constructor(private localStore: LocalstoreService, private appConstants: Constants, private router: Router){}

  getToken(): String {
    return this.localStore.get('clientToken');
  }

  saveToken(token: String) {
    this.localStore.store('clientToken', token);
  }

  destroyToken() {
    this.localStore.remove('clientToken');
  }

  /* Clears all cookies and redirects user to login page (destructive!) */
  flushCookiesAndLeave = () => {
    this.destroyToken();
    this.localStore.clear();
    this.router.navigate(['/dashboard']);
  }

}
