<!-- OPERATION TIME -->
<div class="operation-time">

  <div class="boxlabel">
    <p [translate]>Page.Labels.OPERATION_TIME</p>
  </div>

  <div class="objectfield">

    <ng-container *ngIf="LatestEvent">
      <ng-container *ngIf="LatestEvent.StartDateTime && !LatestEvent.StopDateTime">
        <p [translate]>Page.Messages.ONGOING_ACTIVITY</p>

        <p><span [translate]>Page.Labels.STARTED</span>: <dateTime
            [utcDateTime]="LatestEvent.StartDateTime" [locales]="dateTimeLocales"
            [options]="dateTimeOptions"></dateTime> ({{ LatestEvent.StartDateTime |
          ago:translationsAgo }})</p>
      </ng-container>
      <ng-container *ngIf="LatestEvent.StopDateTime">
        <p [translate]>Page.Messages.COMPLETED_ACTIVITY</p>
        <p>
          <span [translate]>Page.Labels.DURATION</span><span>: </span>
          <span>{{ LatestEvent | otDuration:translationsAgo:"exact" }}</span>
        </p>
        <p>
          <span [translate]>Page.Labels.PERIOD</span><span>: </span>
          <dateTime [utcDateTime]="LatestEvent.StartDateTime" [locales]="dateTimeLocales"
            [options]="dateTimeOptions"></dateTime>
          <span> - </span>
          <dateTime [utcDateTime]="LatestEvent.StopDateTime" [locales]="dateTimeLocales"
            [options]="dateTimeOptions"></dateTime>
        </p>
      </ng-container>
      <div class="horiz">
        <p [translate]>Page.Labels.COMMENT<span>: </span></p>
        <div *ngIf="isEditingComment == false">
          <span *ngIf="LatestEvent.Comment">{{ LatestEvent.Comment }}</span>
          <span *ngIf="!LatestEvent.Comment" [translate]>Page.Labels.NO_COMMENT</span>
          <a (click)="StartEditOperationTimeComment(LatestEvent.AssetEventId)">
            <img class="ico pointer" src="/assets/icons/svg/edit.svg" alt="edit-comment"
              ngbTooltip="{{ 'Page.Labels.EDIT_COMMENT' | translate }}">
          </a>
        </div>
        <div *ngIf="isEditingComment" class="ot-comment">
          <input type="text" value="{{ LatestEvent.Comment }}" placeholder="{{ 'Page.Placeholders.ADD_YOUR_COMMENT' | translate }}"
            id="comment-input">
          <img class="ico pointer" src="/assets/icons/svg/save.svg" alt="save-comment"
            ngbTooltip="{{ 'Page.Labels.SAVE_COMMENT' | translate }}"
            (click)="saveComment(LatestEvent.AssetEventId)">
        </div>
      </div>
    </ng-container>

    <p *ngIf="!LatestEvent" [translate]>Page.Messages.NO_ACTIVITY_REGISTERED</p>

    <!-- <div class="ot-counter-box">
      <div *ngFor="let counter of trakker.Asset.OperationTimeCounters" class="ot-counter">
        <p>{{ counter.OperationTimeCounterId }}</p>
        <p>{{ counter.Name }}</p>
        <p>{{ counter.IntervalType }}</p>
        <div class="buttons">
          <div *ngIf="false" class="svgee" ngbTooltip="{{ 'Page.Labels.EDIT' | translate }}"><img
              src="assets/icons/svg/edit.svg" alt="edit" (click)="editCounter()"></div>
          <div *ngIf="false" class="svgee" ngbTooltip="{{ 'Page.Labels.RESET_COUNTER' | translate }}"><img
              src="assets/icons/svg/refresh.svg" alt="reset" (click)="refreshCounter()"></div>
          <div *ngIf="false" class="svgee" ngbTooltip="{{ 'Page.Labels.DELETE_COUNTER' | translate }}"><img
              src="assets/icons/svg/delete.svg" alt="delete" (click)="deleteCounter()"></div>
        </div>
      </div>
    </div> -->

  </div>
</div>