import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../_shared/shared.module';
import { MapModule } from '../map/map.module';
import { CountUpModule } from 'ngx-countup';
import { SpinnerComponent } from '../spinner/spinner.component';

@NgModule({
  declarations: [
    DashboardComponent
    // Other declarations specific to DashboardModule
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    CountUpModule,
    MapModule
  // Other configurations like providers
  ]
})
export class DashboardModule { }
