import { Injectable } from "@angular/core";
import { IApiError } from "src/app/_interfaces/apierrors.interface";
import { Constants } from "src/app/app.config";
import { JwtService } from "../jwt.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ApiErrorHandlerService {
  constructor(
    private appConstants: Constants,
    private jwtService: JwtService,
    private translate: TranslateService
  ) {}

  public handleError = (error: IApiError = null) => {
    const unspecified: IApiError = {
      ErrorCode: -1,
      ErrorName: "Undefined",
      ErrorMessage: "Unhandled api exception",
      isShowingMessage: false
    };
    const err = error ? error : unspecified;

    var header: string = null;
    // prettier-ignore
    if (err.ErrorName == "Unauthorized") {
      this.jwtService.flushCookiesAndLeave();
    }
    
    this.showError(err, header);
  };

  /* Shows an error pop-up with error info */
  private showError = (error: IApiError, header?: string) => {

    header = header ? this.translate.instant(`ErrorHeaders.${header}`) : "";

    var errorName = error.ErrorName.replace(/\s/g, "").toUpperCase();

    var message = (errorName == 'INVALIDMODEL') ? error?.ErrorMessage : // read error message from backend in case of invalid model
    this.translate.instant(`ErrorMessages.${error.ErrorName.replace(/\s/g, "").toUpperCase()}`);

    if (error && error?.ErrorName && error?.ErrorMessage) {
      this.appConstants.isShowingMessage = error?.isShowingMessage
      this.appConstants.errorMessageHeader = header;
      this.appConstants.errorMessageText = message;
    }
  };
}
