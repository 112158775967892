import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { UnauthorizedAccessComponent } from './_shared/unauthorized-access/unauthorized-access.component';
import { AuthGuardService } from './_services/auth-guard.service';

// import { LoginComponent } from './account/login/login.component';
// import { ProfileComponent } from './account/profile/profile.component';
// import { RegisterComponent } from './account/register/register.component';
// import { ConfirmEmailComponent } from './account/confirm-email/confirm-email.component';
// import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
// import { ResetPasswordComponent } from './account/reset-password/reset-password.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RedirectComponent } from './redirect/redirect.component';

import { UsermanualComponent } from './usermanual/usermanual.component';
import { SiteUnderMaintenanceComponent } from './_shared/site-under-maintenance/site-under-maintenance.component';
import { QrHardwareInfoComponent } from './qr-hardware-info/qr-hardware-info.component';
import { BoomrRedirectComponent } from './_shared/boomr-redirect/boomr-redirect.component';

// import { TrakkersComponent } from './trakkers/trakkers.component';
// import { TrakkerItemComponent } from './trakkers/trakker-item/trakker-item.component';
// import { AssetsComponent } from './assets/assets.component';
// import { MapComponent } from './map/map.component';
// import { ReportsComponent } from './reports/reports.component';


const routes: Routes = [
  // DEFINE YOUR ROUTES HERE
  // { path: '', component: HomeComponent },

  //{ path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'confirm-email', component: ConfirmEmailComponent },
  // { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'reset-password', component: ResetPasswordComponent },

  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'map', loadChildren: () => import('./map/map.module').then(m => m.MapModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'about', component: AboutComponent },
  { path: 'redirect/:guid', component: RedirectComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'unauthorized-access', component: UnauthorizedAccessComponent, data: { title: 'Unauthorized Access' } },
  { path: '', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'units', loadChildren: () => import('./trakkers/trakkers.module').then(m => m.TrakkersModule) },
  { path: 'usermanual', component: UsermanualComponent },
  { path: 'site-under-maintenance', component: SiteUnderMaintenanceComponent },
  { path: 'qr/hardware/:deviceId', component: QrHardwareInfoComponent },
  { path: 'boomr-redirect', component: BoomrRedirectComponent },


  // { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
  // { path: 'assets', component: AssetsComponent, canActivate: [AuthGuardService] },
  // { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService] },
  // { path: 'desktop', component: DesktopComponent, canActivate: [AuthGuardService] },
  // otherwise redirect to login page
  //{ path: '**', redirectTo: 'login', canActivate: [AuthGuardService] },
  // { path: '**', redirectTo: 'login' },
  // otherwise redirect to unauthorized-access page
  { path: '**', redirectTo: 'unauthorized-access' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }

// export const Routing = RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules });
