//#region Imports
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  OnChanges,
  ChangeDetectionStrategy,
  ElementRef,
  Input
} from "@angular/core";
import { environment } from "src/environments/environment";
import { MapCommonFunctionService } from "../_services/map-common-function.service";
// import * as _ from 'lodash';
import { ActivatedRoute } from "@angular/router";
import { TrakkerService } from "../_services/trakker.service";
import { GeofenceService } from "../_services/geofence.service";
import { MapzoneService } from "../_services/mapzone.service";
import { AccountService } from "../_services/account.service";
// import { VehicleService } from '../_services/vehicle.service';
import { LocalstoreService } from "../_shared/localstore.service";

//import { SortPipe } from '../_pipes/sort.pipe';
import { Constants } from "../app.config";
import { ScheduleEditorComponent } from "../_shared/schedule-editor/schedule-editor.component";

import * as L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-control-custom";

import "leaflet.markercluster";
import "leaflet-contextmenu";

import { Subscription } from "rxjs";
import { take } from "rxjs/internal/operators/take";
import { TranslateService } from "@ngx-translate/core";
// import { trigger, transition, style, animate } from '@angular/animations';

// declare let L:any;
var mapsPlaceholder = [];
var self2 = null;
if (typeof L != "undefined") {
  if (L.hasOwnProperty("Map")) {
    L.Map.addInitHook(function () {
      mapsPlaceholder.push(this);
    });
  }
}


//#endregion
@Component({
  selector: "map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  //   animations: [
  //     trigger('slideInOut', [
  //       transition(':enter', [
  //         style({
  //             'overflow-y': 'hidden',
  //             'max-height': 0
  //         }),
  //         animate('500ms ease', style({'max-height': '300px'}))
  //       ]),
  //       transition(':leave', [
  //         style({
  //             'overflow-y': 'hidden',
  //             'max-height': '300px'
  //         }),
  //         animate('500ms ease', style({'max-height': 0}))
  //       ])
  //     ])
  //   ]
})
export class MapComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("mapSearchInput", { static: false }) mapSearchInput: ElementRef;
  @Input() IsDashboard: boolean = false;

  currentPage: number = 1;

  private MapDisabled = true;

  //#region Declarations
  private subscriptions: Subscription[] = [];

  public infoFocus: string = null;
  public modal: string = null;
  public modalTimer: any = null;
  loadingTasks: number = 8; // trakkers, groups, favoriteTrakkers, mapzones, geofences, contacts, account, icons
  loadingTasksDone: number = 1;

  // 	layers: any;
  // 	panOptions: any;
  geofenceMapObjects: any[] = [];
  public map: any;
  markers: any[] = [];
  hiddenMarkers: any[] = [];
  trakkerMarkers: any = L.markerClusterGroup(); //L.featureGroup();
  trakkersPositionAccuracyRadius: any = L.layerGroup();

  selectedGeofenceScheduleId: number;

  unitList: any = [];
  selectedTrakker: any;
  searchTrakker: boolean = false;
  trakkerSearchResult: any = [];
  showSelectedInline: boolean = true;
  listTrakkersBy: string = "name";
  listVisibleTrakkers: boolean = false;
  visibleAddTrakkers: any = [];
  activeAlerts: any = [];
  

  geofenceList: any = [];
  unitsInGeofence: any = [];
  searchGeofence: boolean = false;
  selectedGeofence: any;
  geofenceSearchResult: any = [];
  showAllGeofences: boolean = false;
  editShapeGeofence: any = null;
  visibleAddGeofences: any = [];

  mapzoneList: any = [];
  searchMapzone: boolean = false;
  selectedMapzone: any;
  mapzoneSearchResult: any = [];

  contactList: any = [];
  selectedObject: any = null;
  createContact: boolean = false;
  visibleContacts: any = [];
  currentUser: any = {};
  scheduleError: boolean;

  @ViewChild(ScheduleEditorComponent, { static: true })
  private scheduleComponent: ScheduleEditorComponent;
  shapeControl: any;

  routerObjectType: string;
  routerObjectId: any;

  // GROUPS
  groupList: any = [];
  groupSearchResult: any = [];
  selectedGroup: any = null;
  groupDropdownVisible: boolean = false;
  hideEmptyGroups: boolean = false;
  sortGroupsBy: any = "name";

  // GEOFENCES
  geoList: any = [];
  geoSearchResult: any = [];
  selectedGeo: any = null;
  geoDropdownVisible: boolean = false;
  // hideEmptyGeo: boolean = false;
  sortGeosBy: any = "name";
  geoFavoritesFilter: boolean = false;
  trakkersInSelectedGeo: any = [];

  editTrakkerName: boolean = false;
  refreshTimer: any;
  autoRefresh: boolean;
  refreshProgress: number = 0;
  addressSearchSubscriber: any;
  addressSearchTimer: any;

  dateTimeLocales: string = "en-gb";
  dateTimeOptions: any = { timeZone: "Europe/Stockholm" };
  translationSearchAddress: string = "Search address";
  translationTrakkerHasNoLatestPosition: string = "Trakker has no latest position";
  translationNoVisibleTrakkers: string = "No visible trakkers";
  translationsAgo: any[] = [];
  translationGeofenceShapeMissing: string = "Geofence shape is missing";
  translationNameMissing: string = "Name is missing";
  infoModal: string;
  infoModalObject: any;
  loadedIconsOnce: boolean = false;

  extraSpace: boolean = false;
  followingObject: any;
  trakkerFavoritesList: any;
  favoritesFilter: boolean = false;
  groupFavoritesFilter: boolean = false;
  opsm: any;
  opsmdt: any;
  polyline: any;
  customerName: any;
  translateMapDeactivatedText: any;
  translateMapDeactivatedNote: any;
  mapDisabledOverlay: L.Control;
  userInfo: any;

  //#endregion

  constructor(
    private mapCommonFunctionService: MapCommonFunctionService,
    private route: ActivatedRoute,
    private trakkerService: TrakkerService,
    private geofenceService: GeofenceService,
    private mapzoneService: MapzoneService,
    private accountService: AccountService,
    // 		private vehicleService: VehicleService,
    private localStore: LocalstoreService,
    public appConstants: Constants,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,

  ) {
    //console.log("TLOG (map-component): Running constructor");

    this.modal = "loading";
    self2 = this;
    this.userInfo = this.localStore.get('accountDetails');
    this.autoRefresh = this.appConstants.initialAutomaticRefreshEventsActivated;


    this.subscriptions.push(
      this.route.params.pipe(take(1)).subscribe((params) => {
        this.routerObjectType = "trakkers";

        if (params["objectType"]) this.routerObjectType = params["objectType"].toLowerCase();

        this.routerObjectId = params["objectId"];

        switch (this.routerObjectType) {
          case "mapzone":
          case "mapzones":
            this.routerObjectType = "mapzones";
            this.infoFocus = "mapzones";
            break;
          case "geofence":
          case "geofences":
            this.routerObjectType = "geofences";
            this.infoFocus = "geofences";
            break;
          case "group":
          case "groups":
            this.routerObjectType = "groups";
            this.infoFocus = "trakkers";
            break;
          case "vehicle":
          case "vehicles":
            this.routerObjectType = "vehicles";
            this.infoFocus = "trakkers";
            break;
          case "trakker":
          case "trakkers":
          default:
            this.routerObjectType = "trakkers";
            this.infoFocus = "trakkers";
            break;
        }
        this.loadingTasksDone++;

        this.GetCurrentUser();
        this.GetAllTrakkers();
        this.GetAllActiveAlerts();
        this.GetAllMapzones();
        this.GetAllContacts();
        
      })
    );

    this.subscriptions.push(
      this.accountService.language.subscribe((lang) => {
        if (lang == "English") this.dateTimeLocales = "en-gb";
        if (lang == "Svenska") this.dateTimeLocales = "sv-se";
        // console.log(lang);
        this.UpdateTranslations();
        this.cdr.detectChanges();
      })
    );

    this.subscriptions.push(
      translate
        .get("Page.Labels.SEARCH_ADDRESS")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationSearchAddress = result;
        })
    );

    this.subscriptions.push(
      this.translate
        .get("MESSAGE.MAP_DEACTIVATED")
        .pipe(take(1))
        .subscribe((result) => {
          this.translateMapDeactivatedText = result;
        })
    );

    this.subscriptions.push(
      this.translate
        .get("MESSAGE.NOTE")
        .pipe(take(1))
        .subscribe((result) => {
          this.translateMapDeactivatedNote = result;
        })
    );

    this.subscriptions.push(
      translate
        .get("Page.Messages.TRAKKER_HAS_NO_LATEST_POSITION")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationTrakkerHasNoLatestPosition = result;
        })
    );

    this.subscriptions.push(
      translate
        .get("Page.Messages.NO_VISIBLE_TRAKKERS")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationNoVisibleTrakkers = result;
        })
    );
    this.subscriptions.push(
      translate
        .get("Page.Messages.NAME_MISSING")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationNameMissing = result;
        })
    );
    this.subscriptions.push(
      translate
        .get("Page.Messages.GEOFENCE_SHAPE_MISSING")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationGeofenceShapeMissing = result;
        })
    );
    this.translationsAgo.push({
      key: "ABOUT_YEARS_AGO",
      value: "about { value } years ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_YEAR_AGO",
      value: "about 1 year ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MONTHS_AGO",
      value: "about { value } months ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MONTH_AGO",
      value: "about 1 month ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_DAYS_AGO",
      value: "about { value } days ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_DAY_AGO",
      value: "about 1 day ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_HOURS_AGO",
      value: "about { value } hours ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_HOUR_AGO",
      value: "about 1 hour ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MINUTES_AGO",
      value: "about { value } minutes ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MINUTE_AGO",
      value: "about 1 minute ago",
    });
    this.translationsAgo.push({
      key: "A_FEW_SECONDS_AGO",
      value: "a few seconds ago",
    });

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_YEAR_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MONTH_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_HOUR_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MINUTE_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value = result;
        })
    );
  }
  /*********************************************************/
  /*                                                       */
  /*                        Init                           */
  /*                                                       */
  /*********************************************************/
  //#region Init
  ngOnInit() {
    //console.log("TLOG (map-component): Running ngOnInit");
  

    $("#info").show();
    $("#basemap").addClass("showInfo");
    

    if (mapsPlaceholder.length > 0) {
      this.map = mapsPlaceholder[0];
      this.map.clear;
    }

    // TODO REMOVE
    // this.StartCreateGeofence();

    // if(mapsPlaceholder.length == 0)//Kanske ta bort
    // {
    //     this.InitMap();
    //     this.AddControls();
    //     //this.AddGeofence();
    //     this.AddShortcutsListener();
    // }

    $("#info").show();
    $("#basemap").addClass("showInfo");
    $(window).trigger("resize");

    $("header").css({
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      "z-index": 2000,
    });
    $(".mat-toolbar.mat-primary").css({ "z-index": 2000 });

    // TURN ON AUTO-REFRESH
    this.AutoRefreshToggle();

    //        this.StopRefreshTimer();
    if (this.autoRefresh == true) {
      var waitingForLoaded = setInterval(() => {
        if (this.loadingTasksDone == -1) {
          clearInterval(waitingForLoaded);
          this.SetRefreshTimer();
        }
      }, 500);
    } else {
      this.StopRefreshTimer();
    }

  }


  CloseInformation() {
    this.extraSpace = false;
    //console.log("closeInformation");
    $("#info").hide();
    $("#basemap").removeClass("showInfo");
    setTimeout(() => {
      $(window).trigger("resize");
    }, 1);
  }

  OpenInformation() {
    this.extraSpace = true;
    $("#info").show();
    $("#basemap").addClass("showInfo");
    setTimeout(() => {
      $(window).trigger("resize");
    }, 1);
  }

  ngOnDestroy() {
    
    //console.log("MapComponent ngOnDestroy Running");

    $("header").css({ position: "initial" });
    $(".mat-toolbar.mat-primary").css({ "z-index": 1 });

    if (mapsPlaceholder.length > 0) {
      for (let m of mapsPlaceholder) {
        m.remove();
      }
      mapsPlaceholder = [];
    }
    this.StopRefreshTimer();

    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
  ngOnChanges(changes: any): void {
    if (changes.hasOwnProperty("Object")) {
      if (changes.Object.hasOwnProperty("TrakkerId")) {
        this.modal = null;
        var trakker = changes.Object;
        var marker = this.markers.find((m) => m.TrakkerId == trakker.TrakkerId);
        if (marker) {
          var markerIcon = this.GetMarkerIcon(trakker);
          marker.MapObject.setIcon(markerIcon);
        }
      }
    }
  }
  UpdateTranslations() {
    this.subscriptions.push(
      this.translate
        .get("Page.MESSAGE.MAP_DEACTIVATED")
        .pipe(take(1))
        .subscribe((result) => {
          this.translateMapDeactivatedText = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("MESSAGE.NOTE")
        .pipe(take(1))
        .subscribe((result) => {
          this.translateMapDeactivatedNote = result;
        })
    );

    this.subscriptions.push(
      this.translate
        .get("Page.Labels.SEARCH_ADDRESS")
        .pipe(take(1))
        .subscribe((result) => {
          // console.log(result);
          this.translationSearchAddress = result;
          $("#addressSearch").attr("placeholder", result);
        })
    );

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.TRAKKER_HAS_NO_LATEST_POSITION")
        .pipe(take(1))
        .subscribe((result) => {
          // console.log(result);
          this.translationTrakkerHasNoLatestPosition = result;

          if (this.modal == "info" && this.infoModal == "trakker_has_no_latest_position") {
            // console.log(this.infoModalObject);
            this.InfoModalHasNoPositionText(this.infoModalObject.Name);
          }
        })
    );

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.NO_VISIBLE_TRAKKERS")
        .pipe(take(1))
        .subscribe((result) => {
          // console.log(result);
          this.translationNoVisibleTrakkers = result;

          if (this.modal == "info" && this.infoModal == "no_visible_trakkers") {
            this.InfoModalNoVisibleTrakkers();
          }
        })
    );

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_YEAR_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MONTH_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_HOUR_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MINUTE_AGO").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS_AGO").value = result;
        })
    );
    var t = this.selectedTrakker;
    this.selectedTrakker = null;
    this.cdr.detectChanges();
    this.selectedTrakker = t;
    this.cdr.detectChanges();
  }

  CancelModal() {
    if (this.modal == "loading") {
      //console.log("loading...");
      return;
    }
    if (this.modal == "scheduleEditor") {
      this.CancelScheduleEditor();
      return;
    }

    this.createContact = false;
    if (this.modalTimer != null) {
      clearTimeout(this.modalTimer);
      this.modalTimer = null;
    }
    this.modal = null;
    this.infoModal = null;
    this.infoModalObject = null;
  }
  loadingProgress() {
    var progress = 100 * (this.loadingTasksDone / this.loadingTasks);
    if (progress >= 100) {
      setTimeout(() => {
        this.modal = null;
        this.cdr.detectChanges();
      }, 500);
      this.loadingTasksDone = -1;
    }

    return progress.toString();
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                         Map                           */
  /*                                                       */
  /*********************************************************/
  //#region Map

  InitMap() {
    //console.log("MAP: " + JSON.stringify(this.map))
    //return;
    if (this.map) {
      this.map.remove();
    }

    this.map = L.map("basemap", {
      center: [39.8282, -98.5795],
      zoom: 3,
      zoomControl: false,
    });

    //this.AddContextMenuToMap();

    var storedThemeId = this.localStore.get("ThemeId");
    if (storedThemeId == 1) this.opsmdt.addTo(this.map);
    else this.opsm.addTo(this.map);

    this.map.addLayer(this.trakkerMarkers);
    this.map.addLayer(this.trakkersPositionAccuracyRadius)

    $(window).on("resize", function () {
      if (mapsPlaceholder.length > 0) {
        mapsPlaceholder[0].invalidateSize();
      }
    });
  }
  CenterOnMap(object: any, zoomIn: boolean = true) {
    if (this.MapDisabled) {
      return;
    }

    if (!object) {
      this.ClearGeofenceLayer();
      this.UpdateVisibleUnits();
      if (zoomIn == true) {
        this.ZoomInVisibleMarkers();
      }
      return;
    }

    if (this.followingObject != object) {
      this.followingObject = null;
    }

    if (object.hasOwnProperty("GeofenceId")) {
      if (this.IsGeofenceVisible(object) && this.showAllGeofences == false) {
        this.ClearGeofenceLayer();
        return;
      }
      if (this.showAllGeofences == false) {
        this.ClearGeofenceLayer();
      }
      this.DrawAndCenterGeofence(object);
      return;
    }

    let zoomLevel = 16;
    let latitude = "57.657601904547846"; //'57.657601904547846';
    let longitude = "11.918121457362883"; //'11.91812145736288352';

    if (object.hasOwnProperty("MapzoneId")) {
      zoomLevel = object.ZoomLevel;
      latitude = object.Latitude;
      longitude = object.Longitude;
      if (zoomIn) {
        this.map.setView([latitude, longitude], zoomLevel);
      }
      if (zoomIn == false) {
        this.map.panTo([latitude, longitude]);
      }
      return;
    }

    if (object.hasOwnProperty("TrakkerId")) {
      if (object.LatestPositionEvent == null) {
        this.InfoModalHasNoPositionText(object.Name);
        this.modal = "info";
        this.infoModal = "trakker_has_no_latest_position";
        this.infoModalObject = object;
        setTimeout(() => {
          $(".modalWrapper .defaultAction").trigger("focus");
        }, 1);
        this.modalTimer = setTimeout(() => {
          this.modal = null;
          this.infoModal = null;
          this.infoModalObject = null;
        }, 2500);
        return;
      }
      latitude = object.LatestPositionEvent?.Position?.Latitude;
      longitude = object.LatestPositionEvent?.Position?.Longitude;
      if(!longitude || !latitude)
        return;

      if (zoomIn) {
        this.map.setView([latitude, longitude], zoomLevel);
      }
      if (zoomIn == false) {
        this.map.panTo([latitude, longitude], { animate: true, duration: 1 });
      }
      return;
    }

    if (object.hasOwnProperty("GroupId")) {
      this.selectedGroup = object;
      this.UpdateVisibleUnits();
      this.ZoomInVisibleMarkers();
      // this.ShowTrakkerInSelectedGroup();
    }
  }
  ZoomInVisibleMarkers() {
    if (this.map && this.trakkerMarkers.getLayers().length > 0) {
      this.map.fitBounds(this.trakkerMarkers.getBounds(), {
        maxZoom: 16,
        padding: [50, 50],
      });
      return;
    }

    this.infoModalObject = null;
    setTimeout(() => {
      $(".modalWrapper .defaultAction").trigger("focus");
    }, 1);
    this.modalTimer = setTimeout(() => {
      this.modal = null;
      this.infoModal = null;
      this.infoModalObject = null;
    }, 2500);

    this.map.setView([57.65387, 11.93595], 10);
  }
  private InfoModalNoVisibleTrakkers() {
    $("#infoModal p").html(this.translationNoVisibleTrakkers);
  }
  private InfoModalHasNoPositionText(name: string) {
    $("#infoModal p").html(this.translationTrakkerHasNoLatestPosition.replace("{{ name }}", name));
  }
  private InfoModalGeofenceShapeMissing() {
    $("#infoModal p").html(this.translationGeofenceShapeMissing);
  }
  private InfoModalNameMissing() {
    $("#infoModal p").html(this.translationNameMissing);
  }
  ScrollToTop() {
    $(".infoContent").scrollTop(0);
  }

  AddControls() {
    // Dark & Light maps
    var baseMaps = {
      "Open street map": this.opsm,
      "Open street map Dark theme": this.opsmdt,
    };
    L.control.layers(baseMaps).addTo(this.map);

    // Searchbar
    const htmlSearchBar = `<div style="margin: 10px; width: 300px; overflow: hidden; background: rgba(255,255,255,0.8); border-radius: 5px; box-shadow: 0 1px 5px rgba(0,0,0,0.65);"> \
               <input #mapSearchInput id="mapSearchInput" type="search" class="form-control input-sm" placeholder="${this.translationSearchAddress}" id="addressSearch" style="margin:0;">\
               <div id="addressSearchInfo" style="display:none;">
                   Info
               </div>
               <div id="addressSearchResult" style="display:none;">
                   Result
               </div>
            </div>`;

    var searchBar = new L.Control({ position: "topleft" });
    searchBar.onAdd = () => {
      const _div = L.DomUtil.create("div", "mapsearchbar");
      _div.innerHTML = htmlSearchBar;
      L.DomEvent.addListener(_div, "input", (data) => {
        self2.SearchAddress(data);
      });
      return _div;
    };
    searchBar.addTo(this.map);

    new L.Control.Zoom({ position: "bottomright" }).addTo(this.map);

    /** MAP DEACTIVATED TEXT */
    const htmlmapDisabled = `<div style="margin: 10px; width: 300px; height: fit-content; background:rgba(255,255,255,0.8); padding: 20px;" > 
    <h5>${this.translateMapDeactivatedNote}</h5>
    <p>${this.translateMapDeactivatedText}</p></div>`;

    this.mapDisabledOverlay = new L.Control({ position: "topleft" });
    this.mapDisabledOverlay.onAdd = () => {
      const _div = L.DomUtil.create("div", "mapdisabledText");
      _div.innerHTML = htmlmapDisabled;
      return _div;
    };

    if (this.MapDisabled && this.currentUser != null) {
      this.mapDisabledOverlay.addTo(this.map);
    } else if (this.mapDisabledOverlay) {
      this.mapDisabledOverlay.remove();
    }

    this.map;
  }

  private CreateMapTilesLayers() {
    this.opsm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© Trakk",
    });
    this.opsmdt = L.tileLayer("https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png", {
      attribution: "© Trakk",
    });
  }

  SearchAddress(data) {
    if (data.target.value.length > 2) {
      $("#addressSearchInfo")
        .html('<div style="padding:10px;">' + "   Searching addresses..." + "</div>")
        .show();

      if (this.addressSearchTimer != null) {
        clearTimeout(this.addressSearchTimer);
        this.addressSearchTimer = null;
      }
      //vänta en stund (1 sekund) innan sökningen påbörjas så att man hinner skriva färdigt innan sökningen görs
      this.addressSearchTimer = setTimeout(() => {
        //kolla om det finns en ofärdig sökning igång och avbryt den i så fall

        var searchText = data.target.value;
        //Gör sökningen och byt ut allt mot resultatet
        //this.addressSearchSubscriber = this.mapCommonFunctionService.addressSearch(searchText).then(result => {
        this.addressSearchSubscriber = this.mapCommonFunctionService.addressSearch(searchText).subscribe((result) => {
          $("#addressSearchInfo").html("").hide();
          $("#addressSearchResult").html("").show();

          if (result.suggestions.length == 0) {
            $("#addressSearchResult").html('<div style="padding:10px;">' + "   No address found" + "</div>");
          }

          result.suggestions.forEach((s) => {
            $("#addressSearchResult").append(
              '<div class="addressSearchRow" style="padding:10px;">' + "   <span>" + s.label + "</span>" + "</div>"
            );
            $("#addressSearchResult>div")
              .last()
              .click(function () {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = s.label;
                const label = tmp.textContent || tmp.innerText || "";

                self2.ZoomInOnLocation(s.locationId);
                const input = <HTMLInputElement>document.getElementById("mapSearchInput");

                if (input) {
                  input.value = label;
                }
                $("#addressSearchResult").html("").hide();
              });
          });

          this.addressSearchSubscriber.unsubscribe();
        });
        this.addressSearchTimer = null;
      }, 1000);
    }
    if (data.target.value.length <= 2) {
      if (this.addressSearchTimer != null) {
        clearTimeout(this.addressSearchTimer);
        this.addressSearchTimer = null;
      }
      $("#addressSearchInfo").html("").hide();
      $("#addressSearchResult").html("").hide();
    }

    //console.log(data);
  }

  ZoomInOnLocation(locationId) {
    this.subscriptions.push(
      this.mapCommonFunctionService
        .locationDetails(locationId)
        .pipe(take(1))
        .subscribe((result) => {
          if (result.Response.View.length == 0) {
            //Error
            return;
          }

          var bounds = result.Response.View[0].Result[0].Location.MapView;

          var TopLeft = [bounds.TopLeft.Latitude, bounds.TopLeft.Longitude];
          var BottomRight = [bounds.BottomRight.Latitude, bounds.BottomRight.Longitude];
          this.map.fitBounds([TopLeft, BottomRight]);

          // console.log(result);
        })
    );
  }

  ToggleFollowObject(object) {
    if (this.followingObject == object) {
      this.followingObject = null;
      return;
    }
    this.followingObject = object;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                         User                          */
  /*                                                       */
  /*********************************************************/
  GetCurrentUser() {
    this.subscriptions.push(
      this.accountService
        .getAccount()
        .pipe(take(1))
        .subscribe((result) => {
          this.currentUser = result;
          this.MapDisabled = environment.MapBannedCustomer.indexOf(this.currentUser.CustomerId) >= 0;
          // console.log(this.currentUser);
          this.dateTimeOptions.windowsTimeZone = result.TimeZone.DisplayName; // was result.Timezone.Name

          this.CreateMapTilesLayers();
          if (mapsPlaceholder.length == 0) {
            this.InitMap();
            this.AddControls();
          }
          // var compayDetails = this.localStore.get('companyDetails');
          // console.log(compayDetails);
          // this.customerName = compayDetails.Name;
          this.GetCustomer();

          this.loadingTasksDone++;
          this.cdr.detectChanges();
        })
    );
  }

  GetCustomer() {
    this.subscriptions.push(
      this.accountService.getCompanyDetails().subscribe((result) => {
        //console.log(result);
        this.customerName = result.Name;
      })
    );
  }

  /*********************************************************/
  /*                                                       */
  /*                       Trakker                         */
  /*                                                       */
  /*********************************************************/
  //#region Trakker
  GetAllTrakkers() {
    this.subscriptions.push(
      this.trakkerService
        .getTrakkers()
        .pipe(take(1))
        .subscribe((result) => {
          result.forEach((t) => {
            t.IsFavorite = false;
          });
          this.cdr.detectChanges();

          this.unitList = result;
          this.trakkerSearchResult = result;
          this.loadingTasksDone++;

          this.GetAllGeofences();
          this.GetAllGroups();

          this.cdr.detectChanges();
        })
    );
  }

  GetAllActiveAlerts(){
    this.subscriptions.push(
      this.trakkerService.getTrakkersActiveAlertsForAccount().subscribe((result) => {
        this.activeAlerts = result;
        this.cdr.detectChanges();
      })
    )
  }

  AnyActiveAlerts(trakkerId : number){
    return this.activeAlerts.some(item => item.TrakkerEvent.TrakkerId === trakkerId);
  }

  GetTrakkerActiveAlerts(trakkerId : number){
    return this.activeAlerts.filter(x => x.TrakkerEvent.TrakkerId === trakkerId);
  }

  GetFavoriteTrakkers() {
    this.subscriptions.push(
      this.trakkerService.getFavoriteTrakkersNew().subscribe((result) => {
        this.trakkerFavoritesList = result;
        this.unitList.forEach((t) => {
          var isFavorite = result.find((f) => f == t.TrakkerId) != null;
          this.UpdateLocalTrakkers(t.TrakkerId, { IsFavorite: isFavorite });
        });

        this.DrawAllTrakkers();

        if (this.routerObjectId == null) {
          this.CenterOnMap(null);
          // this.ShowAllTrakkers();
        }

        if (this.routerObjectId && this.routerObjectType == "trakkers") {
          if (this.routerObjectId.toLowerCase() == "favorites") {
            this.favoritesFilter = true;
            this.UpdateVisibleUnits();
            this.CenterOnMap(null);
            this.cdr.detectChanges();
            return;
          }

          this.selectedTrakker = this.unitList.find((t) => t.TrakkerId == this.routerObjectId);
          if (this.selectedTrakker) this.CenterOnMap(this.selectedTrakker);
        }

        if (this.routerObjectId && this.routerObjectType == "vehicles") {
          if (this.routerObjectId.toLowerCase() == "favorites") {
            this.favoritesFilter = true;
            this.UpdateVisibleUnits();
            this.CenterOnMap(null);
            this.cdr.detectChanges();
            return;
          }

          this.selectedTrakker = this.unitList.find(
            (t) => t.Vehicles != null && t.Vehicles.length > 0 && t.Vehicles[0].TrakkerId == this.routerObjectId
          );

          if (this.selectedTrakker) this.CenterOnMap(this.selectedTrakker);
        }
      })
    );
  }
  GetConvertedSpeed(trakker)
  {
    if (trakker == null || trakker.LatestPositionEvent == null || trakker.LatestPositionEvent.Speed == null || trakker.LatestPositionEvent.Speed <= 0)
      return 0;
                   
    switch(this.userInfo.SpeedUnit)
    {
      case 'kmh':
        return trakker.LatestPositionEvent.Speed;
      case 'mph':
        return Math.round(trakker.LatestPositionEvent.Speed * 0.621371192);
      case 'knot':
        return Math.round(trakker.LatestPositionEvent.Speed * 0.539956803);
      default:
        return trakker.LatestPositionEvent.Speed;
    }
  }
  TryToggleFavorite(trakker) {
    var data = { favorite: !trakker.IsFavorite };
    this.subscriptions.push(
      this.trakkerService.editTrakkerFavorite(trakker.TrakkerId, data).subscribe((result) => {
        //console.log(data.favorite);
        trakker.IsFavorite = data.favorite;
        this.UpdateLocalTrakkers(trakker.TrakkerId, {
          IsFavorite: data.favorite,
        });
        this.cdr.detectChanges();
      })
    );
  }
  UpdateLocalTrakkers(trakkerId: number, newTrakkerData: any) {
    Object.keys(newTrakkerData).forEach((key: string) => {
      // var trakkerKey = dataKey;
      // switch(dataKey)
      // {
      //     case "favorite":
      //         trakkerKey ="IsFavorite";
      //         break;
      // }
      this.unitList.find((t) => t.TrakkerId == trakkerId)[key] = newTrakkerData[key];

      this.groupList.forEach((g) => {
        var temp = g.Trakkers.find((t) => t.TrakkerId == trakkerId);
        if (temp) temp[key] = newTrakkerData[key];
      });
      this.groupSearchResult.forEach((g) => {
        var temp = g.Trakkers.find((t) => t.TrakkerId == trakkerId);
        if (temp) temp[key] = newTrakkerData[key];
      });
    });
  }
  ToggleFavoritesFilter() {
    this.favoritesFilter = !this.favoritesFilter;
    this.UpdateVisibleUnits();
  }
  private SetRefreshTimer() {
    var refreshIntervall = this.appConstants.automaticRefreshEventsInterval; // 10000;

    $("#autoRefreshToggle>.tiny-meter>span")
      .stop()
      .css("width", 0)
      .animate({ width: "100%" }, refreshIntervall, "linear");
    $("#autoRefreshToggle>.tiny-meter").show();
    $("#infoAutoRefreshToggle>.tiny-meter>span")
      .stop()
      .css("width", 0)
      .animate({ width: "100%" }, refreshIntervall, "linear");
    $("#infoAutoRefreshToggle>.tiny-meter").show();

    if(this.refreshTimer == undefined)
    {
      this.refreshTimer = setTimeout(() => {
        this.RefreshEventData();
      }, refreshIntervall);
    }
  }
  AutoRefreshToggle() {
    this.autoRefresh = !this.autoRefresh;
    if (this.autoRefresh == false) {
      $("#autoRefreshToggle").addClass("deactivated");
      this.StopRefreshTimer();
    }

    if (this.autoRefresh == true) {
      $("#autoRefreshToggle").removeClass("deactivated");
      this.RefreshEventData();
    }
  }
  StopRefreshTimer() {
    $("#autoRefreshToggle>img").removeClass("fast-spin");
    $("#infoAutoRefreshToggle>img").removeClass("fast-spin");
    $("#autoRefreshToggle>.tiny-meter").hide();
    $("#infoAutoRefreshToggle>.tiny-meter").hide();

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }
  RefreshEventData() {
    //Idé: Hämta alla event som är nyare änn en viss tid

    $("#autoRefreshToggle>img").addClass("fast-spin");
    $("#infoAutoRefreshToggle>img").addClass("fast-spin");

    this.trakkerService.getTrakkers().subscribe((result) => {
      var trakkersToAdd = [];
      var needRefreshGeofences = false;

      this.GetAllActiveAlerts()
      
      result.forEach((t) => {
        var trakker = this.unitList.find((o) => o.TrakkerId == t.TrakkerId);
        if (trakker) {
          trakker.Status = t.Status;
          trakker.BatteryLevel = t.BatteryLevel;
          if (JSON.stringify(trakker.LatestPositionEvent) != JSON.stringify(t.LatestPositionEvent)) {
            var marker = this.markers.find((o) => o.TrakkerId == t.TrakkerId);
            if (marker) {
              var latlng = [t.LatestPositionEvent.Position.Latitude, t.LatestPositionEvent.Position.Longitude];
              marker.MapObject.setLatLng(latlng);
            }
            trakker.LatestPositionEvent = t.LatestPositionEvent;
          }
          if (trakker.Asset != null) {
            trakker.Asset.LatestEvent = t.Asset.LatestEvent;
          }
          if (JSON.stringify(trakker.LatestEvent) != JSON.stringify(t.LatestEvent)) {
            trakker.LatestEvent = t.LatestEvent;
          }

          // if (JSON.stringify(trakker.ActiveAlerts) != JSON.stringify(t.ActiveAlerts)) {
          //   trakker.ActiveAlerts = t.ActiveAlerts;
          //   this.AddTriggeredGeofenceData(trakker);
          // }

          if (JSON.stringify(trakker.Contacts) != JSON.stringify(t.Contacts)) {
            trakker.Contacts = t.Contacts;
          }

          if (this.IsGeofenceInfoSame(trakker, t) == false) {
            trakker.Geofences = t.Geofences;
            needRefreshGeofences = true;
          }
        }
        if (trakker == null) {
          trakkersToAdd.push(t);
        }
      });

      //Add new trakkers in unitList
      if (trakkersToAdd.length > 0) {
        trakkersToAdd.forEach((t) => {
          this.unitList.push(t);
          if (t.LatestPositionEvent != null && !this.MapDisabled) {
            var mapObject = this.CreateTrakkerMarker(t);
            this.markers.push({
              TrakkerId: t.TrakkerId,
              Visible: this.MapDisabled ? false : true,
              MapObject: mapObject,
            });
            if(mapObject)
            this.trakkerMarkers.addLayer(mapObject);
          }
        });
        needRefreshGeofences = true;
      }

      //Todo Groups check and update

      //Check for removed trakkers
      this.unitList.forEach((t) => {
        var trakker = result.find((o) => o.TrakkerId == t.TrakkerId);
        if (trakker == null) {
          var index = this.unitList.findIndex((m) => m.TrakkerId == t.TrakkerId);
          this.unitList.splice(index, 1);
          var marker = this.markers.find((m) => m.TrakkerId == t.TrakkerId);
          if (marker != null) {
            this.trakkerMarkers.removeLayer(marker.MapObject);
            this.trakkersPositionAccuracyRadius.removeLayer(marker.MabObjecTrakkersPositionAccuracyRadius);
            index = this.markers.findIndex((m) => m.TrakkerId == t.TrakkerId);
            this.markers.splice(index, 1);
          }
          needRefreshGeofences = true;
        }
      });

      if (needRefreshGeofences == true) {
        this.RefreshGeofences();
      }

      this.UpdateVisibleUnits();
      var backupList = this.trakkerSearchResult;
      this.trakkerSearchResult = [];

      this.cdr.detectChanges();
      this.trakkerSearchResult = backupList;
      this.cdr.detectChanges();

      if (this.followingObject != null) {
        this.CenterOnMap(this.followingObject, false);
      }

      setTimeout(() => {
        $("#autoRefreshToggle>img").removeClass("fast-spin");
        $("#infoAutoRefreshToggle>img").removeClass("fast-spin");
      }, 250);

      if (this.autoRefresh == true) this.SetRefreshTimer();
    });
  }
  private IsGeofenceInfoSame(trakker: any, t: any) {
    var tempGeofenceTrakkers = [];
    trakker.Geofences.forEach((g) => {
      if (g.Trakkers != null) {
        g.Trakkers.forEach((gt) => {
          tempGeofenceTrakkers.push({
            GeofenceId: g.GeofenceId,
            Trakker: gt,
          });
        });
        g.Trakkers = [];
      }
    });

    var returnValue = JSON.stringify(trakker.Geofences) == JSON.stringify(t.Geofences);

    trakker.Geofences.forEach((g) => {
      g.Trakkers = tempGeofenceTrakkers.filter((gt) => gt.GeofenceId == g.GeofenceId);
    });

    return returnValue;
  }

  ToggleSelectedTrakker(trakker) {
    if (this.selectedTrakker != null) {
      this.AddToMarkerCluster(this.selectedTrakker.TrakkerId);
    }

    if (trakker) {
      this.RemoveFromTrakkerPositionAccuracyRadius(trakker.TrakkerId);
      
      if(trakker.LatestPositionEvent?.PositionSourceType != 'Undefined') {
        this.AddToTrakkerPositionAccuracyRadius(trakker.TrakkerId);
      }
      
      
      this.RemoveFromMarkerCluster(trakker.TrakkerId);
      if (trakker != this.selectedTrakker) {
        this.CenterOnMap(trakker, true);
      }
    }

    this.selectedTrakker = this.selectedTrakker == trakker ? null : trakker;
    if (this.selectedTrakker != null) {
      this.editTrakkerName = false;

      // SCROLLING UP
      setTimeout(function () {
        $("#infoContent").scrollTop(
          $("#infoContent").scrollTop() +
            $("#trakker" + self2.selectedTrakker.TrakkerId)
              .parent()
              .position().top -
            130
        );
      }, 1);
    }
  }

  ToggleGeofenceActiveForAll(){
    this.selectedGeofence.ActiveForAllUnits ? this.selectedGeofence.ActiveForAllUnits = false : this.selectedGeofence.ActiveForAllUnits = true 
  }

  private RemoveFromMarkerCluster(trakkerId) {
    var marker = this.markers.find((m) => m.TrakkerId == trakkerId);
    if (marker) {
      marker.MapObject.removeFrom(this.trakkerMarkers);
      this.map.addLayer(marker.MapObject);

    }
  }

  private AddToMarkerCluster(trakkerId) {
    var marker = this.markers.find((m) => m.TrakkerId == trakkerId);
    if (marker) {
      marker.MapObject.removeFrom(this.map);
      this.trakkerMarkers.addLayer(marker.MapObject);
    }
  }

  private RemoveFromTrakkerPositionAccuracyRadius(trakkerId) {
    var marker = this.markers.find((m) => m.TrakkerId == trakkerId);
    if (marker) {
      this.trakkersPositionAccuracyRadius.clearLayers();
    }
  }

  private AddToTrakkerPositionAccuracyRadius(trakkerId) {
    var marker = this.markers.find((m) => m.TrakkerId == trakkerId);
    if (marker) {
      this.trakkersPositionAccuracyRadius.addLayer(marker.MabObjecTrakkersPositionAccuracyRadius);
    }
  }

  DrawAllTrakkers() {
    if (this.MapDisabled) {
      return;
    }

    for (let trakker of this.unitList) {
      if (trakker.LatestPositionEvent != null) {
        var marker = this.markers.find((m) => m.TrakkerId == trakker.TrakkerId);

        //mapObject.addTo(this.map);
        if (!marker) {
          var mapObject = this.CreateTrakkerMarker(trakker);
          var mabObjecTrakkersPositionAccuracyRadius = this.CreatePositionAccuracyRadius(trakker);
          if(!mapObject){
            continue;
          }
          this.markers.push({
            TrakkerId: trakker.TrakkerId,
            Visible: this.MapDisabled ? false : true,
            MapObject: mapObject,
            MabObjecTrakkersPositionAccuracyRadius: mabObjecTrakkersPositionAccuracyRadius
          });

          this.trakkerMarkers.addLayer(mapObject);          
        }
      }
    }
  }
  CreateTrakkerMarker(trakker: any) {
    var latitude = trakker.LatestPositionEvent?.Position?.Latitude;
    var longitude = trakker.LatestPositionEvent?.Position?.Longitude;
    var latlng: [number, number];
    var marker = null;

    if(!latitude || !longitude)
      return null;

    latlng = [latitude, longitude];
    marker = new L.Marker(latlng, { icon: this.GetMarkerIcon(trakker) });

    var tempTrakkerSearchResult = this.trakkerSearchResult;

    marker.on("click", function () {
      var index = tempTrakkerSearchResult.findIndex(obj => obj.TrakkerId === trakker.TrakkerId);
      tempTrakkerSearchResult.splice(index, 1);
      tempTrakkerSearchResult.unshift(trakker);
      this.trakkerSearchResult = tempTrakkerSearchResult;

      $("#infoFocusTrakkers").trigger("click");
      setTimeout(() => {
        $("#trakker" + trakker.TrakkerId).trigger("click");
      }, 1);
      //CenterOnMap(trakker);
      // this.infoFocus="trakkers";
      // this.selectedTrakker = trakker;
    });
    var toolTip = trakker.Name;
    marker.bindTooltip(toolTip, { offset: L.point(0, -16), direction: "top" });
    return marker;
  }

  CreatePositionAccuracyRadius(trakker:any){
    var positionSourceType = trakker.LatestPositionEvent?.PositionSourceType;
    var radius = trakker.LatestPositionEvent?.Accuracy;
    var latitude = trakker.LatestPositionEvent?.Position?.Latitude;
    var longitude = trakker.LatestPositionEvent?.Position?.Longitude;
    var latlng: [number, number];
    var circle = null;
  
    if(!latitude || !longitude || positionSourceType == 'Undefined' || !radius) return null;
    latlng = [latitude, longitude];

    circle = L.circle(latlng, radius, {
      fillColor: '#4285F4',
      fillOpacity: 0.3,
      opacity: 0.1,
    });

    return circle;
  }

  GetMinimalSquareHtml(type: any, trakkerId: any, iconSize: number) {
    var arr = [];
    arr.push("<div class='MinimalSquare_" + iconSize + "  " + type + "' id='sq" + trakkerId + "'>");
    arr.push("</div>");

    return arr.join("");
  }
  GetRegNoPlateHtml(trakkerName: any, iconName: any, trakkerId: any, iconSize: number) {
    var arr = [];
    arr.push("<div class='RegNoPlate_" + iconSize + " " + iconName + "' id='regno" + trakkerId + "'>");
    arr.push("<div>" + trakkerName + "</div>");
    arr.push("</div>");

    return arr.join("");
  }
  GetMarkerIcon(trakker) {
    var iconSize = 32;
    //Hämta plate från kartan
    var regNoPlate = $("#regno" + trakker.TrakkerId);

    //Ta bort plate från kartan
    if (regNoPlate.length > 0) {
      regNoPlate.remove();
    }

    //Hämta minimal square från kartan
    var sq = $("#sq" + trakker.TrakkerId);

    //Ta bort minimal square från kartan
    if (sq.length > 0) {
      sq.remove();
    }

    if (trakker.Icon.Name.substring(0, 5) == "plate") {
      //Om plate lägg till på kartan
      var htmlIcon = new L.DivIcon({
        html: this.GetRegNoPlateHtml(trakker.Name, trakker.Icon.Name, trakker.TrakkerId, iconSize),
        className: "transparent_div_marker",
      });
      return htmlIcon;
    } else if (trakker.Icon.Name.substring(trakker.Icon.Name.length - 6, trakker.Icon.Name.length) == "square") {
      //Om minimal square lägg till på kartan
      var type = trakker.Icon.Url.split("/")[2];
      type = type.substring(0, type.length - 4);

      var htmlIcon = new L.DivIcon({
        html: this.GetMinimalSquareHtml(type, trakker.TrakkerId, iconSize),
        className: "transparent_div_marker",
      });
      return htmlIcon;
    } else {
      var iconImage = "/assets/" + trakker.Icon.Url;
      var trakkerIcon = L.icon({
        iconUrl: iconImage,
        //shadowUrl: "/img/active.png",//null,
        iconSize: new L.Point(iconSize, iconSize),
        //shadowSize: new L.Point(iconSize*1.5, iconSize*1.5),
        iconAnchor: new L.Point(iconSize / 2, iconSize / 2),
        popupAnchor: new L.Point(-3, -iconSize / 2),
        //shadowAnchor: new L.Point(iconSize * 1.5 / 2, iconSize * 1.5 / 2)
      });
      return trakkerIcon;
    }
  }

  ToggleTrakkerSearch() {
    this.searchTrakker = !this.searchTrakker;
    this.extraSpace = this.searchTrakker;
    // if(this.searchTrakker == true){
    //     setTimeout(() => {  $("#searchTrakkerText").trigger('focus'); }, 1);
    // }
    if (this.searchTrakker == false) {
      this.UpdateVisibleUnits();
    }
  }

  async getUnitsWithinGeofence(geofenceId: Number) {
    this.subscriptions.push(
      this.trakkerService
        .getTrakkers(`?filter.geofenceId=${geofenceId}`)
        .pipe(take(1))
        .subscribe((result) => {
          return result;
        })
    );
  }

  UpdateVisibleUnits(event = null) {
    var filteredList = this.unitList;

    // Filter within geofence
    if (this.selectedGeo != null) {
      filteredList = this.unitsInGeofence;
    }

    // Filter favorites
    if (this.favoritesFilter == true) {
      filteredList = filteredList.filter((t) => t.IsFavorite == true);
    }

    // Filter group
    if (this.selectedGroup != null) {
      filteredList = filteredList.filter((trakker) => {
        return this.selectedGroup.Trakkers.find((el) => el.TrakkerId === trakker.TrakkerId);
      });
    }

    // Filter based on search input
    var searchText = $("#searchTrakkerText").val();
    if (searchText) {
      filteredList = this.FilterSearchResult(searchText, filteredList);
    }

    this.trakkerSearchResult = filteredList;
    this.UpdateVisibleTrakkerMarkers();

    this.cdr.detectChanges();
  }

  FilterSearchResult(searchText, unitList) {
    if (searchText != undefined && searchText != "") {
      searchText = searchText.toLowerCase();
      return unitList.filter((t) => {
        if (t.Name.toLowerCase().indexOf(searchText) !== -1 || 
            (t.EquipmentNumber!= null && t.EquipmentNumber.toLowerCase().indexOf(searchText) !== -1)) 
            return true;
        if (
          t.Vehicles != null &&
          t.Vehicles != undefined &&
          t.Vehicles.length > 0 &&
          ((t.Vehicles[0].VehicleRegistrationNumber != null &&
            t.Vehicles[0].VehicleRegistrationNumber.toLowerCase().indexOf(searchText) !== -1) ||
            (t.Vehicles[0].Name != null && t.Vehicles[0].Name.toLowerCase().indexOf(searchText) !== -1) ||
            (t.Vehicles[0].Driver != null && t.Vehicles[0].Driver.toLowerCase().indexOf(searchText) !== -1) 
            )
        )
          return true;
        if (
          searchText.length > 1 &&
          t.Hardware != null &&
          t.Hardware.GlobalDeviceId != null &&
          t.Hardware.GlobalDeviceId.toLowerCase().indexOf(searchText) !== -1
        )
          return true;
        return false;
      });
    }
    return unitList;
  }
  UpdateVisibleTrakkerMarkers() {
    //console.log(this.trakkerMarkers.getLayers());

    this.markers.forEach((m) => {
      if (m.hasOwnProperty("TrakkerId") == true) {
        var hide = this.trakkerSearchResult.find((t) => t.TrakkerId == m.TrakkerId) == null;
        //console.log(hide);

        if (m.Visible == true && hide == true) {
          // m.MapObject.remove();
          m.Visible = false;
          this.trakkerMarkers.removeLayer(m.MapObject);
        }
        if (m.Visible == false && hide == false) {
          // m.MapObject.addTo(this.map);
          m.Visible = true;
          this.trakkerMarkers.addLayer(m.MapObject);
        }
      }
    });
  }
  TryShowTrakkerOnMap() {
    if (this.MapDisabled) {
      return;
    }

    if (this.trakkerSearchResult.length != 1) return;
    var trakker = this.trakkerSearchResult[0];
    this.CenterOnMap(trakker);
  }
  TryToggleSelectTrakker() {
    if (this.MapDisabled) {
      return;
    }

    if (this.trakkerSearchResult.length != 1) return;
    var trakker = this.trakkerSearchResult[0];

    if (this.selectedTrakker != null) {
      var marker = this.markers.find((m) => m.TrakkerId == this.selectedTrakker.TrakkerId);
      if (marker) {
        marker.MapObject.removeFrom(this.map);
        this.trakkerMarkers.addLayer(marker.MapObject);

      }
    }

    if (this.selectedTrakker == trakker) {
      // if(trakker)
      // {
      //     console.log(trakker);
      // }
      this.selectedTrakker = null;
      return;
    }
    this.selectedTrakker = trakker;

    if (trakker) {
      var marker = this.markers.find((m) => m.TrakkerId == trakker.TrakkerId);
      if (marker) {
        marker.MapObject.removeFrom(this.trakkerMarkers);
        this.map.addLayer(marker.MapObject);

      }
    }
  }
  StartAddTrakker(object = null) {
    if (object.hasOwnProperty("GeofenceId")) {
      if (this.selectedGeofence.GeofenceId != -1) {
        this.selectedObject = this.selectedGeofence;
      } else {
        this.selectedObject = object;
      }
    }
    this.modal = "addTrakker";
    this.visibleAddTrakkers = this.unitList;
    this.cdr.detectChanges();
    setTimeout(() => {
      $(".modalWrapper .defaultAction").trigger("focus");
    }, 1);
  }
  IsTrakkerConnectedToObject(trakker) {
    if (this.selectedObject == null) {
      return false;
    }
    if (this.selectedObject.hasOwnProperty("TrakkerId")) {
      return;
    }
    if (this.selectedObject.Trakkers.filter((t) => t.TrakkerId == trakker.TrakkerId).length > 0) {
      return true;
    }
    return false;
  }
  AddTrakkerToObject(object, trakker) {
    if (object == null) {
      return;
    }
    if (object.hasOwnProperty("GeofenceId")) {
      this.subscriptions.push(
        this.trakkerService
          .addTrakkerGeofence(trakker.TrakkerId, object.GeofenceId)
          .pipe(take(1))
          .subscribe((result) => {
            trakker.Geofences.push(object);
            object.Trakkers.push(trakker);
            this.cdr.detectChanges();
          })
      );
    }
  }
  UpdateVisibleAddTrakkers(event) {
    if (event.target.value == "") this.visibleAddTrakkers = this.unitList;
    var searchText = event.target.value.toLowerCase();
    this.visibleAddTrakkers = this.unitList.filter(function (t) {
      if (t.Name.toLowerCase().indexOf(searchText) !== -1) return true;
      if (
        t.Vehicles != null &&
        t.Vehicles.length > 0 &&
        ((t.Vehicles[0].VehicleRegistrationNumber != null &&
          t.Vehicles[0].VehicleRegistrationNumber.toLowerCase().indexOf(searchText) !== -1) ||
          (t.Vehicles[0].Name != null && t.Vehicles[0].Name.toLowerCase().indexOf(searchText) !== -1) ||
          (t.Vehicles[0].Driver != null && t.Vehicles[0].Driver.toLowerCase().indexOf(searchText) !== -1))
      )
        return true;
      return false;

      // return t.Name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }
  TryToggleTrakker() {
    if (this.visibleAddTrakkers.length != 1) return;
    var trakker = this.visibleAddTrakkers[0];

    if (this.IsTrakkerConnectedToObject(trakker) == false && this.selectedObject != null)
      this.AddTrakkerToObject(this.selectedObject, trakker);

    if (this.IsTrakkerConnectedToObject(trakker) == true && this.selectedObject != null)
      this.RemoveGeofenceFromObject(trakker, this.selectedObject);
  }
  StartChangeIcon() {
    this.modal = "changeIcon";
    this.selectedObject = this.selectedTrakker;
    // setTimeout(() => {  $('iconpicker').scrollTop(0); }, 1);
    this.loadedIconsOnce = true;
  }
  StartEditTrakkerName() {
    if (this.editTrakkerName == true) {
      this.StopEditTrakkerName();
      return;
    }

    this.editTrakkerName = true;
    setTimeout(() => {
      $("#inputTrakkerName").val(this.selectedTrakker.Name).trigger("focus");
    }, 1);
  }
  StopEditTrakkerName() {
    this.editTrakkerName = false;
    this.cdr.detectChanges();
  }
  TrySaveTrakkerName() {
    var newName = $("#inputTrakkerName").val();
    var data = {
      Name: newName,
      IconId: this.selectedTrakker.Icon.IconId,
      EquipmentNumber: "",
    };
    this.subscriptions.push(
      this.trakkerService.editTrakkerData(this.selectedTrakker.TrakkerId, data).subscribe((result) => {
        this.selectedTrakker.Name = newName;
        this.StopEditTrakkerName();
      })
    );
  }
  TryConfirmAlert(alert, trakker) {
    this.subscriptions.push(
      this.trakkerService.deleteTrakkerAlert(alert.TrakkerEventId).subscribe((result) => {
        trakker.ActiveAlerts = trakker.ActiveAlerts.filter((a) => a != alert);
        this.cdr.detectChanges();
      })
    );
  }
  TryShowLastHour(trakker) {
    if (this.polyline) {
      this.polyline.remove();
      this.polyline = null;
    }
    var toDateTime = new Date();
    var fromDateTime = new Date(toDateTime.getTime() - 60 * 60000);

    var data = {
      TrakkerIds: [trakker.TrakkerId],
      FromDateTime: fromDateTime.toISOString().replace("Z", ""),
      ToDateTime: toDateTime.toISOString().replace("Z", ""),
      MinAccuracy: 0,
    };

    this.subscriptions.push(
      this.trakkerService.fetchTrakkerEvents(data).subscribe((result) => {
        if (result.Data.length == 0) {
          this.InfoModalHasNoPositionText(trakker.Name);
          this.modal = "info";
          this.infoModal = "trakker_has_no_latest_position";
          this.infoModalObject = trakker;
          this.cdr.detectChanges();
          setTimeout(() => {
            $(".modalWrapper .defaultAction").trigger("focus");
          }, 1);
          this.modalTimer = setTimeout(() => {
            this.modal = null;
            this.infoModal = null;
            this.infoModalObject = null;
          }, 2500);
          return;
        }
        this.DrawLine(result.Data);
      })
    );
  }
  TryShowLastTen(trakker) {
    if (this.polyline) {
      this.polyline.remove();
      this.polyline = null;
    }
    var data = {
      TrakkerIds: [trakker.TrakkerId],
      SearchCount: 10,
    };
    this.subscriptions.push(
      this.trakkerService.fetchTrakkerEvents(data).subscribe((result) => {
        if (result.Data.length == 0) {
          this.InfoModalHasNoPositionText(trakker.Name);
          this.modal = "info";
          this.infoModal = "trakker_has_no_latest_position";
          this.infoModalObject = trakker;
          this.cdr.detectChanges();
          setTimeout(() => {
            $(".modalWrapper .defaultAction").trigger("focus");
          }, 1);
          this.modalTimer = setTimeout(() => {
            this.modal = null;
            this.infoModal = null;
            this.infoModalObject = null;
          }, 2500);
          return;
        }
        this.DrawLine(result.Data);
      })
    );
  }
  DrawLine(trakkerEvents: [any]) {
    var latLngs = [];
    trakkerEvents.forEach((e) => {
      var p = e.Position;
      if (p) {
        latLngs.push([p.Latitude, p.Longitude]);
      }
    });
    this.polyline = L.polyline(latLngs, { color: "red" }).addTo(this.map);
    this.map.fitBounds(this.polyline.getBounds());
  }
  CountTriggeredGeofence(trakker, geofenceType) {
    var triggeredGeofences = this.activeAlerts.filter((a) => a.TrakkerEvent.AlertType?.includes("Geofence") && a.TrakkerEvent.TrakkerId === trakker.TrakkerId);
    if (triggeredGeofences.length > 0) {
      switch (geofenceType) {
        case "exit":
          return triggeredGeofences.filter((g) => g.TrakkerEvent.AlertType == "ExitGeofence").length;
        case "enter":
          return triggeredGeofences.filter((g) => g.TrakkerEvent.AlertType == "EnterGeofence").length;
        default:
          return triggeredGeofences.length;
      }
    }
    return 0;
  }
  TrakkerIdentify(index, item) {
    return item.TrakkerId;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                       Geofence                        */
  /*                                                       */
  /*********************************************************/
  //#region Geofence

  SaveFinishedGeo() {
    this.SaveEditGeofence(this.selectedGeofence);

    const index = this.geofenceList.map((g) => g.GeofenceId).indexOf(this.selectedGeofence.GeofenceId);
    if (index >= 0) {
      this.geofenceList[index] = this.selectedGeofence;
    }

    this.infoFocus = "geofence";
    this.cdr.detectChanges();
  }

  GeoComponentEvent(event: string) {
    switch (event) {
      case "Circle":
        this.StartAddCircleGeofenceShape();
        break;
      case "Polygon":
        this.StartAddPolygonGeofenceShape();
        break;
      case "Stop":
        this.StopCreateGeofence(false);
        break;
      case "Stop&Del":
        this.StopCreateGeofence(true);
        break;
      case "Done":
        this.SaveFinishedGeo();
        break;
    }
  }

  GetAllGeofences() {
    this.subscriptions.push(
      this.geofenceService
        .getAllGeofences()
        .pipe(take(1))
        .subscribe((result) => {
          this.geofenceList = result;
          this.geoList = result;

          this.geofenceSearchResult = result.filter((g) => g.GeofenceType !== "Parking");
          this.geoSearchResult = result;

          for (let geofence of this.geofenceList) {
            var trakkers = [];
            for (let trakker of this.unitList) {
              if (trakker.Geofences.filter((g) => g.GeofenceId == geofence.GeofenceId).length > 0) {
                trakkers.push(trakker);
              }
            }
            geofence.Trakkers = trakkers;
          }

          if (this.routerObjectId && this.routerObjectType == "geofences") {
            const selGeo = this.geofenceList.find((g) => g.GeofenceId == this.routerObjectId);
            if (selGeo) {
              this.selectedGeofence = selGeo;
              this.CenterOnMap(this.selectedGeofence);
            }
          }

          this.unitList.forEach((t) => {
            this.AddTriggeredGeofenceData(t);
          });

          this.loadingTasksDone++;
          this.cdr.detectChanges();
        })
    );
  }
  private AddTriggeredGeofenceData(t: any) {
    if (t.TriggeredGeofences != null && t.TriggeredGeofences.length > 0) {
      t.TriggeredGeofences.forEach((triggeredGeofence) => {
        var a = t.ActiveAlerts.find((a) => a.TrakkerEvent.TrakkerEventId == triggeredGeofence.TrakkerEventId);
        if (a != null) {
          var geofence = this.geofenceList.find((g) => g.GeofenceId == triggeredGeofence.GeofenceId);
          if (geofence != null) {
            a.TriggeredGeofenceName = geofence.Name;
            a.TriggeredGeofenceId = geofence.GeofenceId;
            triggeredGeofence.GeofenceType = geofence.GeofenceType;

            if (geofence.Triggered == null) {
              geofence.Triggered = [];
            }
            if (geofence.Triggered.find((tt) => tt.TrakkerId == t.TrakkerId) == null) {
              geofence.Triggered.push(t);
            }
          }
        }
      });
    }
  }

  RefreshGeofences() {
    var backupSelectedGeofence = this.selectedGeofence;
    this.subscriptions.push(
      this.geofenceService
        .getAllGeofences()
        .pipe(take(1))
        .subscribe((result) => {
          this.geofenceList = result.filter(g => g.GeofenceType !== "Parking");
          this.geofenceSearchResult = result;
          for (let geofence of this.geofenceList) {
            var trakkers = [];
            for (let trakker of this.unitList) {
              if (trakker.Geofences.filter((g) => g.GeofenceId == geofence.GeofenceId).length > 0) {
                trakkers.push(trakker);
              }
            }
            geofence.Trakkers = trakkers;
          }

          if (backupSelectedGeofence != null) {
            const selGeo = this.geofenceSearchResult.find((g) => g.GeofenceId == backupSelectedGeofence.GeofenceId);
            if (selGeo) {
              this.selectedGeofence = selGeo;
            }
          }

          this.cdr.detectChanges();
        })
    );
  }
  ShowAllGeofences() {
    this.showAllGeofences = !this.showAllGeofences;
    this.selectedGeofence = null;

    if (this.showAllGeofences == false) {
      this.ClearGeofenceLayer();
      return;
    }

    for (let geofence of this.geofenceList) {
      var mapObject = this.AddGeofence(geofence);
      mapObject.addTo(this.map);
      this.geofenceMapObjects.push({
        GeofenceId: geofence.GeofenceId,
        MapObject: mapObject,
      });
    }
  }
  DrawAndCenterGeofence(geofence) {
    if (this.showAllGeofences == false) {
      var mapObject = this.AddGeofence(geofence);
      mapObject.addTo(this.map);
      this.geofenceMapObjects.push({
        GeofenceId: geofence.GeofenceId,
        MapObject: mapObject,
      });
      this.map.fitBounds(mapObject.getBounds(), { padding: [50, 50] });
      return;
    }

    var g = this.geofenceMapObjects.find(({ GeofenceId }) => GeofenceId == geofence.GeofenceId);
    this.map.fitBounds(g.MapObject.getBounds(), { padding: [50, 50] });
  }
  AddGeofence(geofence) {
    var geojsonFeature = geofence.GeoJsonFeature;

    var myStyle = {
      color: "#000",
      fillColor: "#ff7800",
      weight: 2,
      opacity: 0.3,
      fillOpacity: 0.2,
    };
    var mapObject = null;

    if (geojsonFeature.properties.hasOwnProperty("bufferRadius")) {
      if (geojsonFeature.geometry.type == "Point") {
        mapObject = L.circle(
          [geojsonFeature.geometry.coordinates[1], geojsonFeature.geometry.coordinates[0]],
          geojsonFeature.properties.bufferRadius,
          myStyle
        );
      }
    } else {
      mapObject = L.geoJSON(geojsonFeature, {
        style: myStyle,
      });
    }

    // WHEN GEOFENCE POLYGON ON MAP IS CLICKED
    mapObject.on("click", function () {
      $("#infoFocusGeofences").trigger("click");
      setTimeout(function () {
        $("#geofence" + geofence.GeofenceId).trigger("click");
      }, 200);
    });

    return mapObject;
  }
  AddEditShapeGeofence(geofence) {
    var geojsonFeature = geofence.GeoJsonFeature;

    var myStyle = {
      color: "#000",
      fillColor: "#00ff78",
      weight: 2,
      opacity: 0.3,
      fillOpacity: 0.2,
    };

    var mapObject = null;

    if (geojsonFeature.properties.hasOwnProperty("bufferRadius")) {
      if (geojsonFeature.geometry.type == "Point") {
        mapObject = L.circle(
          [geojsonFeature.geometry.coordinates[1], geojsonFeature.geometry.coordinates[0]],
          geojsonFeature.properties.bufferRadius,
          {
            pmIgnore: false,
          }
        );
      }
    } else {
      mapObject = L.geoJSON(geojsonFeature, {
        style: myStyle,
        pmIgnore: false,
      });
    }

    return mapObject;
  }
  IsGeofenceVisible(geofence) {
    if (this.showAllGeofences == true) {
      return true;
    }

    var g = this.geofenceMapObjects.find(({ GeofenceId }) => GeofenceId == geofence.GeofenceId);
    if (g) {
      return true;
    }

    return false;
  }
  ClearGeofenceLayer() {
    if (this.editShapeGeofence != null) {
      this.map.off("pm:create");
      this.editShapeGeofence.remove();
      this.editShapeGeofence = null;
    }

    if (this.geofenceMapObjects.length > 0) {
      for (let geofence of this.geofenceMapObjects) {
        geofence.MapObject.remove();
      }
    }
    this.geofenceMapObjects = [];
  }
  StartCreateGeofence() {
    this.selectedGeofence = {
      GeofenceId: -1,
      Name: "",
      GeoJsonFeature: null,
      Contacts: [],
      Schedules: [],
      GeofenceType: "Exit",
      Trakkers: [],
      IsTimeControlled: false,
      Timezone: this.currentUser.Timezone,
      TimezoneId: this.currentUser.TimezoneId,
      IsActive: true,
      ActiveForAllUnits: false,
    };
    this.infoFocus = "createGeofence";
    this.cdr.detectChanges();
    setTimeout(() => {
      $("#geofenceNameInput").trigger("focus");
    }, 1);
  }
  SaveCreateGeofence() {
    this.selectedGeofence.Name = $("#geofenceNameInput").val();
    if (this.selectedGeofence.Name == "") {
      this.InfoModalNameMissing();
      this.modal = "info";
      this.infoModal = "name_missing";
      setTimeout(() => {
        $(".modalWrapper .defaultAction").trigger("focus");
      }, 1);
      this.modalTimer = setTimeout(() => {
        this.modal = null;
        this.infoModal = null;
        this.infoModalObject = null;
      }, 2500);
      return;
    }

    if (this.editShapeGeofence == null) {
      this.InfoModalGeofenceShapeMissing();
      this.modal = "info";
      this.infoModal = "geofence_shape_missing";
      setTimeout(() => {
        $(".modalWrapper .defaultAction").trigger("focus");
      }, 1);
      this.modalTimer = setTimeout(() => {
        this.modal = null;
        this.infoModal = null;
        this.infoModalObject = null;
      }, 2500);
      return;
    }
    if (this.editShapeGeofence.options.hasOwnProperty("radius")) {
      var latLng = this.editShapeGeofence.getLatLng();
      var bufferRadius = Math.round(this.editShapeGeofence.getRadius());
      var geojsonFeature = {
        type: "Feature",
        properties: {
          bufferRadius: bufferRadius,
        },
        geometry: {
          coordinates: [latLng.lng, latLng.lat],
          type: "Point",
        },
      };
      this.selectedGeofence.GeoJsonFeature = geojsonFeature;
    } else {
      var featureCollection = this.editShapeGeofence.toGeoJSON();
      this.selectedGeofence.GeoJsonFeature = featureCollection.features[0];
    }

    switch (this.selectedGeofence.GeofenceTypeId) {
      case 1:
        this.selectedGeofence.GeofenceType = "Exit";
        break;
      case 2:
        this.selectedGeofence.GeofenceType = "Enter";
        break;
      case 3:
        this.selectedGeofence.GeofenceType = "Intersect";
        break;
    }

    this.subscriptions.push(
      this.geofenceService
        .createNewGeofence(this.selectedGeofence)
        .pipe(take(1))
        .subscribe((result) => {
          this.selectedGeofence.GeofenceId = result;
          this.selectedGeofence = { ...this.selectedGeofence };
          this.geofenceList.push(this.selectedGeofence);
          this.cdr.detectChanges();
          this.GetAllGeofences();
          this.subscriptions.push(
            this.geofenceService.getGeofence(this.selectedGeofence.GeofenceId).subscribe((res) => {
              this.selectedGeofenceScheduleId = res?.ScheduleId;
            })
          );
        })
    );
  }
  StopCreateGeofence(del = false) {
    if (this.shapeControl != null) {
      this.shapeControl.remove();
      this.shapeControl == null;
    }

    if (this.editShapeGeofence != null) {
      this.editShapeGeofence.remove();
      this.editShapeGeofence == null;
    }

    if (del) {
      this.DeleteGeofence();
    }
    this.infoFocus = "geofences";
    this.cdr.detectChanges();
  }

  StartAddCircleGeofenceShape = () => {
    this.ClearGeofenceLayer();
    this.map.pm.enableDraw("Circle", {
      snappable: false,
      finishOn: "dblclick",
    });
    this.map.on("pm:create", (e) => {
      if (e.layer.options.hasOwnProperty("radius") == false) return;
      var latLng = e.layer.getLatLng();
      var bufferRadius = Math.round(e.layer.getRadius());
      e.layer.remove();

      var geojsonFeature = {
        type: "Feature",
        properties: {
          bufferRadius: bufferRadius,
        },
        geometry: {
          coordinates: [latLng.lng, latLng.lat],
          type: "Point",
        },
      };

      let tmp: any = { ...this.selectedGeofence };
      tmp.GeoJsonFeature = geojsonFeature;
      this.selectedGeofence = { ...tmp };

      this.cdr.detectChanges();
      this.StartEditGeofenceShape();
    });
  };
  StartAddPolygonGeofenceShape() {
    this.ClearGeofenceLayer();
    this.map.pm.enableDraw("Polygon", {
      snappable: false,
      finishOn: "dblclick",
    });
    this.map.on("pm:create", (e) => {
      if (e.layer.options.hasOwnProperty("radius") == true) return;
      var geojsonFeature = e.layer.toGeoJSON();
      e.layer.remove();

      let tmp: any = { ...this.selectedGeofence };
      tmp.GeoJsonFeature = geojsonFeature;
      this.selectedGeofence = { ...tmp };

      this.cdr.detectChanges();
      this.StartEditGeofenceShape();
    });
  }

  updateGeofence(geofence) {
    this.selectedGeofence = { ...geofence };
    this.selectedObject = { ...geofence };
  }

  StartEditGeofenceShape() {
    if (this.selectedGeofence.GeoJsonFeature == null) return;
    // if(this.infoFocus != "createGeofence"){
    // this.infoFocus ="editGeofence";
    this.ClearGeofenceLayer();
    this.editShapeGeofence = this.AddEditShapeGeofence(this.selectedGeofence);
    this.editShapeGeofence.addTo(this.map);
    this.editShapeGeofence.pm.enable({
      snappable: false,
    });
    this.map.fitBounds(this.editShapeGeofence.getBounds(), {
      padding: [50, 50],
    });
  }
  
  StartEditGeofenceSettings() {
    this.infoFocus = "editGeofence";
    this.ScrollToTop();
  }

  StartEditSchedule(geofence) {
    if (!geofence) {
      this.selectedGeofenceScheduleId = this.selectedGeofence.ScheduleId;
    }

    this.modal = "scheduleEditor";
  }

  ScheduleErrorEvent(error) {
    this.scheduleError = error;
  }
  UpdateScheduleOnSelectedGeofence(events) {
    const tmp = { ...this.selectedGeofence };
    tmp.Schedules = events;
    tmp.IsTimeControlled = true;
    this.selectedGeofence = { ...tmp };
  }

  CancelScheduleEditor() {
    if (this.scheduleError == true) {
      return;
    }

    this.modal = null;
  }
  StopEditGeofence() {
    this.ClearGeofenceLayer();
    this.infoFocus = "geofences";
    this.cdr.detectChanges();
    this.ScrollToSelectedGeofence();
  }
  SaveEditGeofence(geo = null) {
    if (!geo) {
      var trakkers = this.selectedGeofence.Trakkers;
      this.selectedGeofence.Trakkers = [];

      var contacts = this.selectedGeofence.Contacts;
      this.selectedGeofence.Contacts = [];

      var data = JSON.parse(JSON.stringify(this.selectedGeofence));

      this.selectedGeofence.Trakkers = trakkers;
      this.selectedGeofence.Contacts = contacts;

      data.Name = $("#geofenceEditNameInput").val();
      if (this.selectedGeofence.Name == "") {
        return;
      }

      if (this.editShapeGeofence != null) {
        if (this.editShapeGeofence.options.hasOwnProperty("radius")) {
          var latLng = this.editShapeGeofence.getLatLng();
          var bufferRadius = Math.round(this.editShapeGeofence.getRadius());
          var geojsonFeature = {
            type: "Feature",
            properties: {
              bufferRadius: bufferRadius,
            },
            geometry: {
              coordinates: [latLng.lng, latLng.lat],
              type: "Point",
            },
          };
          data.GeoJsonFeature = geojsonFeature;
        } else {
          var featureCollection = this.editShapeGeofence.toGeoJSON();
          data.GeoJsonFeature = featureCollection.features[0];
        }
      }

      switch (this.selectedGeofence.GeofenceTypeId) {
        case 1:
          data.GeofenceType = "Exit";
          break;
        case 2:
          data.GeofenceType = "Enter";
          break;
        case 3:
          data.GeofenceType = "Intersect";
          break;
      }
    } else {
      data = geo;
    }

    this.subscriptions.push(
      this.geofenceService
        .editGeofenceData(this.selectedGeofence.GeofenceId, data)
        .pipe(take(1))
        .subscribe((result) => {
          this.selectedGeofence.Name = data.Name;
          this.selectedGeofence.GeoJsonFeature = data.GeoJsonFeature;
          this.StopEditGeofence();
        })
    );
  }
  ToggleGeofenceSearch() {
    this.searchGeofence = !this.searchGeofence;
    this.extraSpace = this.searchGeofence;
    if (this.searchGeofence == true) {
      setTimeout(() => {
        $("#searchGeofenceText").trigger("focus");
      }, 1);
    }
    if (this.searchGeofence == false) {
      this.geofenceSearchResult = this.geofenceList;
    }
  }
  UpdateVisibleGeofences(event) {
    if (event.target.value == "") this.geofenceSearchResult = this.geofenceList;

    this.geofenceSearchResult = this.geofenceList.filter(function (el) {
      return el.Name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }
  TryShowGeofenceOnMap() {
    if (this.geofenceSearchResult.length != 1) return;
    var geofence = this.geofenceSearchResult[0];
    this.CenterOnMap(geofence);
  }
  TryToggleSelectGeofence() {
    if (this.geofenceSearchResult.length != 1) return;
    var geofence = this.geofenceSearchResult[0];
    if (this.selectedGeofence == geofence) {
      this.selectedGeofence = null;
      return;
    }
    this.selectedGeofence = geofence;
  }
  ToggleSelectedGeofence(geofence) {
    this.selectedGeofence = this.selectedGeofence == geofence ? null : geofence;
    this.ScrollToSelectedGeofence();
  }
  private ScrollToSelectedGeofence() {
    if (this.selectedGeofence != null) {
      setTimeout(function () {
        $("#infoContent").scrollTop(
          $("#infoContent").scrollTop() +
            $("#geofence" + self2.selectedGeofence.GeofenceId)
              .parent()
              .position().top -
            92
        );
      }, 1);
    }
  }

  StartAddGeofence(object) {
    if (object.hasOwnProperty("TrakkerId")) {
      this.selectedObject = this.selectedTrakker;
    }
    this.modal = "addGeofence";
    this.visibleAddGeofences = this.geofenceList;
    this.cdr.detectChanges();
    setTimeout(() => {
      $(".modalWrapper .defaultAction").trigger("focus");
    }, 1);
  }
  IsGeofenceConnectedToObject(geofence) {
    if (this.selectedObject == null) {
      return false;
    }
    if (this.selectedObject.hasOwnProperty("GeofenceId")) {
      return;
    }
    if (this.selectedObject.Geofences.filter((g) => g.GeofenceId == geofence.GeofenceId).length > 0) {
      return true;
    }
    return false;
  }
  AddGeofenceToObject(object, geofence) {
    if (object == null) {
      return;
    }
    if (object.hasOwnProperty("TrakkerId")) {
      this.subscriptions.push(
        this.trakkerService
          .addTrakkerGeofence(object.TrakkerId, geofence.GeofenceId)
          .pipe(take(1))
          .subscribe((result) => {
            var slimGeofence = {
              GeofenceId: geofence.GeofenceId,
              Name: geofence.Name,
              GeofenceType: geofence.GeofenceType,
            };
            object.Geofences.push(slimGeofence);
            geofence.Trakkers.push(object);
            this.cdr.detectChanges();
          })
      );
    }
  }
  RemoveGeofenceFromObject(object, slimGeofence) {
    if (object == null) {
      return;
    }
    var geofence = this.geofenceList.find((g) => g.GeofenceId == slimGeofence.GeofenceId);
    if (geofence == null) {
      return;
    }
    if (object.hasOwnProperty("TrakkerId")) {
      this.subscriptions.push(
        this.trakkerService
          .deleteTrakkerGeofence(object.TrakkerId, geofence.GeofenceId)
          .pipe(take(1))
          .subscribe((result) => {
            var index = object.Geofences.findIndex((g) => g.GeofenceId == geofence.GeofenceId);
            var index2 = geofence.Trakkers.findIndex((t) => t.TrakkerId == object.TrakkerId);
            if (index2 != -1) {
              geofence.Trakkers.splice(index2, 1);
            }
            if (index != -1) {
              object.Geofences.splice(index, 1);
            }
            this.cdr.detectChanges();
          })
      );
    }
  }
  UpdateVisibleAddGeofences(event) {
    if (event.target.value == "") this.visibleAddGeofences = this.geofenceList;

    this.visibleAddGeofences = this.geofenceList.filter(function (el) {
      return el.Name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }
  TryToggleGeofence() {
    if (this.visibleAddGeofences.length != 1) return;
    var geofence = this.visibleAddGeofences[0];

    if (this.IsGeofenceConnectedToObject(geofence) == false && this.selectedObject != null)
      this.AddGeofenceToObject(this.selectedObject, geofence);

    if (this.IsGeofenceConnectedToObject(geofence) == true && this.selectedObject != null)
      this.RemoveGeofenceFromObject(this.selectedObject, geofence);
  }
  VerifyDeleteGeofence(mapzone) {
    this.modal = "verifyDeleteGeofence";
  }
  DeleteGeofence() {
    this.CenterOnMap(null);
    this.subscriptions.push(
      this.geofenceService
        .deleteGeofence(this.selectedGeofence.GeofenceId)
        .pipe(take(1))
        .subscribe((result) => {
          var index = this.geofenceList.findIndex((g) => g.GeofenceId === this.selectedGeofence.GeofenceId);
          this.geofenceList.splice(index, 1);
          index = this.visibleAddGeofences.findIndex((g) => g.GeofenceId === this.selectedGeofence.GeofenceId);
          this.visibleAddGeofences.splice(index, 1);

          var self = this;
          $.each(this.selectedGeofence.Trakkers, function (idx, t) {
            var index = t.Geofences.findIndex((g) => g.GeofenceId == self.selectedGeofence.GeofenceId);
            if (index != -1) {
              t.Geofences.splice(index, 1);
            }
          });
          this.selectedGeofence = null;
          this.infoFocus = "geofences";
          this.modal = null;
          this.GetAllGeofences();
          this.cdr.detectChanges();
        })
    );
  }
  GeofenceIdentify(index, item) {
    return item.GeofenceId;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                       Mapzone                         */
  /*                                                       */
  /*********************************************************/
  //#region Mapzone
  GetAllMapzones() {
    this.subscriptions.push(
      this.mapzoneService
        .getAllMapzones()
        .pipe(take(1))
        .subscribe((result) => {
          this.mapzoneList = result;
          this.mapzoneSearchResult = result;

          if (this.routerObjectId && this.routerObjectType == "mapzones") {
            this.selectedMapzone = this.mapzoneList.find((m) => m.MapzoneId == this.routerObjectId);
            if (this.selectedMapzone) this.CenterOnMap(this.selectedMapzone);
          }

          this.loadingTasksDone++;
          this.cdr.detectChanges();
        })
    );
  }
  CreateMapzone() {
    var name = $("#mapzoneNameInput").val();
    var latLng = this.map.getCenter();
    var zoomLevel = this.map.getZoom();

    var data: any = {
      Name: name,
      Latitude: latLng.lat,
      Longitude: latLng.lng,
      ZoomLevel: zoomLevel,
    };

    this.subscriptions.push(
      this.mapzoneService
        .createMapzoneData(data)
        .pipe(take(1))
        .subscribe((result) => {
          data.MapzoneId = result;
          this.mapzoneList.push(data);
          this.infoFocus = "mapzones";
          this.cdr.detectChanges();
        })
    );
  }
  StartCreateMapzone() {
    this.infoFocus = "createMapzone";
    $("#mapzoneNameInput").val("");
    this.cdr.detectChanges();
    setTimeout(() => {
      $("#mapzoneNameInput").trigger("focus");
    }, 1);
  }
  StartEditMapzoneName(mapzone) {
    this.infoFocus = "editMapzoneName";
    this.selectedMapzone = mapzone;
    $("#mapzoneNameInput").val(mapzone.Name);
    setTimeout(() => {
      $("#mapzoneNameInput").trigger("focus");
    }, 1);
  }
  OnMapzoneNameInputEnter() {
    if (this.infoFocus == "editMapzoneName") {
      this.UpdateMapzone();
      return;
    }

    this.CreateMapzone();
  }
  VerifyUpdateMapzone(mapzone) {
    this.modal = "verifyUpdateMapzone";
  }
  UpdateMapzone() {
    if (this.infoFocus == "editMapzoneName") {
      var nameBackup = this.selectedMapzone.Name;
      var name = $("#mapzoneNameInput").val();
      this.selectedMapzone.Name = name;

      this.subscriptions.push(
        this.mapzoneService
          .editMapzoneData(this.selectedMapzone.MapzoneId, this.selectedMapzone)
          .pipe(take(1))
          .subscribe((result) => {
            //console.log("Mapzone updated");
            this.infoFocus = "mapzones";
            this.cdr.detectChanges();
          })
      );
      return;
    }

    //Set new lat lng and zoomlevel
    var latBackup = this.selectedMapzone.Latitude;
    var lngBackup = this.selectedMapzone.Longitude;
    var zoomLevelBackup = this.selectedMapzone.ZoomLevel;
    var latLng = this.map.getCenter();
    var zoomLevel = this.map.getZoom();

    this.selectedMapzone.Latitude = latLng.lat;
    this.selectedMapzone.Longitude = latLng.lng;
    this.selectedMapzone.ZoomLevel = zoomLevel;

    this.subscriptions.push(
      this.mapzoneService
        .editMapzoneData(this.selectedMapzone.MapzoneId, this.selectedMapzone)
        .pipe(take(1))
        .subscribe((result) => {
          //console.log("Mapzone updated");
          this.infoFocus = "mapzones";
          this.modal = null;
          this.cdr.detectChanges();
        })
    );
  }
  VerifyDeleteMapzone(mapzone) {
    this.modal = "verifyDeleteMapzone";
  }
  DeleteMapzone() {
    this.subscriptions.push(
      this.mapzoneService
        .deleteMapzone(this.selectedMapzone.MapzoneId)
        .pipe(take(1))
        .subscribe((result) => {
          //console.log("Mapzone deleted");
          var index = this.mapzoneList.findIndex((m) => m.MapzoneId === this.selectedMapzone.MapzoneId);
          this.mapzoneList.splice(index, 1);

          index = this.mapzoneSearchResult.findIndex((m) => m.MapzoneId === this.selectedMapzone.MapzoneId);
          this.mapzoneSearchResult.splice(index, 1);

          this.selectedMapzone = null;
          this.infoFocus = "mapzones";
          this.modal = null;
          this.cdr.detectChanges();
        })
    );
  }
  ToggleMapzoneSearch() {
    this.searchMapzone = !this.searchMapzone;
    this.extraSpace = this.searchMapzone;
    if (this.searchMapzone == true) {
      setTimeout(() => {
        $("#searchMapzoneText").trigger("focus");
      }, 1);
    }
    if (this.searchMapzone == false) {
      this.mapzoneSearchResult = this.mapzoneList;
    }
  }
  UpdateVisibleMapzones(event) {
    if (event.target.value == "") this.mapzoneSearchResult = this.mapzoneList;

    this.mapzoneSearchResult = this.mapzoneList.filter(function (el) {
      return el.Name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }
  TryUseMapzone() {
    if (this.mapzoneSearchResult.length != 1) return;
    var mapzone = this.mapzoneSearchResult[0];
    this.CenterOnMap(mapzone);
  }
  TryToggleSelectMapzone() {
    if (this.mapzoneSearchResult.length != 1) return;
    var mapzone = this.mapzoneSearchResult[0];
    if (this.selectedMapzone == mapzone) {
      this.selectedMapzone = null;
      return;
    }
    this.selectedMapzone = mapzone;
  }
  MapzoneIdentify(index, item) {
    return item.MapzoneId;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                       Contact                         */
  /*                                                       */
  /*********************************************************/
  //#region Contact
  GetAllContacts() {
    this.subscriptions.push(
      this.accountService
        .getAllContacts()
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.contactList = result;
          this.loadingTasksDone++;
          this.cdr.detectChanges();
        })
    );
  }
  StartAddContact(object) {
    // console.log("addContact");
    if (object.hasOwnProperty("TrakkerId")) {
      this.selectedObject = this.selectedTrakker;
    }
    if (object.hasOwnProperty("GeofenceId")) {
      this.selectedObject = this.selectedGeofence;
    }
    this.visibleContacts = this.contactList;
    $("#searchContactText").val("");
    this.modal = "addContact";
    this.cdr.detectChanges();
    setTimeout(() => {
      $(".modalWrapper .defaultAction").trigger("focus");
    }, 1);
  }
  IsConnectedToObject(contact) {
    if (this.selectedObject == null) {
      return false;
    }
    if (this.selectedObject.Contacts.filter((c) => c.ContactId == contact.ContactId).length > 0) {
      return true;
    }
    return false;
  }
  AddContact(object, contact) {
    if (object == null) {
      return;
    }
    if (object.hasOwnProperty("TrakkerId")) {
      this.subscriptions.push(
        this.trakkerService
          .addTrakkerContact(object.TrakkerId, contact.ContactId)
          .pipe(take(1))
          .subscribe((result) => {
            object.Contacts.push(contact);
            this.cdr.detectChanges();
          })
      );
    }
    if (object.hasOwnProperty("GeofenceId")) {
      this.subscriptions.push(
        this.geofenceService
          .addGeofenceContact(object.GeofenceId, contact.ContactId)
          .pipe(take(1))
          .subscribe((result) => {
            object.Contacts.push(contact);
            this.cdr.detectChanges();
          })
      );
    }
  }
  RemoveContact(object, contact) {
    if (object == null) {
      return;
    }
    if (object.hasOwnProperty("TrakkerId")) {
      this.subscriptions.push(
        this.trakkerService
          .deleteTrakkerContact(object.TrakkerId, contact.ContactId)
          .pipe(take(1))
          .subscribe((result) => {
            var index = object.Contacts.findIndex((c) => c.ContactId == contact.ContactId);
            if (index == -1) {
              return;
            }
            object.Contacts.splice(index, 1);
            this.cdr.detectChanges();
          })
      );
    }
    if (object.hasOwnProperty("GeofenceId")) {
      this.subscriptions.push(
        this.geofenceService
          .removeGeofenceContact(object.GeofenceId, contact.ContactId)
          .pipe(take(1))
          .subscribe((result) => {
            var index = object.Contacts.findIndex((c) => c.ContactId == contact.ContactId);
            if (index == -1) {
              return;
            }
            object.Contacts.splice(index, 1);
            this.cdr.detectChanges();
          })
      );
    }
  }
  StartCreateContact() {
    $("#contactNameInput").val("");
    $("#contactEmailInput").val("");
    $("#contactNumberInput").val("");
    this.createContact = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      $(".modalWrapper .defaultAction").trigger("focus");
    }, 1);
  }
  CreateContact() {
    var customerId = this.selectedObject.CustomerId;
    var name = $("#contactNameInput").val();
    var email = $("#contactEmailInput").val();
    var number = $("#contactNumberInput").val();
    var data: any = {
      Name: name,
      Email: email,
      Number: number,
    };
    this.subscriptions.push(
      this.accountService
        .createNewContact(customerId, data)
        .pipe(take(1))
        .subscribe((result) => {
          data.ContactId = result;
          this.contactList.push(data);
          this.AddContact(this.selectedObject, data);
          this.createContact = false;
          this.cdr.detectChanges();
        })
    );
  }
  UpdateVisibleContacts(event) {
    if (event.target.value == "") this.visibleContacts = this.contactList;

    this.visibleContacts = this.contactList.filter(function (el) {
      return el.Name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }
  TryToggleContact() {
    if (this.visibleContacts.length != 1) return;
    var contact = this.visibleContacts[0];

    if (this.IsConnectedToObject(contact) == false && this.selectedObject != null)
      this.AddContact(this.selectedObject, contact);

    if (this.IsConnectedToObject(contact) == true && this.selectedObject != null)
      this.RemoveContact(this.selectedObject, contact);
  }
  ContactIdentify(index, item) {
    return item.ContactId;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                       Groups                          */
  /*                                                       */
  /*********************************************************/
  //#region Groups
  GetAllGroups() {
    this.subscriptions.push(
      this.accountService
        .getAllGroups()
        .pipe(take(1))
        .subscribe((result) => {
          this.GetFavoriteTrakkers();
          result.forEach((grp) => {
            var accountSettingsForGroup = grp.Accounts.find((a) => a.Account.AccountId == this.currentUser.AccountId);
            if (accountSettingsForGroup != null) {
              grp.IsFavorite = accountSettingsForGroup.IsFavorite;
            } else {
              grp.IsFavorite = false;
            }
          });
          // console.log(result);

          this.groupList = result;
          this.groupSearchResult = result;
          //console.log(this.geofenceList);

          if (this.routerObjectId && this.routerObjectType == "groups") {
            this.selectedGroup = this.groupList.find((g) => g.GroupId == this.routerObjectId);
            if (this.selectedGroup) this.CenterOnMap(this.selectedGroup);
          }

          // this.SortGroupsBy("name");
          // this.SortGroupsBy("trakkers");
          this.loadingTasksDone++;
          this.cdr.detectChanges();
        })
    );
  }
  ToggleGroupFavoritesFilter() {
    this.groupFavoritesFilter = !this.groupFavoritesFilter;
    this.UpdateVisibleGroups();
  }

  GroupDropdown(g) {
    if (this.groupDropdownVisible == true) {
      this.selectedGroup = g;
      this.searchTrakker = false;
      this.UpdateVisibleUnits();
      // this.UpdateVisibleGroups();
    }
    this.ToggleGroupSearch();
  }
  SearchGroups(groupList, searchText) {
    return groupList.filter((g) => g.Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
  UpdateVisibleGroups(event = null) {
    var groupList = this.groupList;

    if (this.groupFavoritesFilter == true) {
      groupList = groupList.filter((g) => g.IsFavorite == true);
    }

    if (this.hideEmptyGroups == true) {
      groupList = groupList.filter((g) => g.Trakkers.length != 0);
    }

    var searchText = $("#searchGroupText").val();
    if (event != null) {
      searchText = event.target.value;
    }

    if (searchText != "" && searchText != null) {
      this.groupSearchResult = this.SearchGroups(groupList, searchText);
      this.SortGroupsBy(this.sortGroupsBy);
      return;
    }

    this.groupSearchResult = groupList;
    this.SortGroupsBy(this.sortGroupsBy);
    return;
  }
  SortGroupsBy(order) {
    this.sortGroupsBy = order;

    switch (order) {
      case "name":
        this.groupSearchResult.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
        break;
      case "trakkers":
        this.groupSearchResult.sort((a, b) => b.Trakkers.length - a.Trakkers.length);
        break;
    }
  }
  ToggleHideEmptyGroups() {
    this.hideEmptyGroups = !this.hideEmptyGroups;
    this.UpdateVisibleGroups();
  }
  ToggleGroupSearch() {
    this.groupDropdownVisible = !this.groupDropdownVisible;
    if (this.groupDropdownVisible == true) {
      $("#searchGroupText").val("");
      this.UpdateVisibleGroups();
      this.geoDropdownVisible = false;
    }
    this.cdr.detectChanges();
  }
  TrySelectGroup() {
    if (this.groupSearchResult.length != 1) return;

    this.GroupDropdown(this.groupSearchResult[0]);
  }
  TryToggleFavoriteGroup(group) {
    var data = { NewFavoriteStatus: !group.IsFavorite };
    this.subscriptions.push(
      this.accountService
        .editGroupFavorite(group.GroupId, data)
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(data.NewFavoriteStatus);
          group.IsFavorite = data.NewFavoriteStatus;
          this.cdr.detectChanges();
        })
    );
  }
  GroupIdentify(index, item) {
    return item.GroupId;
  }
  //#endregion

  /*********************************************************/
  /*                                                       */
  /*                       Geos                            */
  /*                                                       */
  /*********************************************************/
  //#region Geos

  // unitList = this.selectedGroup.Trakkers.filter(t => t.IsFavorite == true);
  // this.trakkerSearchResult

  GeoDropdown(geo) {
    // WHENEVER ANY GEOFENCE ITEM IS CLICKED
    if (this.geoDropdownVisible == true) {
      // WHENEVER ANY GEOFENCE ITEM IS CLICKED WHEN DROPDOWN IS OPEN
      this.selectedGeo = geo;
      if (geo) {
        this.subscriptions.push(
          this.trakkerService.getTrakkers(`?filter.geofenceId=${geo.GeofenceId}`).subscribe((result) => {
            this.unitsInGeofence = result;
            // console.log(geo.GeofenceId);
            // console.log("Units in geofence: ");
            // console.log(this.unitsInGeofence);
            this.CenterOnMap(geo);
            this.UpdateVisibleUnits();
          })
        );
      } else {
        this.CenterOnMap(null);
        this.UpdateVisibleUnits();
      }
    }
    this.ToggleGeoSearch();
  }
  SearchGeos(geoList, searchText) {
    return geoList.filter((g) => g.Name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
  UpdateVisibleGeos(event = null) {
    // console.log("Updating visible geos. Sort geos by: " + this.sortGeosBy);
    var geoList = this.geoList;

    if (this.geoFavoritesFilter == true) {
      geoList = geoList.filter((g) => g.IsFavorite == true);
    }

    // if(this.hideEmptyGeos == true)
    // {
    //     geoList = geoList.filter(g => g.Trakkers.length != 0);
    // }

    var searchText = $("#searchGeoText").val();
    if (event != null) {
      searchText = event.target.value;
    }

    if (searchText != "" && searchText != null) {
      this.geoSearchResult = this.SearchGeos(geoList, searchText);
      this.SortGeosBy(this.sortGeosBy);
      return;
    }

    this.geoSearchResult = geoList;
    this.SortGeosBy(this.sortGeosBy);
    return;
  }
  SortGeosBy(order) {
    // console.log("Sorting geos by: " + order);
    this.sortGeosBy = order;

    switch (order) {
      case "name":
        this.geoSearchResult.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
        break;
      case "trakkers":
        this.geoSearchResult.sort((a, b) => b.Trakkers.length - a.Trakkers.length);
        break;
    }
  }
  // ToggleHideEmptyGroups(){
  //     this.hideEmptyGroups = !this.hideEmptyGroups;
  //     this.UpdateVisibleGroups();
  // }
  ToggleGeoSearch() {
    this.geoDropdownVisible = !this.geoDropdownVisible;
    if (this.geoDropdownVisible == true) {
      $("#searchGeoText").val("");
      this.UpdateVisibleGeos();
      this.groupDropdownVisible = false;
    }
    this.cdr.detectChanges();
  }
  TrySelectGeo() {
    if (this.geoSearchResult.length != 1) return;

    this.GeoDropdown(this.geoSearchResult[0]);
    this.CenterOnMap(this.selectedGeo);
  }
  GeoIdentify(index, item) {
    return item.GeofenceId;
  }
  //#endregion

  IsNumber(str) {
    return isNaN(Number(str)) == false;
  }

  calculateTrakkersTriggeringGeofence(geofence) {
    // console.log(geofence);

    // TOTAL CONNECTED TRAKKERS
    let totalConnectedTrakkers = 0;
    if (geofence.ActiveForAllUnits == false) {
      totalConnectedTrakkers = this.calculateTrakkerTotalWithThisGeofence(geofence.GeofenceId);
    } else {
      totalConnectedTrakkers = this.trakkerSearchResult.length;
      // console.log("full array is : " + this.trakkerSearchResult.length);
    }
    // console.log("TotalConnectedTrakkers are: " + totalConnectedTrakkers);

    // CALCULATE TRIGGERED
    let triggeredGeofences = 0;
    if (geofence.GeofenceType == "Enter") {
      // console.log("Length is : " + geofence.UnitsInsideGeofence.length);
      if (geofence.UnitsInsideGeofence) {
        triggeredGeofences = geofence.UnitsInsideGeofence.length;
      } else {
        triggeredGeofences = 0;
      }
    } else if (geofence.GeofenceType == "Exit") {
      if (geofence.UnitsInsideGeofence) {
        triggeredGeofences = totalConnectedTrakkers - geofence.UnitsInsideGeofence.length;
      } else {
        triggeredGeofences = totalConnectedTrakkers;
      }
    }
    // console.log("TrakkersTriggeringThisGeofence are: " + triggeredGeofences);
    return triggeredGeofences;
  }

  calculateTrakkerTotalWithThisGeofence(geofenceId) {
    let trakkerTotal = 0;
    this.trakkerSearchResult.forEach((trakker) => {
      trakker.Geofences.forEach((geo) => {
        if (geo.GeofenceId == geofenceId) {
          trakkerTotal += 1;
        }
      });
    });
    // console.log("Total trakkers within geofence are: " + trakkerTotal);
    return trakkerTotal;
  }

  OnScroll(event)
  {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight * .9) {
      self2.currentPage++;
    }
  }

  PreventShortcuts(event = null) {
    return;
  }

  getBackgroundClass(trakker: any): string {
    return trakker?.Hardware?.EmergencyModeEnabled ? 'objectWrapper emergency-mode' : 'objectWrapper';
  }
}
