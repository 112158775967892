import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  @Input() event: any;
  @Input() outputType: string;
  constructor() { }

  ngOnInit() {
    if(this.outputType==null)
      this.outputType="icon";
    this.outputType=this.outputType.toLocaleLowerCase();
  }
  GetHeadingText(){
    if(this.event == null)
      return "Page.Labels.NA";
    if(this.event.Heading == null)
      return "Page.Labels.NA";

    var h:number = Math.floor((this.event.Heading + 22.5) / 45);
    switch(h)
    {
      case 0:
      case 8:
          return "Page.Labels.NORTH";
      case 1:
          return "Page.Labels.NORTHEAST";
      case 2:
          return "Page.Labels.EAST";
      case 3:
          return "Page.Labels.SOUTHEAST";
      case 4:
          return "Page.Labels.SOUTH";
      case 5:
          return "Page.Labels.SOUTHWEST";
      case 6:
          return "Page.Labels.WEST";
      case 7:
          return "Page.Labels.NORTHWEST";
    }

    return "Page.Labels.NA";
  }
}
