import { Component, OnInit, Input } from '@angular/core';
//import * as moment from 'moment';

@Component({
  selector: 'dateTime',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.sass']
})
export class DateTimeComponent implements OnInit {
  @Input() utcDateTime:string;
  @Input() locales:any = null; //'sv-se' 'en-gb' 'en.us'
  @Input() options:any = null; //{ timeZone: 'UTC' }
  outputString :string = "";
  realDateTime:Date;
  zt:any =  [
            {
              "-other": "Dateline Standard Time",
              "-territory": "001",
              "-type": "Etc/GMT+12"
            },
            {
              "-other": "Dateline Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+12"
            },
            {
              "-other": "UTC-11",
              "-territory": "001",
              "-type": "Etc/GMT+11"
            },
            {
              "-other": "UTC-11",
              "-territory": "AS",
              "-type": "Pacific/Pago_Pago"
            },
            {
              "-other": "UTC-11",
              "-territory": "NU",
              "-type": "Pacific/Niue"
            },
            {
              "-other": "UTC-11",
              "-territory": "UM",
              "-type": "Pacific/Midway"
            },
            {
              "-other": "UTC-11",
              "-territory": "ZZ",
              "-type": "Etc/GMT+11"
            },
            {
              "-other": "Aleutian Standard Time",
              "-territory": "001",
              "-type": "America/Adak"
            },
            {
              "-other": "Aleutian Standard Time",
              "-territory": "US",
              "-type": "America/Adak"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "001",
              "-type": "Pacific/Honolulu"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "CK",
              "-type": "Pacific/Rarotonga"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "PF",
              "-type": "Pacific/Tahiti"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "UM",
              "-type": "Pacific/Johnston"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "US",
              "-type": "Pacific/Honolulu"
            },
            {
              "-other": "Hawaiian Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+10"
            },
            {
              "-other": "Marquesas Standard Time",
              "-territory": "001",
              "-type": "Pacific/Marquesas"
            },
            {
              "-other": "Marquesas Standard Time",
              "-territory": "PF",
              "-type": "Pacific/Marquesas"
            },
            {
              "-other": "Alaskan Standard Time",
              "-territory": "001",
              "-type": "America/Anchorage"
            },
            {
              "-other": "Alaskan Standard Time",
              "-territory": "US",
              "-type": "America/Anchorage America/Juneau America/Metlakatla America/Nome America/Sitka America/Yakutat"
            },
            {
              "-other": "UTC-09",
              "-territory": "001",
              "-type": "Etc/GMT+9"
            },
            {
              "-other": "UTC-09",
              "-territory": "PF",
              "-type": "Pacific/Gambier"
            },
            {
              "-other": "UTC-09",
              "-territory": "ZZ",
              "-type": "Etc/GMT+9"
            },
            {
              "-other": "Pacific Standard Time (Mexico)",
              "-territory": "001",
              "-type": "America/Tijuana"
            },
            {
              "-other": "Pacific Standard Time (Mexico)",
              "-territory": "MX",
              "-type": "America/Tijuana America/Santa_Isabel"
            },
            {
              "-other": "UTC-08",
              "-territory": "001",
              "-type": "Etc/GMT+8"
            },
            {
              "-other": "UTC-08",
              "-territory": "PN",
              "-type": "Pacific/Pitcairn"
            },
            {
              "-other": "UTC-08",
              "-territory": "ZZ",
              "-type": "Etc/GMT+8"
            },
            {
              "-other": "Pacific Standard Time",
              "-territory": "001",
              "-type": "America/Los_Angeles"
            },
            {
              "-other": "Pacific Standard Time",
              "-territory": "CA",
              "-type": "America/Vancouver America/Dawson America/Whitehorse"
            },
            {
              "-other": "Pacific Standard Time",
              "-territory": "US",
              "-type": "America/Los_Angeles"
            },
            {
              "-other": "Pacific Standard Time",
              "-territory": "ZZ",
              "-type": "PST8PDT"
            },
            {
              "-other": "US Mountain Standard Time",
              "-territory": "001",
              "-type": "America/Phoenix"
            },
            {
              "-other": "US Mountain Standard Time",
              "-territory": "CA",
              "-type": "America/Dawson_Creek America/Creston America/Fort_Nelson"
            },
            {
              "-other": "US Mountain Standard Time",
              "-territory": "MX",
              "-type": "America/Hermosillo"
            },
            {
              "-other": "US Mountain Standard Time",
              "-territory": "US",
              "-type": "America/Phoenix"
            },
            {
              "-other": "US Mountain Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+7"
            },
            {
              "-other": "Mountain Standard Time (Mexico)",
              "-territory": "001",
              "-type": "America/Chihuahua"
            },
            {
              "-other": "Mountain Standard Time (Mexico)",
              "-territory": "MX",
              "-type": "America/Chihuahua America/Mazatlan"
            },
            {
              "-other": "Mountain Standard Time",
              "-territory": "001",
              "-type": "America/Denver"
            },
            {
              "-other": "Mountain Standard Time",
              "-territory": "CA",
              "-type": "America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife"
            },
            {
              "-other": "Mountain Standard Time",
              "-territory": "MX",
              "-type": "America/Ojinaga"
            },
            {
              "-other": "Mountain Standard Time",
              "-territory": "US",
              "-type": "America/Denver America/Boise"
            },
            {
              "-other": "Mountain Standard Time",
              "-territory": "ZZ",
              "-type": "MST7MDT"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "001",
              "-type": "America/Guatemala"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "BZ",
              "-type": "America/Belize"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "CR",
              "-type": "America/Costa_Rica"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "EC",
              "-type": "Pacific/Galapagos"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "GT",
              "-type": "America/Guatemala"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "HN",
              "-type": "America/Tegucigalpa"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "NI",
              "-type": "America/Managua"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "SV",
              "-type": "America/El_Salvador"
            },
            {
              "-other": "Central America Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+6"
            },
            {
              "-other": "Central Standard Time",
              "-territory": "001",
              "-type": "America/Chicago"
            },
            {
              "-other": "Central Standard Time",
              "-territory": "CA",
              "-type": "America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute"
            },
            {
              "-other": "Central Standard Time",
              "-territory": "MX",
              "-type": "America/Matamoros"
            },
            {
              "-other": "Central Standard Time",
              "-territory": "US",
              "-type": "America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem"
            },
            {
              "-other": "Central Standard Time",
              "-territory": "ZZ",
              "-type": "CST6CDT"
            },
            {
              "-other": "Easter Island Standard Time",
              "-territory": "001",
              "-type": "Pacific/Easter"
            },
            {
              "-other": "Easter Island Standard Time",
              "-territory": "CL",
              "-type": "Pacific/Easter"
            },
            {
              "-other": "Central Standard Time (Mexico)",
              "-territory": "001",
              "-type": "America/Mexico_City"
            },
            {
              "-other": "Central Standard Time (Mexico)",
              "-territory": "MX",
              "-type": "America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey"
            },
            {
              "-other": "Canada Central Standard Time",
              "-territory": "001",
              "-type": "America/Regina"
            },
            {
              "-other": "Canada Central Standard Time",
              "-territory": "CA",
              "-type": "America/Regina America/Swift_Current"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "001",
              "-type": "America/Bogota"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "BR",
              "-type": "America/Rio_Branco America/Eirunepe"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "CA",
              "-type": "America/Coral_Harbour"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "CO",
              "-type": "America/Bogota"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "EC",
              "-type": "America/Guayaquil"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "JM",
              "-type": "America/Jamaica"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "KY",
              "-type": "America/Cayman"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "PA",
              "-type": "America/Panama"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "PE",
              "-type": "America/Lima"
            },
            {
              "-other": "SA Pacific Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+5"
            },
            {
              "-other": "Eastern Standard Time (Mexico)",
              "-territory": "001",
              "-type": "America/Cancun"
            },
            {
              "-other": "Eastern Standard Time (Mexico)",
              "-territory": "MX",
              "-type": "America/Cancun"
            },
            {
              "-other": "Eastern Standard Time",
              "-territory": "001",
              "-type": "America/New_York"
            },
            {
              "-other": "Eastern Standard Time",
              "-territory": "BS",
              "-type": "America/Nassau"
            },
            {
              "-other": "Eastern Standard Time",
              "-territory": "CA",
              "-type": "America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay"
            },
            {
              "-other": "Eastern Standard Time",
              "-territory": "US",
              "-type": "America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville"
            },
            {
              "-other": "Eastern Standard Time",
              "-territory": "ZZ",
              "-type": "EST5EDT"
            },
            {
              "-other": "Haiti Standard Time",
              "-territory": "001",
              "-type": "America/Port-au-Prince"
            },
            {
              "-other": "Haiti Standard Time",
              "-territory": "HT",
              "-type": "America/Port-au-Prince"
            },
            {
              "-other": "Cuba Standard Time",
              "-territory": "001",
              "-type": "America/Havana"
            },
            {
              "-other": "Cuba Standard Time",
              "-territory": "CU",
              "-type": "America/Havana"
            },
            {
              "-other": "US Eastern Standard Time",
              "-territory": "001",
              "-type": "America/Indianapolis"
            },
            {
              "-other": "US Eastern Standard Time",
              "-territory": "US",
              "-type": "America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay"
            },
            {
              "-other": "Turks And Caicos Standard Time",
              "-territory": "001",
              "-type": "America/Grand_Turk"
            },
            {
              "-other": "Turks And Caicos Standard Time",
              "-territory": "TC",
              "-type": "America/Grand_Turk"
            },
            {
              "-other": "Paraguay Standard Time",
              "-territory": "001",
              "-type": "America/Asuncion"
            },
            {
              "-other": "Paraguay Standard Time",
              "-territory": "PY",
              "-type": "America/Asuncion"
            },
            {
              "-other": "Atlantic Standard Time",
              "-territory": "001",
              "-type": "America/Halifax"
            },
            {
              "-other": "Atlantic Standard Time",
              "-territory": "BM",
              "-type": "Atlantic/Bermuda"
            },
            {
              "-other": "Atlantic Standard Time",
              "-territory": "CA",
              "-type": "America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton"
            },
            {
              "-other": "Atlantic Standard Time",
              "-territory": "GL",
              "-type": "America/Thule"
            },
            {
              "-other": "Venezuela Standard Time",
              "-territory": "001",
              "-type": "America/Caracas"
            },
            {
              "-other": "Venezuela Standard Time",
              "-territory": "VE",
              "-type": "America/Caracas"
            },
            {
              "-other": "Central Brazilian Standard Time",
              "-territory": "001",
              "-type": "America/Cuiaba"
            },
            {
              "-other": "Central Brazilian Standard Time",
              "-territory": "BR",
              "-type": "America/Cuiaba America/Campo_Grande"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "001",
              "-type": "America/La_Paz"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "AG",
              "-type": "America/Antigua"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "AI",
              "-type": "America/Anguilla"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "AW",
              "-type": "America/Aruba"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "BB",
              "-type": "America/Barbados"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "BL",
              "-type": "America/St_Barthelemy"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "BO",
              "-type": "America/La_Paz"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "BQ",
              "-type": "America/Kralendijk"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "BR",
              "-type": "America/Manaus America/Boa_Vista America/Porto_Velho"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "CA",
              "-type": "America/Blanc-Sablon"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "CW",
              "-type": "America/Curacao"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "DM",
              "-type": "America/Dominica"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "DO",
              "-type": "America/Santo_Domingo"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "GD",
              "-type": "America/Grenada"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "GP",
              "-type": "America/Guadeloupe"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "GY",
              "-type": "America/Guyana"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "KN",
              "-type": "America/St_Kitts"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "LC",
              "-type": "America/St_Lucia"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "MF",
              "-type": "America/Marigot"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "MQ",
              "-type": "America/Martinique"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "MS",
              "-type": "America/Montserrat"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "PR",
              "-type": "America/Puerto_Rico"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "SX",
              "-type": "America/Lower_Princes"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "TT",
              "-type": "America/Port_of_Spain"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "VC",
              "-type": "America/St_Vincent"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "VG",
              "-type": "America/Tortola"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "VI",
              "-type": "America/St_Thomas"
            },
            {
              "-other": "SA Western Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+4"
            },
            {
              "-other": "Pacific SA Standard Time",
              "-territory": "001",
              "-type": "America/Santiago"
            },
            {
              "-other": "Pacific SA Standard Time",
              "-territory": "CL",
              "-type": "America/Santiago"
            },
            {
              "-other": "Newfoundland Standard Time",
              "-territory": "001",
              "-type": "America/St_Johns"
            },
            {
              "-other": "Newfoundland Standard Time",
              "-territory": "CA",
              "-type": "America/St_Johns"
            },
            {
              "-other": "Tocantins Standard Time",
              "-territory": "001",
              "-type": "America/Araguaina"
            },
            {
              "-other": "Tocantins Standard Time",
              "-territory": "BR",
              "-type": "America/Araguaina"
            },
            {
              "-other": "E. South America Standard Time",
              "-territory": "001",
              "-type": "America/Sao_Paulo"
            },
            {
              "-other": "E. South America Standard Time",
              "-territory": "BR",
              "-type": "America/Sao_Paulo"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "001",
              "-type": "America/Cayenne"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Rothera Antarctica/Palmer"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "BR",
              "-type": "America/Fortaleza America/Belem America/Maceio America/Recife America/Santarem"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "FK",
              "-type": "Atlantic/Stanley"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "GF",
              "-type": "America/Cayenne"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "SR",
              "-type": "America/Paramaribo"
            },
            {
              "-other": "SA Eastern Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+3"
            },
            {
              "-other": "Argentina Standard Time",
              "-territory": "001",
              "-type": "America/Buenos_Aires"
            },
            {
              "-other": "Argentina Standard Time",
              "-territory": "AR",
              "-type": "America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza"
            },
            {
              "-other": "Greenland Standard Time",
              "-territory": "001",
              "-type": "America/Godthab"
            },
            {
              "-other": "Greenland Standard Time",
              "-territory": "GL",
              "-type": "America/Godthab"
            },
            {
              "-other": "Montevideo Standard Time",
              "-territory": "001",
              "-type": "America/Montevideo"
            },
            {
              "-other": "Montevideo Standard Time",
              "-territory": "UY",
              "-type": "America/Montevideo"
            },
            {
              "-other": "Magallanes Standard Time",
              "-territory": "001",
              "-type": "America/Punta_Arenas"
            },
            {
              "-other": "Magallanes Standard Time",
              "-territory": "CL",
              "-type": "America/Punta_Arenas"
            },
            {
              "-other": "Saint Pierre Standard Time",
              "-territory": "001",
              "-type": "America/Miquelon"
            },
            {
              "-other": "Saint Pierre Standard Time",
              "-territory": "PM",
              "-type": "America/Miquelon"
            },
            {
              "-other": "Bahia Standard Time",
              "-territory": "001",
              "-type": "America/Bahia"
            },
            {
              "-other": "Bahia Standard Time",
              "-territory": "BR",
              "-type": "America/Bahia"
            },
            {
              "-other": "UTC-02",
              "-territory": "001",
              "-type": "Etc/GMT+2"
            },
            {
              "-other": "UTC-02",
              "-territory": "BR",
              "-type": "America/Noronha"
            },
            {
              "-other": "UTC-02",
              "-territory": "GS",
              "-type": "Atlantic/South_Georgia"
            },
            {
              "-other": "UTC-02",
              "-territory": "ZZ",
              "-type": "Etc/GMT+2"
            },
            {
              "-other": "Azores Standard Time",
              "-territory": "001",
              "-type": "Atlantic/Azores"
            },
            {
              "-other": "Azores Standard Time",
              "-territory": "GL",
              "-type": "America/Scoresbysund"
            },
            {
              "-other": "Azores Standard Time",
              "-territory": "PT",
              "-type": "Atlantic/Azores"
            },
            {
              "-other": "Cape Verde Standard Time",
              "-territory": "001",
              "-type": "Atlantic/Cape_Verde"
            },
            {
              "-other": "Cape Verde Standard Time",
              "-territory": "CV",
              "-type": "Atlantic/Cape_Verde"
            },
            {
              "-other": "Cape Verde Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT+1"
            },
            {
              "-other": "UTC",
              "-territory": "001",
              "-type": "Etc/GMT"
            },
            {
              "-other": "UTC",
              "-territory": "GL",
              "-type": "America/Danmarkshavn"
            },
            {
              "-other": "UTC",
              "-territory": "ZZ",
              "-type": "Etc/GMT Etc/UTC"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "001",
              "-type": "Europe/London"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "ES",
              "-type": "Atlantic/Canary"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "FO",
              "-type": "Atlantic/Faeroe"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "GB",
              "-type": "Europe/London"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "GG",
              "-type": "Europe/Guernsey"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "IE",
              "-type": "Europe/Dublin"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "IM",
              "-type": "Europe/Isle_of_Man"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "JE",
              "-type": "Europe/Jersey"
            },
            {
              "-other": "GMT Standard Time",
              "-territory": "PT",
              "-type": "Europe/Lisbon Atlantic/Madeira"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "001",
              "-type": "Atlantic/Reykjavik"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "BF",
              "-type": "Africa/Ouagadougou"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "CI",
              "-type": "Africa/Abidjan"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "GH",
              "-type": "Africa/Accra"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "GM",
              "-type": "Africa/Banjul"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "GN",
              "-type": "Africa/Conakry"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "GW",
              "-type": "Africa/Bissau"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "IS",
              "-type": "Atlantic/Reykjavik"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "LR",
              "-type": "Africa/Monrovia"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "ML",
              "-type": "Africa/Bamako"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "MR",
              "-type": "Africa/Nouakchott"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "SH",
              "-type": "Atlantic/St_Helena"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "SL",
              "-type": "Africa/Freetown"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "SN",
              "-type": "Africa/Dakar"
            },
            {
              "-other": "Greenwich Standard Time",
              "-territory": "TG",
              "-type": "Africa/Lome"
            },
            {
              "-other": "Sao Tome Standard Time",
              "-territory": "001",
              "-type": "Africa/Sao_Tome"
            },
            {
              "-other": "Sao Tome Standard Time",
              "-territory": "ST",
              "-type": "Africa/Sao_Tome"
            },
            {
              "-other": "Morocco Standard Time",
              "-territory": "001",
              "-type": "Africa/Casablanca"
            },
            {
              "-other": "Morocco Standard Time",
              "-territory": "EH",
              "-type": "Africa/El_Aaiun"
            },
            {
              "-other": "Morocco Standard Time",
              "-territory": "MA",
              "-type": "Africa/Casablanca"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "001",
              "-type": "Europe/Berlin"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "AD",
              "-type": "Europe/Andorra"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "AT",
              "-type": "Europe/Vienna"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "CH",
              "-type": "Europe/Zurich"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "DE",
              "-type": "Europe/Berlin Europe/Busingen"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "GI",
              "-type": "Europe/Gibraltar"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "IT",
              "-type": "Europe/Rome"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "LI",
              "-type": "Europe/Vaduz"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "LU",
              "-type": "Europe/Luxembourg"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "MC",
              "-type": "Europe/Monaco"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "MT",
              "-type": "Europe/Malta"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "NL",
              "-type": "Europe/Amsterdam"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "NO",
              "-type": "Europe/Oslo"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "SE",
              "-type": "Europe/Stockholm"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "SJ",
              "-type": "Arctic/Longyearbyen"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "SM",
              "-type": "Europe/San_Marino"
            },
            {
              "-other": "W. Europe Standard Time",
              "-territory": "VA",
              "-type": "Europe/Vatican"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "001",
              "-type": "Europe/Budapest"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "AL",
              "-type": "Europe/Tirane"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "CZ",
              "-type": "Europe/Prague"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "HU",
              "-type": "Europe/Budapest"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "ME",
              "-type": "Europe/Podgorica"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "RS",
              "-type": "Europe/Belgrade"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "SI",
              "-type": "Europe/Ljubljana"
            },
            {
              "-other": "Central Europe Standard Time",
              "-territory": "SK",
              "-type": "Europe/Bratislava"
            },
            {
              "-other": "Romance Standard Time",
              "-territory": "001",
              "-type": "Europe/Paris"
            },
            {
              "-other": "Romance Standard Time",
              "-territory": "BE",
              "-type": "Europe/Brussels"
            },
            {
              "-other": "Romance Standard Time",
              "-territory": "DK",
              "-type": "Europe/Copenhagen"
            },
            {
              "-other": "Romance Standard Time",
              "-territory": "ES",
              "-type": "Europe/Madrid Africa/Ceuta"
            },
            {
              "-other": "Romance Standard Time",
              "-territory": "FR",
              "-type": "Europe/Paris"
            },
            {
              "-other": "Central European Standard Time",
              "-territory": "001",
              "-type": "Europe/Warsaw"
            },
            {
              "-other": "Central European Standard Time",
              "-territory": "BA",
              "-type": "Europe/Sarajevo"
            },
            {
              "-other": "Central European Standard Time",
              "-territory": "HR",
              "-type": "Europe/Zagreb"
            },
            {
              "-other": "Central European Standard Time",
              "-territory": "MK",
              "-type": "Europe/Skopje"
            },
            {
              "-other": "Central European Standard Time",
              "-territory": "PL",
              "-type": "Europe/Warsaw"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "001",
              "-type": "Africa/Lagos"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "AO",
              "-type": "Africa/Luanda"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "BJ",
              "-type": "Africa/Porto-Novo"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "CD",
              "-type": "Africa/Kinshasa"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "CF",
              "-type": "Africa/Bangui"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "CG",
              "-type": "Africa/Brazzaville"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "CM",
              "-type": "Africa/Douala"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "DZ",
              "-type": "Africa/Algiers"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "GA",
              "-type": "Africa/Libreville"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "GQ",
              "-type": "Africa/Malabo"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "NE",
              "-type": "Africa/Niamey"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "NG",
              "-type": "Africa/Lagos"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "TD",
              "-type": "Africa/Ndjamena"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "TN",
              "-type": "Africa/Tunis"
            },
            {
              "-other": "W. Central Africa Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-1"
            },
            {
              "-other": "Jordan Standard Time",
              "-territory": "001",
              "-type": "Asia/Amman"
            },
            {
              "-other": "Jordan Standard Time",
              "-territory": "JO",
              "-type": "Asia/Amman"
            },
            {
              "-other": "GTB Standard Time",
              "-territory": "001",
              "-type": "Europe/Bucharest"
            },
            {
              "-other": "GTB Standard Time",
              "-territory": "CY",
              "-type": "Asia/Nicosia Asia/Famagusta"
            },
            {
              "-other": "GTB Standard Time",
              "-territory": "GR",
              "-type": "Europe/Athens"
            },
            {
              "-other": "GTB Standard Time",
              "-territory": "RO",
              "-type": "Europe/Bucharest"
            },
            {
              "-other": "Middle East Standard Time",
              "-territory": "001",
              "-type": "Asia/Beirut"
            },
            {
              "-other": "Middle East Standard Time",
              "-territory": "LB",
              "-type": "Asia/Beirut"
            },
            {
              "-other": "Egypt Standard Time",
              "-territory": "001",
              "-type": "Africa/Cairo"
            },
            {
              "-other": "Egypt Standard Time",
              "-territory": "EG",
              "-type": "Africa/Cairo"
            },
            {
              "-other": "E. Europe Standard Time",
              "-territory": "001",
              "-type": "Europe/Chisinau"
            },
            {
              "-other": "E. Europe Standard Time",
              "-territory": "MD",
              "-type": "Europe/Chisinau"
            },
            {
              "-other": "Syria Standard Time",
              "-territory": "001",
              "-type": "Asia/Damascus"
            },
            {
              "-other": "Syria Standard Time",
              "-territory": "SY",
              "-type": "Asia/Damascus"
            },
            {
              "-other": "West Bank Standard Time",
              "-territory": "001",
              "-type": "Asia/Hebron"
            },
            {
              "-other": "West Bank Standard Time",
              "-territory": "PS",
              "-type": "Asia/Hebron Asia/Gaza"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "001",
              "-type": "Africa/Johannesburg"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "BI",
              "-type": "Africa/Bujumbura"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "BW",
              "-type": "Africa/Gaborone"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "CD",
              "-type": "Africa/Lubumbashi"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "LS",
              "-type": "Africa/Maseru"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "MW",
              "-type": "Africa/Blantyre"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "MZ",
              "-type": "Africa/Maputo"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "RW",
              "-type": "Africa/Kigali"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "SZ",
              "-type": "Africa/Mbabane"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "ZA",
              "-type": "Africa/Johannesburg"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "ZM",
              "-type": "Africa/Lusaka"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "ZW",
              "-type": "Africa/Harare"
            },
            {
              "-other": "South Africa Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-2"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "001",
              "-type": "Europe/Kiev"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "AX",
              "-type": "Europe/Mariehamn"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "BG",
              "-type": "Europe/Sofia"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "EE",
              "-type": "Europe/Tallinn"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "FI",
              "-type": "Europe/Helsinki"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "LT",
              "-type": "Europe/Vilnius"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "LV",
              "-type": "Europe/Riga"
            },
            {
              "-other": "FLE Standard Time",
              "-territory": "UA",
              "-type": "Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye"
            },
            {
              "-other": "Israel Standard Time",
              "-territory": "001",
              "-type": "Asia/Jerusalem"
            },
            {
              "-other": "Israel Standard Time",
              "-territory": "IL",
              "-type": "Asia/Jerusalem"
            },
            {
              "-other": "Kaliningrad Standard Time",
              "-territory": "001",
              "-type": "Europe/Kaliningrad"
            },
            {
              "-other": "Kaliningrad Standard Time",
              "-territory": "RU",
              "-type": "Europe/Kaliningrad"
            },
            {
              "-other": "Sudan Standard Time",
              "-territory": "001",
              "-type": "Africa/Khartoum"
            },
            {
              "-other": "Sudan Standard Time",
              "-territory": "SD",
              "-type": "Africa/Khartoum"
            },
            {
              "-other": "Libya Standard Time",
              "-territory": "001",
              "-type": "Africa/Tripoli"
            },
            {
              "-other": "Libya Standard Time",
              "-territory": "LY",
              "-type": "Africa/Tripoli"
            },
            {
              "-other": "Namibia Standard Time",
              "-territory": "001",
              "-type": "Africa/Windhoek"
            },
            {
              "-other": "Namibia Standard Time",
              "-territory": "NA",
              "-type": "Africa/Windhoek"
            },
            {
              "-other": "Arabic Standard Time",
              "-territory": "001",
              "-type": "Asia/Baghdad"
            },
            {
              "-other": "Arabic Standard Time",
              "-territory": "IQ",
              "-type": "Asia/Baghdad"
            },
            {
              "-other": "Turkey Standard Time",
              "-territory": "001",
              "-type": "Europe/Istanbul"
            },
            {
              "-other": "Turkey Standard Time",
              "-territory": "TR",
              "-type": "Europe/Istanbul"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "001",
              "-type": "Asia/Riyadh"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "BH",
              "-type": "Asia/Bahrain"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "KW",
              "-type": "Asia/Kuwait"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "QA",
              "-type": "Asia/Qatar"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "SA",
              "-type": "Asia/Riyadh"
            },
            {
              "-other": "Arab Standard Time",
              "-territory": "YE",
              "-type": "Asia/Aden"
            },
            {
              "-other": "Belarus Standard Time",
              "-territory": "001",
              "-type": "Europe/Minsk"
            },
            {
              "-other": "Belarus Standard Time",
              "-territory": "BY",
              "-type": "Europe/Minsk"
            },
            {
              "-other": "Russian Standard Time",
              "-territory": "001",
              "-type": "Europe/Moscow"
            },
            {
              "-other": "Russian Standard Time",
              "-territory": "RU",
              "-type": "Europe/Moscow Europe/Kirov"
            },
            {
              "-other": "Russian Standard Time",
              "-territory": "UA",
              "-type": "Europe/Simferopol"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "001",
              "-type": "Africa/Nairobi"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Syowa"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "DJ",
              "-type": "Africa/Djibouti"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "ER",
              "-type": "Africa/Asmera"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "ET",
              "-type": "Africa/Addis_Ababa"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "KE",
              "-type": "Africa/Nairobi"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "KM",
              "-type": "Indian/Comoro"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "MG",
              "-type": "Indian/Antananarivo"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "SO",
              "-type": "Africa/Mogadishu"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "SS",
              "-type": "Africa/Juba"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "TZ",
              "-type": "Africa/Dar_es_Salaam"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "UG",
              "-type": "Africa/Kampala"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "YT",
              "-type": "Indian/Mayotte"
            },
            {
              "-other": "E. Africa Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-3"
            },
            {
              "-other": "Iran Standard Time",
              "-territory": "001",
              "-type": "Asia/Tehran"
            },
            {
              "-other": "Iran Standard Time",
              "-territory": "IR",
              "-type": "Asia/Tehran"
            },
            {
              "-other": "Arabian Standard Time",
              "-territory": "001",
              "-type": "Asia/Dubai"
            },
            {
              "-other": "Arabian Standard Time",
              "-territory": "AE",
              "-type": "Asia/Dubai"
            },
            {
              "-other": "Arabian Standard Time",
              "-territory": "OM",
              "-type": "Asia/Muscat"
            },
            {
              "-other": "Arabian Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-4"
            },
            {
              "-other": "Astrakhan Standard Time",
              "-territory": "001",
              "-type": "Europe/Astrakhan"
            },
            {
              "-other": "Astrakhan Standard Time",
              "-territory": "RU",
              "-type": "Europe/Astrakhan Europe/Ulyanovsk"
            },
            {
              "-other": "Azerbaijan Standard Time",
              "-territory": "001",
              "-type": "Asia/Baku"
            },
            {
              "-other": "Azerbaijan Standard Time",
              "-territory": "AZ",
              "-type": "Asia/Baku"
            },
            {
              "-other": "Russia Time Zone 3",
              "-territory": "001",
              "-type": "Europe/Samara"
            },
            {
              "-other": "Russia Time Zone 3",
              "-territory": "RU",
              "-type": "Europe/Samara"
            },
            {
              "-other": "Mauritius Standard Time",
              "-territory": "001",
              "-type": "Indian/Mauritius"
            },
            {
              "-other": "Mauritius Standard Time",
              "-territory": "MU",
              "-type": "Indian/Mauritius"
            },
            {
              "-other": "Mauritius Standard Time",
              "-territory": "RE",
              "-type": "Indian/Reunion"
            },
            {
              "-other": "Mauritius Standard Time",
              "-territory": "SC",
              "-type": "Indian/Mahe"
            },
            {
              "-other": "Saratov Standard Time",
              "-territory": "001",
              "-type": "Europe/Saratov"
            },
            {
              "-other": "Saratov Standard Time",
              "-territory": "RU",
              "-type": "Europe/Saratov"
            },
            {
              "-other": "Georgian Standard Time",
              "-territory": "001",
              "-type": "Asia/Tbilisi"
            },
            {
              "-other": "Georgian Standard Time",
              "-territory": "GE",
              "-type": "Asia/Tbilisi"
            },
            {
              "-other": "Volgograd Standard Time",
              "-territory": "001",
              "-type": "Europe/Volgograd"
            },
            {
              "-other": "Volgograd Standard Time",
              "-territory": "RU",
              "-type": "Europe/Volgograd"
            },
            {
              "-other": "Caucasus Standard Time",
              "-territory": "001",
              "-type": "Asia/Yerevan"
            },
            {
              "-other": "Caucasus Standard Time",
              "-territory": "AM",
              "-type": "Asia/Yerevan"
            },
            {
              "-other": "Afghanistan Standard Time",
              "-territory": "001",
              "-type": "Asia/Kabul"
            },
            {
              "-other": "Afghanistan Standard Time",
              "-territory": "AF",
              "-type": "Asia/Kabul"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "001",
              "-type": "Asia/Tashkent"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Mawson"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "KZ",
              "-type": "Asia/Oral Asia/Aqtau Asia/Aqtobe Asia/Atyrau"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "MV",
              "-type": "Indian/Maldives"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "TF",
              "-type": "Indian/Kerguelen"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "TJ",
              "-type": "Asia/Dushanbe"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "TM",
              "-type": "Asia/Ashgabat"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "UZ",
              "-type": "Asia/Tashkent Asia/Samarkand"
            },
            {
              "-other": "West Asia Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-5"
            },
            {
              "-other": "Ekaterinburg Standard Time",
              "-territory": "001",
              "-type": "Asia/Yekaterinburg"
            },
            {
              "-other": "Ekaterinburg Standard Time",
              "-territory": "RU",
              "-type": "Asia/Yekaterinburg"
            },
            {
              "-other": "Pakistan Standard Time",
              "-territory": "001",
              "-type": "Asia/Karachi"
            },
            {
              "-other": "Pakistan Standard Time",
              "-territory": "PK",
              "-type": "Asia/Karachi"
            },
            {
              "-other": "Qyzylorda Standard Time",
              "-territory": "001",
              "-type": "Asia/Qyzylorda"
            },
            {
              "-other": "Qyzylorda Standard Time",
              "-territory": "KZ",
              "-type": "Asia/Qyzylorda"
            },
            {
              "-other": "India Standard Time",
              "-territory": "001",
              "-type": "Asia/Calcutta"
            },
            {
              "-other": "India Standard Time",
              "-territory": "IN",
              "-type": "Asia/Calcutta"
            },
            {
              "-other": "Sri Lanka Standard Time",
              "-territory": "001",
              "-type": "Asia/Colombo"
            },
            {
              "-other": "Sri Lanka Standard Time",
              "-territory": "LK",
              "-type": "Asia/Colombo"
            },
            {
              "-other": "Nepal Standard Time",
              "-territory": "001",
              "-type": "Asia/Katmandu"
            },
            {
              "-other": "Nepal Standard Time",
              "-territory": "NP",
              "-type": "Asia/Katmandu"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "001",
              "-type": "Asia/Almaty"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Vostok"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "CN",
              "-type": "Asia/Urumqi"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "IO",
              "-type": "Indian/Chagos"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "KG",
              "-type": "Asia/Bishkek"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "KZ",
              "-type": "Asia/Almaty Asia/Qostanay"
            },
            {
              "-other": "Central Asia Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-6"
            },
            {
              "-other": "Bangladesh Standard Time",
              "-territory": "001",
              "-type": "Asia/Dhaka"
            },
            {
              "-other": "Bangladesh Standard Time",
              "-territory": "BD",
              "-type": "Asia/Dhaka"
            },
            {
              "-other": "Bangladesh Standard Time",
              "-territory": "BT",
              "-type": "Asia/Thimphu"
            },
            {
              "-other": "Omsk Standard Time",
              "-territory": "001",
              "-type": "Asia/Omsk"
            },
            {
              "-other": "Omsk Standard Time",
              "-territory": "RU",
              "-type": "Asia/Omsk"
            },
            {
              "-other": "Myanmar Standard Time",
              "-territory": "001",
              "-type": "Asia/Rangoon"
            },
            {
              "-other": "Myanmar Standard Time",
              "-territory": "CC",
              "-type": "Indian/Cocos"
            },
            {
              "-other": "Myanmar Standard Time",
              "-territory": "MM",
              "-type": "Asia/Rangoon"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "001",
              "-type": "Asia/Bangkok"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Davis"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "CX",
              "-type": "Indian/Christmas"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "ID",
              "-type": "Asia/Jakarta Asia/Pontianak"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "KH",
              "-type": "Asia/Phnom_Penh"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "LA",
              "-type": "Asia/Vientiane"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "TH",
              "-type": "Asia/Bangkok"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "VN",
              "-type": "Asia/Saigon"
            },
            {
              "-other": "SE Asia Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-7"
            },
            {
              "-other": "Altai Standard Time",
              "-territory": "001",
              "-type": "Asia/Barnaul"
            },
            {
              "-other": "Altai Standard Time",
              "-territory": "RU",
              "-type": "Asia/Barnaul"
            },
            {
              "-other": "W. Mongolia Standard Time",
              "-territory": "001",
              "-type": "Asia/Hovd"
            },
            {
              "-other": "W. Mongolia Standard Time",
              "-territory": "MN",
              "-type": "Asia/Hovd"
            },
            {
              "-other": "North Asia Standard Time",
              "-territory": "001",
              "-type": "Asia/Krasnoyarsk"
            },
            {
              "-other": "North Asia Standard Time",
              "-territory": "RU",
              "-type": "Asia/Krasnoyarsk Asia/Novokuznetsk"
            },
            {
              "-other": "N. Central Asia Standard Time",
              "-territory": "001",
              "-type": "Asia/Novosibirsk"
            },
            {
              "-other": "N. Central Asia Standard Time",
              "-territory": "RU",
              "-type": "Asia/Novosibirsk"
            },
            {
              "-other": "Tomsk Standard Time",
              "-territory": "001",
              "-type": "Asia/Tomsk"
            },
            {
              "-other": "Tomsk Standard Time",
              "-territory": "RU",
              "-type": "Asia/Tomsk"
            },
            {
              "-other": "China Standard Time",
              "-territory": "001",
              "-type": "Asia/Shanghai"
            },
            {
              "-other": "China Standard Time",
              "-territory": "CN",
              "-type": "Asia/Shanghai"
            },
            {
              "-other": "China Standard Time",
              "-territory": "HK",
              "-type": "Asia/Hong_Kong"
            },
            {
              "-other": "China Standard Time",
              "-territory": "MO",
              "-type": "Asia/Macau"
            },
            {
              "-other": "North Asia East Standard Time",
              "-territory": "001",
              "-type": "Asia/Irkutsk"
            },
            {
              "-other": "North Asia East Standard Time",
              "-territory": "RU",
              "-type": "Asia/Irkutsk"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "001",
              "-type": "Asia/Singapore"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/Casey"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "BN",
              "-type": "Asia/Brunei"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "ID",
              "-type": "Asia/Makassar"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "MY",
              "-type": "Asia/Kuala_Lumpur Asia/Kuching"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "PH",
              "-type": "Asia/Manila"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "SG",
              "-type": "Asia/Singapore"
            },
            {
              "-other": "Singapore Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-8"
            },
            {
              "-other": "W. Australia Standard Time",
              "-territory": "001",
              "-type": "Australia/Perth"
            },
            {
              "-other": "W. Australia Standard Time",
              "-territory": "AU",
              "-type": "Australia/Perth"
            },
            {
              "-other": "Taipei Standard Time",
              "-territory": "001",
              "-type": "Asia/Taipei"
            },
            {
              "-other": "Taipei Standard Time",
              "-territory": "TW",
              "-type": "Asia/Taipei"
            },
            {
              "-other": "Ulaanbaatar Standard Time",
              "-territory": "001",
              "-type": "Asia/Ulaanbaatar"
            },
            {
              "-other": "Ulaanbaatar Standard Time",
              "-territory": "MN",
              "-type": "Asia/Ulaanbaatar Asia/Choibalsan"
            },
            {
              "-other": "Aus Central W. Standard Time",
              "-territory": "001",
              "-type": "Australia/Eucla"
            },
            {
              "-other": "Aus Central W. Standard Time",
              "-territory": "AU",
              "-type": "Australia/Eucla"
            },
            {
              "-other": "Transbaikal Standard Time",
              "-territory": "001",
              "-type": "Asia/Chita"
            },
            {
              "-other": "Transbaikal Standard Time",
              "-territory": "RU",
              "-type": "Asia/Chita"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "001",
              "-type": "Asia/Tokyo"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "ID",
              "-type": "Asia/Jayapura"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "JP",
              "-type": "Asia/Tokyo"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "PW",
              "-type": "Pacific/Palau"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "TL",
              "-type": "Asia/Dili"
            },
            {
              "-other": "Tokyo Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-9"
            },
            {
              "-other": "North Korea Standard Time",
              "-territory": "001",
              "-type": "Asia/Pyongyang"
            },
            {
              "-other": "North Korea Standard Time",
              "-territory": "KP",
              "-type": "Asia/Pyongyang"
            },
            {
              "-other": "Korea Standard Time",
              "-territory": "001",
              "-type": "Asia/Seoul"
            },
            {
              "-other": "Korea Standard Time",
              "-territory": "KR",
              "-type": "Asia/Seoul"
            },
            {
              "-other": "Yakutsk Standard Time",
              "-territory": "001",
              "-type": "Asia/Yakutsk"
            },
            {
              "-other": "Yakutsk Standard Time",
              "-territory": "RU",
              "-type": "Asia/Yakutsk Asia/Khandyga"
            },
            {
              "-other": "Cen. Australia Standard Time",
              "-territory": "001",
              "-type": "Australia/Adelaide"
            },
            {
              "-other": "Cen. Australia Standard Time",
              "-territory": "AU",
              "-type": "Australia/Adelaide Australia/Broken_Hill"
            },
            {
              "-other": "AUS Central Standard Time",
              "-territory": "001",
              "-type": "Australia/Darwin"
            },
            {
              "-other": "AUS Central Standard Time",
              "-territory": "AU",
              "-type": "Australia/Darwin"
            },
            {
              "-other": "E. Australia Standard Time",
              "-territory": "001",
              "-type": "Australia/Brisbane"
            },
            {
              "-other": "E. Australia Standard Time",
              "-territory": "AU",
              "-type": "Australia/Brisbane Australia/Lindeman"
            },
            {
              "-other": "AUS Eastern Standard Time",
              "-territory": "001",
              "-type": "Australia/Sydney"
            },
            {
              "-other": "AUS Eastern Standard Time",
              "-territory": "AU",
              "-type": "Australia/Sydney Australia/Melbourne"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "001",
              "-type": "Pacific/Port_Moresby"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/DumontDUrville"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "FM",
              "-type": "Pacific/Truk"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "GU",
              "-type": "Pacific/Guam"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "MP",
              "-type": "Pacific/Saipan"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "PG",
              "-type": "Pacific/Port_Moresby"
            },
            {
              "-other": "West Pacific Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-10"
            },
            {
              "-other": "Tasmania Standard Time",
              "-territory": "001",
              "-type": "Australia/Hobart"
            },
            {
              "-other": "Tasmania Standard Time",
              "-territory": "AU",
              "-type": "Australia/Hobart Australia/Currie"
            },
            {
              "-other": "Vladivostok Standard Time",
              "-territory": "001",
              "-type": "Asia/Vladivostok"
            },
            {
              "-other": "Vladivostok Standard Time",
              "-territory": "RU",
              "-type": "Asia/Vladivostok Asia/Ust-Nera"
            },
            {
              "-other": "Lord Howe Standard Time",
              "-territory": "001",
              "-type": "Australia/Lord_Howe"
            },
            {
              "-other": "Lord Howe Standard Time",
              "-territory": "AU",
              "-type": "Australia/Lord_Howe"
            },
            {
              "-other": "Bougainville Standard Time",
              "-territory": "001",
              "-type": "Pacific/Bougainville"
            },
            {
              "-other": "Bougainville Standard Time",
              "-territory": "PG",
              "-type": "Pacific/Bougainville"
            },
            {
              "-other": "Russia Time Zone 10",
              "-territory": "001",
              "-type": "Asia/Srednekolymsk"
            },
            {
              "-other": "Russia Time Zone 10",
              "-territory": "RU",
              "-type": "Asia/Srednekolymsk"
            },
            {
              "-other": "Magadan Standard Time",
              "-territory": "001",
              "-type": "Asia/Magadan"
            },
            {
              "-other": "Magadan Standard Time",
              "-territory": "RU",
              "-type": "Asia/Magadan"
            },
            {
              "-other": "Norfolk Standard Time",
              "-territory": "001",
              "-type": "Pacific/Norfolk"
            },
            {
              "-other": "Norfolk Standard Time",
              "-territory": "NF",
              "-type": "Pacific/Norfolk"
            },
            {
              "-other": "Sakhalin Standard Time",
              "-territory": "001",
              "-type": "Asia/Sakhalin"
            },
            {
              "-other": "Sakhalin Standard Time",
              "-territory": "RU",
              "-type": "Asia/Sakhalin"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "001",
              "-type": "Pacific/Guadalcanal"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "AU",
              "-type": "Antarctica/Macquarie"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "FM",
              "-type": "Pacific/Ponape Pacific/Kosrae"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "NC",
              "-type": "Pacific/Noumea"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "SB",
              "-type": "Pacific/Guadalcanal"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "VU",
              "-type": "Pacific/Efate"
            },
            {
              "-other": "Central Pacific Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-11"
            },
            {
              "-other": "Russia Time Zone 11",
              "-territory": "001",
              "-type": "Asia/Kamchatka"
            },
            {
              "-other": "Russia Time Zone 11",
              "-territory": "RU",
              "-type": "Asia/Kamchatka Asia/Anadyr"
            },
            {
              "-other": "New Zealand Standard Time",
              "-territory": "001",
              "-type": "Pacific/Auckland"
            },
            {
              "-other": "New Zealand Standard Time",
              "-territory": "AQ",
              "-type": "Antarctica/McMurdo"
            },
            {
              "-other": "New Zealand Standard Time",
              "-territory": "NZ",
              "-type": "Pacific/Auckland"
            },
            {
              "-other": "UTC+12",
              "-territory": "001",
              "-type": "Etc/GMT-12"
            },
            {
              "-other": "UTC+12",
              "-territory": "KI",
              "-type": "Pacific/Tarawa"
            },
            {
              "-other": "UTC+12",
              "-territory": "MH",
              "-type": "Pacific/Majuro Pacific/Kwajalein"
            },
            {
              "-other": "UTC+12",
              "-territory": "NR",
              "-type": "Pacific/Nauru"
            },
            {
              "-other": "UTC+12",
              "-territory": "TV",
              "-type": "Pacific/Funafuti"
            },
            {
              "-other": "UTC+12",
              "-territory": "UM",
              "-type": "Pacific/Wake"
            },
            {
              "-other": "UTC+12",
              "-territory": "WF",
              "-type": "Pacific/Wallis"
            },
            {
              "-other": "UTC+12",
              "-territory": "ZZ",
              "-type": "Etc/GMT-12"
            },
            {
              "-other": "Fiji Standard Time",
              "-territory": "001",
              "-type": "Pacific/Fiji"
            },
            {
              "-other": "Fiji Standard Time",
              "-territory": "FJ",
              "-type": "Pacific/Fiji"
            },
            {
              "-other": "Chatham Islands Standard Time",
              "-territory": "001",
              "-type": "Pacific/Chatham"
            },
            {
              "-other": "Chatham Islands Standard Time",
              "-territory": "NZ",
              "-type": "Pacific/Chatham"
            },
            {
              "-other": "UTC+13",
              "-territory": "001",
              "-type": "Etc/GMT-13"
            },
            {
              "-other": "UTC+13",
              "-territory": "KI",
              "-type": "Pacific/Enderbury"
            },
            {
              "-other": "UTC+13",
              "-territory": "TK",
              "-type": "Pacific/Fakaofo"
            },
            {
              "-other": "UTC+13",
              "-territory": "ZZ",
              "-type": "Etc/GMT-13"
            },
            {
              "-other": "Tonga Standard Time",
              "-territory": "001",
              "-type": "Pacific/Tongatapu"
            },
            {
              "-other": "Tonga Standard Time",
              "-territory": "TO",
              "-type": "Pacific/Tongatapu"
            },
            {
              "-other": "Samoa Standard Time",
              "-territory": "001",
              "-type": "Pacific/Apia"
            },
            {
              "-other": "Samoa Standard Time",
              "-territory": "WS",
              "-type": "Pacific/Apia"
            },
            {
              "-other": "Line Islands Standard Time",
              "-territory": "001",
              "-type": "Pacific/Kiritimati"
            },
            {
              "-other": "Line Islands Standard Time",
              "-territory": "KI",
              "-type": "Pacific/Kiritimati"
            },
            {
              "-other": "Line Islands Standard Time",
              "-territory": "ZZ",
              "-type": "Etc/GMT-14"
            }
          ];
  ftz:any[] = [];

  constructor() { 
    //console.log(moment.tz.names());
   
    this.ftz = this.zt.filter(z=>z['-territory']=="001");
    // var ctz = [];
    // this.ftz.forEach(z => { ctz.push({ windowsTimeZone : z["-other"], javascriptTimeZone : z["-type"]}); });
    //console.log(JSON.stringify(ctz));

  }

  ngOnInit() {
    //console.log(this.options);
    if(this.options != null)
    {
      if(this.options.hasOwnProperty("windowsTimeZone"))
      {
        //console.log(this.options.windowsTimeZone.split(" (")[0]);

        var jsTz = this.ftz.find(z=>z["-other"] == this.options.windowsTimeZone.split(" (")[0]);
        //console.log(jsTz);
        if(jsTz != null)
        {
          this.options = { timeZone : jsTz["-type"]};
        }
      }
    } 

    //console.log(this.options);

    if(this.utcDateTime!=null)
    {
      this.utcDateTime = this.utcDateTime.toUpperCase();
      //console.log(this.utcDateTime);
      if(this.utcDateTime[this.utcDateTime.length - 1] != "Z")
        this.utcDateTime += "Z";

      this.realDateTime = new Date(Date.parse(this.utcDateTime));
      //console.log(this.realDateTime);

    }
    if(this.utcDateTime == null)
    {
      this.realDateTime = new Date(Date.now());
    }

    this.outputString = this.GetDateTimeString();
  }


  GetDateTimeString()
  {
    if(this.locales==null)
      return this.realDateTime.toLocaleString();

    if(this.options==null)
      return this.realDateTime.toLocaleString(this.locales);

    return this.realDateTime.toLocaleString(this.locales, this.options);
  }

}
