import { Injectable, Inject, Injector } from '@angular/core';
import { Constants } from './../app.config';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LanguageService } from './../_services/language.service';
import { LocalstoreService } from './../_shared/localstore.service';
import { ApiService } from '../_services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  static http: ApiService;
  static localStore: LocalstoreService;
  public reverse: string;
    /**
     * Creates an instance of ValidationService.
     *
     * @param {Constants} appConstants
     *
     * @memberOf ValidationService
     */

  constructor(
    private appConstants: Constants,
		private translate: TranslateService,
		private language: LanguageService,
		private api: ApiService,
		private localStorage: LocalstoreService
  ) {
    ValidationService.http = api;
		ValidationService.localStore = localStorage;
  }

  public getValidatorErrorMessage(validatorName: string, messageKeys?: Object, validatorValue?: any) {
    if (!_.isUndefined(messageKeys)) {
      if (messageKeys[validatorName]) {
        return this.translate.instant(messageKeys[validatorName]);
      }
      return null;
    }
    else {
      if (!this.appConstants.ValidationMessageKeys[validatorName] || !validatorValue.requiredLength) {
        return null;
      }
      return this.translate.instant(this.appConstants.ValidationMessageKeys[validatorName], { value: validatorValue.requiredLength });
    }
  }

  static emailValidator(control) {
    // checks if value is null
    if (!control.value || control.value == "") {
      return null;
    }
    // RFC 2822 compliant regex
    else if (control.value.match(/[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/)) {
      return null;
    }
    else {
      return { 'invalidEmail': true };
    }
  }

  static matchPasswordValidator(group) {
    let password = group.controls.newPassword;
    let confirmPassword = group.controls.confirmPassword;

    // Don't kick in until user touches both fields
    if (password.pristine || confirmPassword.pristine) {
      return null;
    }

    if (password.value === confirmPassword.value) {
      return null;
    }
    return {
      'passwordMismatch': true
    };
  }

  static equalValidator(mainField: string, confirmField: string) {
    return (group) => {
      let mainInput = group.controls[mainField];
      let confirmationInput = group.controls[confirmField];

      // Maintain the existing errors
      let existingErrors = confirmationInput.errors;

      // Don't kick in until user touches both fields
      if (mainInput.pristine || confirmationInput.pristine) {
        return confirmationInput.setErrors(existingErrors);
      }

      if (mainInput.value === confirmationInput.value) {
        return confirmationInput.setErrors(null);
      }

      return confirmationInput.setErrors(_.extend(existingErrors, { notEquivalent: true }));
    }
  }

  static conditional(conditional, validator) {
    return (control) => {
      this.revalidateOnChanges(control);

      if (control && control._parent) {
        if (conditional(control._parent)) {
          return validator(control);
        }
      }
    };
  }

  static revalidateOnChanges(control): void {
    if (control && control._parent && !control._revalidateOnChanges) {
      control._revalidateOnChanges = true;
      control._parent
        .valueChanges
        .distinctUntilChanged((a, b) => {
          // These will always be plain objects coming from the form, do a simple comparison
          if (a && !b || !a && b) {
            return false;
          } else if (a && b && Object.keys(a).length !== Object.keys(b).length) {
            return false;
          } else if (a && b) {
            for (let i in a) {
              if (a[i] !== b[i]) {
                return false;
              }
            }
          }
          return true;
        })
        .subscribe(() => {
          control.updateValueAndValidity();
        });

      control.updateValueAndValidity();
    }
    return;
  }

  static passwordValidator(control) {
    if (control.pristine) {
      return null
    }

    let policy = ValidationService.localStore.get('passwordpolicies');
    if (policy.length) {
      let minLengthPolicy = _.find(policy, { key: 'MIN_NO_OF_CHARACTERS' });
      let regexPolicy = _.find(policy, { key: 'PASSWORD_POLICY_EXPRESSION' });

      if (minLengthPolicy && control.value.length < minLengthPolicy['value']) {
        return { 'MIN_NO_OF_CHARACTERS': true };
      }
      else if (regexPolicy && !control.value.match(regexPolicy['value'])) {
        return { 'PASSWORD_POLICY_EXPRESSION': true };
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  private get isReverse() {
    if (!this.reverse) return false;
    return this.reverse === 'true' ? true : false;
  }


}
