import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  parameters: any;

  constructor(private api: ApiService) {}

   
  // POST /api/position/{positionId}/geocode 
  geocodePosition(positionId:number, handleError = true): Observable<any> 
  {
    return this.api.post(
      'position/' + positionId + '/geocode' ,
      null, {}, handleError
    );
  }
  
}
