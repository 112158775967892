<div id="objPicker">

    <div class="obj-popup">
        
        <!-- HEADER -->
        
        <div class="f-row" *ngIf="itemtype && targettype">
            <h3>
                <span>{{ 'Page.OBJECTTYPES.' + itemtype | translate }} </span>
                <span translate>Page.Labels.ON </span>
                <span>{{ 'Page.OBJECTTYPES.' + targettype | translate }} </span>
            </h3>

        </div>
        
        <!-- FILTERS -->
        <div class="f-row" style="justify-content: space-between;">
            <input type="text" placeholder="Search...">
            <div style="width: 200px; height: 32px; text-align: right; padding: 2px 8px;">dropdown</div>
        </div>

        <!-- ITEM LIST -->
        <div class="item-list">

            <div class="item">
                <div class="left-col">
                    <div class="icons">
                        <div class="icon"><img class="svgee" src="/assets/icons/svg/trakker.svg"></div>
                    </div>
                    <div class="name">Ursula</div>
                </div>
                <div class="right-col">
                    <div class="sel-icon"><img class="svgee" src="/assets/icons/svg/checkbox-false.svg"></div>
                </div>
            </div>

            <div class="item selected">
                <div class="left-col">
                    <div class="icons">
                        <div class="icon"><img class="svgee" src="/assets/icons/svg/trakker.svg"></div>
                    </div>
                    <div class="name">Heinz</div>
                </div>
                <div class="right-col">
                    <div class="sel-icon"><img class="svgee" src="/assets/icons/svg/checkbox-true.svg"></div>
                </div>
            </div>

            <div class="item">
                <div class="left-col">
                    <div class="icons">
                        <div class="icon"><img class="svgee" src="/assets/icons/svg/trakker.svg"></div>
                    </div>
                    <div class="name">Jörgen</div>
                </div>
                <div class="right-col">
                    <div class="sel-icon"><img class="svgee" src="/assets/icons/svg/checkbox-false.svg"></div>
                </div>
            </div>

        </div>

        <!-- ADD NEW IF CONTACT -->
        <div class="f-row" *ngIf="itemtype === 'Contact'">
            <div class="btn-base btn-options" style="justify-content: flex-start;" translate>Page.Buttons.CREATE_NEW</div>
        </div>

        <!-- CLOSE WINDOW BUTTONS -->
        <div class="f-row" style="margin: 16px 0;">
            <!-- <div class="btn-base btn-options" translate (click)="closeObjectPicker()">Page.Buttons.CANCEL</div> -->
            <div class="btn-base btn-options" translate (click)="closeObjectPicker()">Page.Buttons.DONE</div>
        </div>

    </div>
</div>

