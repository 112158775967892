import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Vehicle } from '../_models/vehicle.model';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  parameters: any;
  vehiclesChanged = new Subject<Vehicle[]>();

  constructor(private api: ApiService) { }

  private vehicles: Vehicle[] = [];

  getVehicles() {
    return this.vehicles.slice();
  }

  getVehicle(index: number) {
    return this.vehicles[index];
  }

  addVehicle(vehicle: Vehicle) {
    this.vehicles.push(vehicle);
    this.vehiclesChanged.next(this.vehicles.slice());
  }

  updateVehicle(index: number, newVehicle: Vehicle) {
    this.vehicles[index] = newVehicle;
    this.vehiclesChanged.next(this.vehicles.slice());
  }

  deleteVehicle(index: number) {
    this.vehicles.splice(index, 1);
    this.vehiclesChanged.next(this.vehicles.slice());
  }

  getAllVehicleOfCompany(companyId, url?): Observable<any> {
    return this.api.get(
      'company/' + companyId + '/vehicle?' + url
    );
  }

  deleteVehicleFromList(id, companyId): Observable<any> {
    return this.api.delete(
      'company/' + companyId + '/vehicle/' + id
    );
  }

  getAllAvailableVehicle(url?): Observable<any> {
    return this.api.get(
      'vehicle/public?' + url
    );
  }

  getAllVehicleViewData(): Observable<any> {
    return this.api.get('vehicle/viewdata');
  }

  assignDriverToVehicle(data, companyId, vehicleId): Observable<any> {
    return this.api
      .post('company/' + companyId + '/vehicle/' + vehicleId + '/driver', data);
  }

  unAssignDriverToVehicle(data, companyId, driverAccountId): Observable<any> {
    return this.api
      .put('company/' + companyId + '/driver/' + driverAccountId + '/vehicle', data);
  }

  assignVehicleToOrder(orderId, companyId, data): Observable<any> {
    return this.api
      .post('company/' + companyId + '/order/transporter/' + orderId + '/vehicle', data);

  }

  sendMailToCompany(vehicleId, data): Observable<any> {
    return this.api
      .post('vehicle/public/' + vehicleId + '/email', data);

  }



}
