import { Component, OnInit } from '@angular/core';
import { PreloaderService } from './preloader.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  public active: boolean;

  public constructor(private preloader: PreloaderService) {
		preloader.status.subscribe((status: boolean) => {
			this.active = status;
		});
	}

  ngOnInit() {
  }

}
