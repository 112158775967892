
        <div >
            <!-- <p class="trl-c-empty-state__icon" p><i class="fa fa-ban" aria-hidden="true"></i></p> -->
            <img id="image" src="/assets/logos/Black-and-Teal-on-White.png" />

            <h3 class="trl-c-empty-state__heading" [translate]></h3>
            <p class="warning-color" [translate]>MESSAGE.NO_PAGE</p>

        </div>

