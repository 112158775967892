<!-- <app-nav></app-nav> -->

<app-main-nav>
  <section id="main-section" class="main-section">

    <app-spinner></app-spinner>
    <error-popup></error-popup>
    <router-outlet></router-outlet>

  </section>
</app-main-nav>
