export enum Day {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export class TimeInput {
  Hour: number;
  Minute: number;

  constructor(hour: string, minute: string) {
    this.Hour = Number.parseInt(hour);
    this.Minute = minute ? Number.parseInt(minute) : 0;
  }

  toString() {
    return !(isNaN(this.Hour) || isNaN(this.Minute))
      ? ("00" + this.Hour).slice(-2) + ":" + ("00" + this.Minute).slice(-2)
      : "";
  }
}

export interface ScheduleEntry {
  Id?: number;
  StartTime: TimeInput;
  StopTime: TimeInput;
  Day: Day;
}

export interface Schedule {
  Id: number;
  Entries: ScheduleEntry[];
}
