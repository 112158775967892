import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MapzoneService {
  parameters: any;

  constructor(private api: ApiService) {
   }

  getAllMapzones(): Observable<any> 
  {
    return this.api.get(
      'mapzones/'
    );
  }

  createMapzoneData(data:any): Observable<any> {
    return this.api.post(
      'mapzones/',
      data
    );
  }
  
  editMapzoneData(mapzoneId:number, data:any): Observable<any> {
    return this.api.put(
      'mapzones/' + mapzoneId,
      data
    );
  }

  deleteMapzone(mapzoneId:number): Observable<any> {
    return this.api.delete(
      'mapzones/' + mapzoneId
    );
  }
}
