import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-qr-hardware-info',
  templateUrl: './qr-hardware-info.component.html',
  styleUrls: ['./qr-hardware-info.component.scss']
})
export class QrHardwareInfoComponent implements OnInit {

  private translateService : TranslateService;
  constructor(
    private translate: TranslateService,
  ) { 

  }

  ngOnInit(): void {
  }

}
