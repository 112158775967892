import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
	// This next option lets all pipes be reloaded on any page change
	// It requires a lot of performance
	// Use to have live added objects added in list even if you are in the filter and doesn't make a change
	// pure: false
})


// export class FilterPipe implements PipeTransform {
// 	transform(items: any, searchText: string, propName: string): any {
// 		if (items.length === 0 || searchText === '') {
// 			return items;
// 		}
// 		const resultArray = [];
// 		for (const item of items) {
// 			if (item[propName] === searchText) {
// 				resultArray.push(item);
// 			}
// 		}
// 		return resultArray;
// 	}
// }



// export class FilterPipe implements PipeTransform {
//   transform(items: any[], searchText: string, propName: string): any[] {
//     if(!items) {
//         return [];
//     }
//     if(!searchText) {
//       return items;
//     } else if (searchText) {
//       searchText = searchText.toLowerCase();
//       const resultArray = [];
//       for (const item of items) {
//         if (item[propName] === searchText) {
//           resultArray.push(item);
//         }
//       }
//       return items.filter( it => {
//         return it.toLowerCase().includes(searchText);
//       });
//     }
//    }
// }



export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    searchText = searchText ? searchText.toLocaleLowerCase() : null;
    return searchText ? items.filter((item: any) => {
			
			if (item.Hardware) {
				return item.Name.toLocaleLowerCase().indexOf(searchText) !== -1 ||
					// item.TrakkerId.toString().indexOf(searchText) !== -1 ||
					item.Hardware.GlobalDeviceId.toString().indexOf(searchText) !== -1
			}
			// else if (item.Hardware && !item.TrakkerId) {
			// 	return item.Name.toLocaleLowerCase().indexOf(searchText) !== -1 ||
			// 		item.Vehicles[0].VehicleId.toString().indexOf(searchText) !== -1
			// }
			
			return item.Name.toLocaleLowerCase().indexOf(searchText) !== -1
					// || item.TrakkerId.toString().indexOf(searchText) !== -1
			}) : items;
	}
}
