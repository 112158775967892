import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shorten'
})

// Usage: {{ printedname | shorten:15 }}
export class ShortenPipe implements PipeTransform {
	transform(value: any, limit: number) {
		if (value) {
			if (value.length > limit) {
				return value.substr(0, limit) + '..';
			}
		}
		return value;
	}
}
