import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss']
})
export class DesktopComponent implements OnInit {

  loadedFeature = 'dashboard';

  constructor() { }

  ngOnInit() {
  }

  onNavigate(feature: string) {
		this.loadedFeature = feature;
	}



}
