<div class="cont">
  <div class="flex-container">
    <div class="first-child-container">
      <div class="top-boxes">
        <div class="dash-stats fade-in">
          <div class="title-field">
            <p [translate] class="box-title">Page.Labels.ACCOUNT_SUMMARY</p>
          </div>


          <div class="stats">
            <div class="col-left">
              <h5>{{'Page.Labels.UNITS' | translate }} ({{ trakkercount }})</h5>
              <div class="user-stats">
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/vehicle.svg">
                  <span>{{ vehiclecount }} {{ 'Page.Labels.VEHICLES' | translate
                    }}</span>
                </div>
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/operation-time.svg">
                  <span>{{ operationtimecount }} {{
                    'Page.Labels.OPERATION_TIME_UNITS' | translate }}</span>
                </div>
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/trakker.svg">
                  <span> {{ trakkercount - vehiclecount -  operationtimecount}} {{ 'Page.Labels.TRAKKERS' | translate }}</span>
                </div>
              </div>

            </div>

            <div class="col-right">
              <h5 translate>Page.Labels.USER_STATS</h5>
              <div class="user-stats">
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/account.svg">
                  <span> {{ accountcount }} {{ 'Page.Labels.ACCOUNTS' | translate }}</span>
                </div>
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/geofence.svg">
                  <span> {{ geofencecount }} {{ 'Page.Labels.GEOFENCES' | translate }}</span>
                </div>
                <div class="user-stat">
                  <img class="ico svgee" src="assets/icons/svg/group.svg">
                  <span> {{ groupcount }} {{ 'Page.Labels.GROUPS' | translate }}</span>
                </div>
              </div>

            </div>


          </div>



        </div>
      </div>


      <div class="top-boxes">
        <div class="dash-co2">
          <div>
            <div class="top-field">
              <div class="title-field">
                <p class="box-title">ASSET</p><!--!-->
              </div><!--!-->
              <!-- <div class="report-link"><a href="reports" [translate]>Page.Labels.SEE_DETAILS</a></div> -->
            </div><!--!-->
            <div class="fork">
              <div><span class="sum" [countUp]="currentMonthAssets">0</span>&nbsp;<span [translate]>Page.Labels.UNITS</span></div>
            </div>
          </div>
        </div>
      </div>

      <div class="top-boxes">
        <div class="dash-co2">
          <div>
            <div class="top-field">
              <div class="title-field">
                <p class="box-title">Fleet</p><!--!-->
              </div><!--!-->
              <!-- <div class="report-link"><a href="reports/vehicle-summary-report" [translate]>Page.Labels.SEE_DETAILS</a>
              </div> -->
            </div><!--!-->
            <div class="fork">
              <div><span class="sum" [countUp]="currentMonthFleet">0</span>&nbsp;<span [translate]>Page.Labels.VEHICLES</span></div>
            </div>
          </div>
        </div>
      </div>

      <div class="monthly-bars">
        <div [ngClass]="{'invisible': !hasYearlies }" class="dash-yearly">
          <div class="title-field">
            <p class="box-title" [translate]>Page.Labels.YEARLY_STATS_LABEL</p>
            <p class="subline">(<span [translate]>Page.Labels.LAST_12_MONTHS</span>)</p>
          </div>
          <div style="position: relative;height: 40vh;">
          <canvas id="stackedbar"></canvas>
          </div>
        </div>
      </div>


      <div class="box-model-4">
        <div class="dash-co2 fade-in" style="position: initial;">
          <div class="top-field">
            <div class="title-field">
              <p [translate] class="box-title">Page.Labels.ACTIVELORA_UNITS<span> ({{ activeLoraTotal }})</span></p>
            </div>
            <!-- <div class="report-link">
              <a href="/reports" translate>Page.Labels.SEE_DETAILS</a>
            </div> -->
          </div>
          <canvas id="doughnut"></canvas>
        </div>
      </div>

      <div class="box-model-4"> <!-- ALARMS -->
        <div class="dash-co2 fade-in ">
          <div class="top-field">
            <div class="title-field">
              <p [translate] class="box-title">Page.Labels.ACTIVE_ALERTS<span> ({{ alertcount }})</span></p>
              <p class="subline">(<span [translate]>Page.Labels.LAST_60_DAYS</span>)</p>

            </div>
            <div class="report-link">
              <a href="/reports" translate>Page.Labels.SEE_DETAILS</a>
            </div>
          </div>
          <p class="alertdetail">SOS: <span class="counter">{{ soscount }}</span> / {{ 'Page.Labels.BATTERY' | translate
            }} : <span class="counter">{{ batcount }}</span> / {{ 'Page.Labels.EXITGEOFENCE' | translate }} : <span
              class="counter">{{ exitgeocount }}</span></p>
          <div class="alarmbox" id="alarmboxelement">
            <ng-container *ngIf="alerts != null">
              <!-- <p class="alertdetail">{{ 'Page.Labels.GEOFENCE' | translate }}: <span class="counter">{{ geocount }}</span> ({{ 'Page.Labels.ENTER' | translate }} : <span class="counter">{{ entergeocount }}</span>, {{ 'Page.Labels.EXIT' | translate }} : <span class="counter">{{ exitgeocount }}</span>)</p> -->
              <!-- <p>{{ sos[1].TrakkerEventId }}</p> -->
              <div *ngFor="let alert of alerts; let i = index">
                <ng-container *ngIf="i < 20 * currentPage">
                  <div class="alarm" [ngClass]="{
                          'ev-sos': alert.TrakkerEvent.AlertType == 'SOS',
                          'ev-geo': alert.TrakkerEvent.AlertType == ('ExitGeofence' || 'IntersectGeofence'),
                          'ev-enter-geo': alert.TrakkerEvent.AlertType == 'EnterGeofence',
                          'ev-bat': alert.TrakkerEvent.AlertType == 'LowBattery'
                        }">

                    <div class="leftest">
                      <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == 'SOS'"
                        src="assets/icons/svg/trakker-events/sos.svg">
                      <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == 'ExitGeofence'"
                        src="assets/icons/svg/trakker-events/exit-geofence.svg">
                      <!-- <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == 'EnterGeofence'" src="assets/icons/svg/trakker-events/enter-geofence.svg"> -->
                      <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == 'LowBattery'"
                        src="assets/icons/svg/trakker-events/low-battery.svg">
                      <!-- <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == 'IntersectGeofence'" src="assets/icons/svg/trakker-events/intersect-geofence.svg"> -->
                      <!-- <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == ''" src="assets/icons/svg/trakker-events/.svg">
                        <img class="ico" *ngIf="alert.TrakkerEvent.AlertType == ''" src="assets/icons/svg/trakker-events/.svg"> -->
                      <div class="left-text">
                        <div class="warntype">
                          <span
                            *ngIf="alert.TrakkerEvent.AlertType == 'EnterGeofence' || alert.AlertType == 'ExitGeofence' || alert.AlertType == 'IntersectGeofence'"
                            ngbTooltip="{{ alert.Comment }}">{{ 'Page.Responses.' + alert.AlertType | translate }}
                            {{alert?.GeofenceEvent?.GeofenceName}}</span>
                          <span
                            *ngIf="alert.TrakkerEvent.AlertType != 'EnterGeofence' && alert.AlertType != 'ExitGeofence' && alert.AlertType != 'IntersectGeofence'">{{
                            'Page.Responses.' + alert.TrakkerEvent.AlertType | translate }}
                            {{alert?.GeofenceEvent?.GeofenceName}}</span>
                        </div>
                        <!-- <div class="date">{{ alert.OccuredOn | date: 'medium' }}</div> -->
                        <div class="date">{{ alert.TrakkerEvent.OccuredOn | ago:translationsAgo }}</div>
                      </div>
                    </div>
                    <div class="rightest">
                      <div>
                        <div class="trk">
                          <div class="alerticon"><img class="ico" src="/assets/{{ alert.IconUrl }}" alt="icon"
                              ngbTooltip="{{ alert.TrakkerName }}"></div>
                          <p class="trkname">{{ alert.TrakkerName }}</p>
                        </div>
                      </div>

                      <!-- INFO-ICON -->
                      <div style="display: flex; align-items: center;">
                        <ng-container
                          *ngIf="alert.TrakkerEvent.AlertType != 'LowBattery' && alert.TrakkerEvent.AlertType != 'EnterGeofence' && alert.TrakkerEvent.AlertType != 'ExitGeofence'; then showTrash; else showInfo">
                        </ng-container>
                        <ng-template #showTrash>
                          <div class="remove" (click)="removeSos(alert)"
                            ngbTooltip="{{ 'Page.ToolTips.REMOVE_ALERT' | translate }}"><img class="svgee"
                              src="assets/icons/svg/delete.svg"></div>
                        </ng-template>
                        <ng-template #showInfo>
                          <img *ngIf="alert.TrakkerEvent.AlertType == 'LowBattery'"
                            src="/assets/icons/svg/information.svg" class="info-circle" alt="info"
                            ngbTooltip="{{ 'Page.ToolTips.INFO_LOW_BATTERY' | translate }}">
                          <img *ngIf="alert.TrakkerEvent.AlertType == 'ExitGeofence'"
                            src="/assets/icons/svg/information.svg" class="info-circle" alt="info"
                            ngbTooltip="{{ 'Page.ToolTips.INFO_EXIT_GEOFENCE' | translate }}">
                        </ng-template>
                      </div>

                    </div>

                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="second-child-container">
      <div class="map-container">
        <!-- <positionMap *ngIf="currentPositions !=null && currentPositions.length > 0" [positions]="currentPositions" [eventId]="currentlyExpandedPositionsId"></positionMap> -->
        <map IsDashboard="true"></map>
      </div>



      <div class="box-model"> <!-- FUEL PRICES -->
        <div class="dash-fuel-prices">
          <div>
            <div class="top-field">
              <div class="top-field-title">
                <div>
                  <p class="box-title" [translate]>Page.Labels.FUEL_PRICES</p>
                  <p class="subline" *ngIf="fuelPrices"> (<span translate>Page.Labels.LAST_UPDATE_FUEL_PRICES<span> {{
                        fuelPrices.CreatedOn | date: 'd MMMM':undefined:locale | lowercase}}</span></span>)</p>
                </div>
                <a (click)="startEditFuelPrices()">
                  <img class="ico-edit" src="/assets/icons/svg/edit.svg" alt="edit-fuel-prices"
                    ngbTooltip="{{ 'Page.ToolTips.EDIT_FUEL_PRICES' | translate }}">
                </a>
              </div>
            </div>
            <div class="fork fork-fuel-prices">
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_PETROL</span> (1l)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.Petrol}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_DIESEL</span> (1l)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.Diesel}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_E85</span> (1l)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.E85}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_ELECTRIC</span> (1kwh)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.Electric}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_GAS</span> (1kg)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.Gas}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
              <div class="box-middle-title">
                <p class="border-bottom-title"><span [translate]>Page.Labels.FUELTYPE_HVO100</span> (1l)</p>
                <p *ngIf="fuelPrices">{{fuelPrices.Hvo100}} <span>SEK</span></p>
                <p *ngIf="fuelPrices == null ">- SEK</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-model"> <!-- Total Fuel Cost -->
        <div class="dash-co2">
          <div>
            <div class="top-field">
              <div class="title-field">
                <p class="box-title" [translate]>Page.Labels.TOTAL_FUEL_COST</p><!--!-->
                <p class="subline">(<span [translate]>Page.Labels.CURRENT_MONTH</span>)</p>
              </div><!--!-->
              <!-- <div class="report-link">
                <a href="/reports/vehicle-summary-report" translate>Page.Labels.SEE_DETAILS</a>
              </div> -->
            </div><!--!-->
            <div class="fork">
              <div class="horiz" *ngIf="!this.showTotalFuelCostLoader"> <span class="sum" [countUp]="currentMonthTotalFuelCost">0</span><span class="currency">&nbsp;SEK</span></div>
              <app-spinner [display]="this.showTotalFuelCostLoader" [isSmallSize]="true"></app-spinner>

              <!-- <p class="sum"><span>222</span><span class="currency">
                  kg</span></p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="box-model"> <!-- CO2 -->
        <div class="dash-co2">
          <div>
            <div class="top-field">
              <div class="title-field">
                <p class="box-title" [translate]>Page.Labels.TOTAL_CO2</p>
                <p class="subline">(<span [translate]>Page.Labels.CURRENT_MONTH</span>)</p>
              </div>
             </div>
            <div class="fork">
              <div *ngIf="!this.showTotalCo2Loader" class="horiz"> <span class="sum" [countUp]="totalVehCo2TotalKg + TotalAssetsCo2Kg">0</span><span class="currency">&nbsp;kg</span></div>
              <app-spinner [display]="this.showTotalCo2Loader" [isSmallSize]="true"></app-spinner>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <br><br><br>





</div>




<!-- MODALS BELOW -->
<div class="modalWrapper" [class.visible]="modal!=null" (click)="CancelModal()">

  <div class="modal" id="infoModal" [class.visible]="modal=='info'" (click)="$event.stopPropagation()">
    <h2>Information</h2>
    <p></p>
    <button class="btn defaultAction" (click)="CancelModal();">Ok</button>
  </div>

  <div class="modal" id="errorModal" [class.visible]="modal=='error'" (click)="$event.stopPropagation()">
    <h2>Error</h2>
    <p>An error occured!</p>
    <button class="btn defaultAction" (click)="CancelModal();">Ok</button>
  </div>


  <!-- MODAL: EDIT ODOMETER -->
  <div class="modal" [class.visibleGrid]="modal=='editOdometer'" (click)="$event.stopPropagation()"
    id="vehicle-odometer">
    <div>
      <h3 [translate]>Page.Labels.EDIT_ODOMETER</h3>
    </div>
    <div *ngIf="pickedVehicle" class="form">

      <div class="labeled-form">
        <div class="field">
          <p class="label field-label" [translate]>Page.Labels.ODOMETER</p>
          <input class="inputOdometer" type="text" value="{{ pickedVehicle.OdometerKilometer }}">
        </div>
        <div class="field">
          <p class="label field-label" [translate]>Page.Labels.COMMENT</p>
          <textarea class="inputOdometerComment" type="text" value="" cols="30" rows="2"></textarea>
        </div>
        <div class="field">
          <p class="label field-label" [translate]>Page.Labels.DISTRIBUTE_DIFFERENCE</p>
          <div class="horiz">
            <button class="btn-ws" (click)="toggleDistributeDifference()">
              <img class="icon-sm" src="assets/icons/svg/toggle-off.svg" *ngIf="distributeDifference == false">
              <img class="icon-sm" src="assets/icons/svg/toggle-on.svg" *ngIf="distributeDifference == true">
              <p *ngIf="distributeDifference == false" [translate]>Page.Labels.DO_NOT_DISTRIBUTE_DIFFERENCE</p>
              <p *ngIf="distributeDifference == true" [translate]>Page.Labels.DISTRIBUTE_DIFFERENCE</p>
            </button>
            <div>
              <div class="infobox" ngbTooltip="{{ 'Page.ToolTips.DISTRIBUTE_DIFFERENCE' | translate }}">
                <img class="icon-sm" src="assets/icons/svg/information.svg">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; justify-content: center;">
        <button class="btn-base btn-options" type="button" name="button" (click)="this.modal = null"
          [translate]>Page.Buttons.CANCEL</button>
        <button class="btn-base btn-save" type="button" name="button" (click)="saveEditOdometer(pickedVehicle)"
          [translate]>Page.Buttons.SAVE_CHANGES</button>
      </div>
    </div>
  </div>


  <!-- MODAL: EDIT DRIVER -->
  <div class="modal" [class.visibleGrid]="modal=='editDriver'" (click)="$event.stopPropagation()" id="vehicle-driver">
    <div>
      <h3 [translate]>Page.Labels.EDIT_DRIVER_NAME</h3>
    </div>
    <div *ngIf="pickedVehicle" class="form">

      <div class="labeled-form">
        <div class="field">
          <p class="label field-label" [translate]>Page.Labels.DRIVER_NAME</p>
          <input class="inputDriverName" type="text" value="{{ pickedVehicle.Driver }}">
        </div>
        <div class="field">
          <p class="label field-label" [translate]>Page.Labels.COMMENT</p>
          <textarea class="inputDriverComment" type="text" value="" cols="30" rows="2"></textarea>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: center;">
          <button class="btn-base btn-options" type="button" name="button" (click)="this.modal = null"
            [translate]>Page.Buttons.CANCEL</button>
          <button class="btn-base btn-save" type="button" name="button" (click)="saveEditDriverName(pickedVehicle)"
            [translate]>Page.Buttons.SAVE_CHANGES</button>
        </div>
      </div>

    </div>
  </div>

  <form (ngSubmit)="saveEditFuelPrices()" [formGroup]="editFuelPricesForm" class="modal-edit-fuel-prices"
    *ngIf="modal=='editFuelPrices'" [class.visibleGrid]="modal=='editFuelPrices'" (click)="$event.stopPropagation()">
    <div class="pl-3 pr-3">
      <div>
        <h3 [translate]>Page.Labels.EDIT_FUEL</h3>
        <p class="modal-top-field-title" [translate]>Page.Labels.EDIT_FUEL_PRICES</p>
      </div>
      <div class="pt-3">
        <div>
          <p class="form-label-edit-fuel-prices field-label">1 liter <span
              [translate]>Page.Labels.FUELTYPE_PETROL</span></p>
          <input formControlName="petrolFuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && petrolFuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_PETROL</p>
        </div>
        <div>
          <p class="form-label-edit-fuel-prices field-label">1 liter <span
              [translate]>Page.Labels.FUELTYPE_DIESEL</span></p>
          <input formControlName="dieselFuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && dieselFuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_DIESEL</p>
        </div>
        <div>
          <p class="form-label-edit-fuel-prices field-label">1 liter <span [translate]>Page.Labels.FUELTYPE_E85</span>
          </p>
          <input formControlName="e85FuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && e85FuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_E85</p>
        </div>
        <div>
          <p class="form-label-edit-fuel-prices field-label">1kwh <span [translate]>Page.Labels.FUELTYPE_ELECTRIC</span>
          </p>
          <input formControlName="electricFuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && electricFuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_ELECTRIC</p>
        </div>
        <div>
          <p class="form-label-edit-fuel-prices field-label">1kg <span [translate]>Page.Labels.FUELTYPE_GAS</span></p>
          <input formControlName="gasFuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && gasFuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_GAS</p>
        </div>
        <div>
          <p class="form-label-edit-fuel-prices field-label">1 liter <span
              [translate]>Page.Labels.FUELTYPE_HVO100</span></p>
          <input formControlName="hvo100FuelConsumptionInput" class="form-control" type="number">
          <p class="error" *ngIf="isSubmitted && hvo100FuelConsumptionInput.invalid" translate>
            Page.Messages.NEED_FUEL_PRICE_HVO100</p>
        </div>
      </div>
    </div>
    <div class="horiz boxbuttons">
      <button class="btn-base btn-cancel iconless" type="button" name="button"
        (click)="this.clearFuelPricesModal()"><span [translate]>Page.Buttons.CANCEL</span></button>
      <button class="btn-base btn-save iconless" type="submit" name="button"><span
          [translate]>Page.Buttons.SAVE_CHANGES</span></button>
    </div>
  </form>




</div>

