import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort2"
})
export class Sort2Pipe  implements PipeTransform {
  transform(array: any, field: string, isDescending: boolean): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    var splitField = field.split(".");
    var first = splitField[0].split("[").length > 1;

    array.sort((a: any, b: any) => {
      if(first){
        var aa = splitField.length==1? a.Vehicles[0] : a.Vehicles.length > 0 ? a.Vehicles[0][splitField[1]] : null;
        var bb = splitField.length==1? b.Vehicles[0] : b.Vehicles.length > 0 ? b.Vehicles[0][splitField[1]] : null;
      }
      else
      {
        var aa = splitField.length==1?  a.length > 0 ? a[splitField[0]] : a[splitField[0]][splitField[1]] : null;
        var bb = splitField.length==1?  b.length > 0 ? b[splitField[0]] : b[splitField[0]][splitField[1]] : null;
      }
      if(aa == null) aa = "";
      if(bb == null) bb = "";
      if(isDescending == true){
        var temp = aa;
        aa = bb;
        bb = temp;
      }

      if (aa < bb) {
        return -1;
      } else if (aa > bb) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
