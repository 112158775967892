<ng-container *ngIf="trakker || event">
  <ng-container *ngIf="outputType == 'icon'">
    <img *ngIf="batteryLevel == 100" src="assets/icons/svg/battery-100.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel >= 75 && batteryLevel != 100" src="assets/icons/svg/battery-100.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel < 75 && batteryLevel >= 50" src="assets/icons/svg/battery-75.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel < 50 && batteryLevel >= 25" src="assets/icons/svg/battery-50.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel < 25 && batteryLevel >= 1" src="assets/icons/svg/battery-25.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel < 1 && batteryLevel > -1" src="assets/icons/svg/battery-0.svg" ngbTooltip="{{ batteryLevel }}%">
    <img *ngIf="batteryLevel == -1" src="assets/icons/svg/battery-100.svg" ngbTooltip="{{ 'Page.Labels.BATTERY_LEVEL_NA' | translate }}" style="filter:brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(7%) hue-rotate(160deg) brightness(85%) contrast(84%);">
    <img *ngIf="batteryLevel == -2" src="assets/icons/svg/trakker-events/power-supply-on.svg" ngbTooltip="{{ 'Page.Labels.EXTERNAL_BATTERY' | translate }}">
  </ng-container>
  <ng-container *ngIf="outputType == 'text'">
    <ng-container *ngIf="batteryLevel >= 0" >
      {{ batteryLevel }} %
    </ng-container>
    <ng-container *ngIf="batteryLevel < 0" >
      {{ 'Page.Labels.' + (trakker.BatteryLevel | uppercase) | translate }}
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="!trakker && !event">
  <ng-container *ngIf="outputType == 'icon'">
    <img src="assets/icons/svg/battery-100.svg" ngbTooltip="{{ 'Page.Labels.BATTERY_LEVEL_NA' | translate }}" style="filter:brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(7%) hue-rotate(160deg) brightness(85%) contrast(84%);">
  </ng-container>
  <ng-container *ngIf="outputType == 'text'">
    {{ 'Page.Labels.BATTERY_LEVEL_NA' | translate }}
  </ng-container>
</ng-container>
