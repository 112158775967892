import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // ACCORDION CODE
    var acc = document.getElementsByClassName("accordion");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        console.log("clicking for active");
        var accbody = this.nextElementSibling;
        if (accbody.style.maxHeight){
          accbody.style.maxHeight = null;
        } else {
          accbody.style.maxHeight = accbody.scrollHeight + "px";
        }
      });
    }
    // END ACCORDION CODE





  }



}
