import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { ToastyConfig } from 'ng2-toasty';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { LocalstoreService } from './_shared/localstore.service';
import { Constants } from './app.config';
import { Router } from '@angular/router';
import { NavService } from './_services/nav.service';
// @ts-ignore
import defaultLanguage from "./../assets/i18n/en.json";
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'TrakkApp4';

  // isLoggedIn = false;

	loadedFeature: string;
	isTrlSideBarHide: Boolean;
	isMenuOpen = false;
	isSubMenuOpened = false;
	isDetailsShow: Boolean;
	loginInfo: any;
	showedPromotionsId: any[] = [];
	public isShowingFbPage: any;
	public promotions: any[] = [];
	public firstTime: any = 0;
	public addClasses = { ml: false, en: false, ar: false };

  public showAlertError = true;

  constructor(
		// private toastyConfig: ToastyConfig,
		private localStore: LocalstoreService,
		public appConstants: Constants,
		private translateService: TranslateService,
		// public jwtHelper: JwtHelperService,
		private router: Router,
		private navService: NavService,
		private titleService: Title
	)
	{
		//console.log("TLOG (app-component): Running constructor");

		// this.toastyConfig.theme = 'material';
		// this.toastyConfig.showClose = true;
		// this.toastyConfig.timeout = 5000;
		// this.toastyConfig.position = 'center-center';
		translateService.setTranslation('en', defaultLanguage)
		translateService.setDefaultLang('en'); // ADDED FROM TUTORIAL https://www.youtube.com/watch?v=Ara2cQ4IQxA

		this.titleService.setTitle(appConstants.webAppTitle);
	}

	switchLanguage(language: string)
	{
		this.translateService.use(language);
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	ngOnInit()
	{

		//console.log("TLOG (app-component): Running ngOnInit");

		//console.log("TLOG (app-component): Getting accountDetails from localStore");
		this.appConstants.accountDetails = this.localStore.get('accountDetails');

		if(this.appConstants.accountDetails)
		{
			var language;
			switch(this.appConstants.accountDetails.Language)
			{
				case "Svenska":
					language = "sv";
					break;
				default:
					language = "en";
					this.appConstants.accountDetails.Language = "English";
					break;

			}
			this.translateService.use(language);

			//this.isLoggedIn = true;
			//console.log("TLOG (app-component): accountDetailsReady");
			this.navService.isLoggedIn.next(true);
		}


	}

	onNavigate(feature: string) {
		this.loadedFeature = feature;
	}

}
