import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterGeofence'
})


export class FilterGeofencePipe implements PipeTransform {
  transform(items: any[], searchGeofences: string): any[] {
    searchGeofences = searchGeofences ? searchGeofences.toLocaleLowerCase() : null;
    return searchGeofences ? items.filter((item: any) =>
      item.Name.toLocaleLowerCase().indexOf(searchGeofences) !== -1) : items;
  }
}