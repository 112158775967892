import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "translateAssignment"
})
export class TranslateAssignmentPipe  implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(assignment: string): string {
    switch(assignment){
      case "OneTrip":
        return "(" + this.translate.instant("Page.Labels.ASSIGNMENT_TYPE_ONE_TRIP") + ")";
      case "ValidForTime":
        return "(" + this.translate.instant("Page.Labels.ASSIGNMENT_TYPE_VALID_FOR_TIME") + ")";
      case "Permanent":
        return "(" + this.translate.instant("Page.Labels.ASSIGNMENT_TYPE_PERMANENT") + ")";
    }
  }
}
