import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/_services/account.service';
import { JwtService } from 'src/app/_services/jwt.service';

@Component({
  selector: 'app-boomr-redirect',
  templateUrl: './boomr-redirect.component.html',
  styleUrls: ['./boomr-redirect.component.scss']
})
export class BoomrRedirectComponent implements OnInit {

  boomrLink: string = "https://app.dstx.se/";

  constructor(private jwtService: JwtService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.logout();
  }


  logout(){
    localStorage.clear();
    this.jwtService.destroyToken();
  }
}
