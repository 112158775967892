import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  transform(value: any, locales?: string): any {
    let _locales: any = [];
    if (locales != null) {
      _locales = locales;
      //console.log(_locales)
    }

    switch (typeof value) {
      case "number":
        var d = new Date(0, 0, 0, value);
        return d.toLocaleTimeString(_locales, {
          hour: "2-digit",
          minute: "2-digit",
        });
      case "string":
        var h = Number.parseInt(value.split(":")[0]);
        var m = Number.parseInt(value.split(":")[1]);
        var d = new Date(0, 0, 0, h, m);
        return d.toLocaleTimeString(_locales, {
          hour: "2-digit",
          minute: "2-digit",
        });
    }

    return value;
  }
}
