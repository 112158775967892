import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'batteryLevel',
  templateUrl: './battery-level.component.html',
  styleUrls: ['./battery-level.component.scss']
})
export class BatteryLevelComponent implements OnInit {
  @Input() trakker: any;
  @Input() event: any;
  @Input() outputType: string;
  public batteryLevel: number;

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    if(this.trakker){
      this.batteryLevel = this.GetBatteryLevel(this.trakker.BatteryLevel);
      if(this.batteryLevel == -1 && this.trakker.ActiveAlerts != null && this.trakker.ActiveAlerts.find(a => a.TrakkerEvent.AlertType == "LowBattery"))
        this.batteryLevel = 0;
    }
    if(this.event)
    {
      // console.log("is event");
      // console.log(this.event.BatteryLevel);
      this.batteryLevel = this.GetBatteryLevel(this.event.BatteryLevel);
    }
    if(this.outputType==null)
      this.outputType = "icon";
    this.outputType = this.outputType.toLowerCase();

  }

  GetBatteryLevel(batteryLevel):number{
    if(batteryLevel != null)
    {
      if(this.IsNumber(batteryLevel) == true)
      {
        return batteryLevel;
      }

      if(batteryLevel == 'ExternalBattery')
      {
          return -2;
      }
    }
    return -1;//Battery N/A
  }

  IsNumber(str){
      return isNaN(Number(str))==false;
  }
}
