import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "otDuration",
})
export class OtDurationPipe implements PipeTransform {
  transform(event: any, translations: any[] = [], outputType = "about"): any {
    // console.log("Event is:");
    // console.log(event);

    let nowTime = null;

    if (event.StopDateTime == null) {
      // console.log("Ongoing, calculating with right now");
      nowTime = new Date().toISOString();
      // console.log(nowTime);
    }
    const tempStartDateTime = event.StartDateTime || "";
    if (
      (event.hasOwnProperty("StartDateTime") &&
        event.StartDateTime != null &&
        event.hasOwnProperty("StopDateTime") &&
        event.StopDateTime != null) ||
      nowTime
    ) {
      var startUtcDateTime = tempStartDateTime.toUpperCase();
      if (startUtcDateTime[startUtcDateTime.length - 1] != "Z") startUtcDateTime += "Z";

      var realStartDateTime = new Date(Date.parse(startUtcDateTime));

      if (nowTime) {
        // console.log("Using now time for calc");
        var StopUtcDateTime = nowTime.toUpperCase();
      } else {
        var StopUtcDateTime = event.StopDateTime.toUpperCase();
      }
      if (StopUtcDateTime[StopUtcDateTime.length - 1] != "Z") StopUtcDateTime += "Z";

      var realStopDateTime = new Date(Date.parse(StopUtcDateTime));

      var seconds = Math.floor((realStopDateTime.getTime() - realStartDateTime.getTime()) / 1000);

      if (outputType == "seconds") return seconds;

      if (outputType == "hours") {
        let minutes = Math.round(seconds / 60);
        let hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
        let hourString = "";
        if (hours > 0) {
          hourString = hours + "h ";
        }
        return hourString + minutes + "m";
      }

      if (outputType == "exact") {
        var minutes = Math.round(seconds / 60);

        // var days = Math.floor(seconds / 86400);
        var days = Math.floor(minutes / 1440);

        var daysText =
          (days == 1
            ? "1 " + translations.find((t) => t.key == "DAY").value
            : days + " " + translations.find((t) => t.key == "DAYS").value) + " ";
        if (days == 0) {
          daysText = "";
        }
        // var hours = Math.floor((seconds - days * 86400) / 3600);
        var hours = Math.floor((minutes - days * 1440) / 60);

        var hoursText =
          (hours == 1
            ? "1 " + translations.find((t) => t.key == "HOUR").value
            : hours + " " + translations.find((t) => t.key == "HOURS").value) + " ";
        if (hours == 0) {
          hoursText = "";
        }

        minutes = minutes - hours * 60 - days * 1440;
        // var minutes = Math.round((seconds - hours * 3600 - days * 86400) / 60);
        var minutesText =
          (minutes == 1
            ? "1 " + translations.find((t) => t.key == "MINUTE").value
            : minutes + " " + translations.find((t) => t.key == "MINUTES").value) + " ";

        // seconds = seconds - hours*3600 - minutes * 60;
        // var secondsText = seconds == 1?"1 " + translations.find(t=>t.key == "SECOND").value:seconds + " " + translations.find(t=>t.key == "SECONDS").value;

        // return hoursText + minutesText + secondsText;

        return daysText + hoursText + minutesText;
      }

      if (outputType == "exactNoDays") {
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var hoursText =
          (hours == 1
            ? "1 " + translations.find((t) => t.key == "HOUR").value
            : hours + " " + translations.find((t) => t.key == "HOURS").value) + " ";
        if (hours == 0) {
          hoursText = "";
        }
        minutes = minutes - hours * 60;
        if (minutes > 0) {
          var minutesText =
            (minutes == 1
              ? "1 " + translations.find((t) => t.key == "MINUTE").value
              : minutes + " " + translations.find((t) => t.key == "MINUTES").value) + " ";
        } else if (minutes < 1 && hours === 0) {
          var minutesText =
            (minutes < 1
              ? " < 1 " + translations.find((t) => t.key == "MINUTE").value
              : minutes + " " + translations.find((t) => t.key == "MINUTES").value) + " ";
        } else {
          var minutesText = "";
        }

        return hoursText + minutesText;
      }

      var interval = Math.floor(seconds / 31536000);

      if (interval == 1) {
        var ABOUT_ONE_YEAR = translations.find((t) => t.key == "ABOUT_ONE_YEAR");
        if (ABOUT_ONE_YEAR) {
          return ABOUT_ONE_YEAR.value;
        }
        return "about 1 year";
      }
      if (interval > 1) {
        var ABOUT_YEARS = translations.find((t) => t.key == "ABOUT_YEARS");
        if (ABOUT_YEARS) {
          return ABOUT_YEARS.value.replace("{ value }", interval);
        }
        return "about " + interval + " years";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        var ABOUT_ONE_MONTH = translations.find((t) => t.key == "ABOUT_ONE_MONTH");
        if (ABOUT_ONE_MONTH) {
          return ABOUT_ONE_MONTH.value;
        }
        return "about 1 month";
      }
      if (interval > 1) {
        var ABOUT_MONTHS = translations.find((t) => t.key == "ABOUT_MONTHS");
        if (ABOUT_MONTHS) {
          return ABOUT_MONTHS.value.replace("{ value }", interval);
        }
        return "about " + interval + " months";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        var ABOUT_ONE_DAY = translations.find((t) => t.key == "ABOUT_ONE_DAY");
        if (ABOUT_ONE_DAY) {
          return ABOUT_ONE_DAY.value;
        }
        return "about 1 day";
      }
      if (interval > 1) {
        var ABOUT_DAYS = translations.find((t) => t.key == "ABOUT_DAYS");
        if (ABOUT_HOURS) {
          return ABOUT_DAYS.value.replace("{ value }", interval);
        }
        return "about " + interval + " days";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        var ABOUT_ONE_HOUR = translations.find((t) => t.key == "ABOUT_ONE_HOUR");
        if (ABOUT_ONE_HOUR) {
          return ABOUT_ONE_HOUR.value;
        }
        return "about 1 hour";
      }
      if (interval > 1) {
        var ABOUT_HOURS = translations.find((t) => t.key == "ABOUT_HOURS");
        if (ABOUT_HOURS) {
          return ABOUT_HOURS.value.replace("{ value }", interval);
        }
        return "about " + interval + " hours";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        var ABOUT_ONE_MINUTE = translations.find((t) => t.key == "ABOUT_ONE_MINUTE");
        if (ABOUT_ONE_MINUTE) {
          return ABOUT_ONE_MINUTE.value;
        }
        return "about 1 minute";
      }
      if (interval > 1) {
        var ABOUT_MINUTES = translations.find((t) => t.key == "ABOUT_MINUTES");
        if (ABOUT_MINUTES) {
          return ABOUT_MINUTES.value.replace("{ value }", interval);
        }
        return "about " + interval + " minutes";
      }
      var A_FEW_SECONDS = translations.find((t) => t.key == "A_FEW_SECONDS");
      if (A_FEW_SECONDS) {
        return A_FEW_SECONDS.value;
      }
      return "a few seconds";
    }
    return "";
  }
}
