import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

// scrollspy directive to refresh scrollspy on Switch Lang
@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective {

  @Input()
  lang: any;

  @Input()
  activeElementId:string;

  constructor(private el: ElementRef) {
    
  }


  // refresh scrollspy on language gets changed 
  ngOnChanges(changes: SimpleChanges) {

    this.scrollPageById(this.activeElementId)

    this.refreshScrollSpy()

  }

  scrollPageById(Id:string ){
    
      this.el.nativeElement.querySelector(Id).scrollIntoView(); // scroll to a particular element
  }

  refreshScrollSpy(): any {

    if (typeof jQuery != 'undefined') {

      this.el.nativeElement.querySelectorAll('[data-spy="scroll"]').forEach(function () {

        (<any>jQuery(this)).scrollspy('refresh')

      })
    }
  }


}
