import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class GeofenceService {
  parameters: any;

  constructor(private api: ApiService) {}

  getAllGeofences(): Observable<any> {
    return this.api.get("geofences/");
  }

  getGeofence = (id: number) => {
    return this.api.get(`geofences/${id}`);
  };

  getGeofenceEvents(geofenceId: number, data: any): Observable<any> {
    return this.api.post("geofences/" + geofenceId + "/event/", data);
  }

  getGeofenceEventSummary(data): Observable<any> {
    return this.api.post("geofences/event-summary/", data);
  }

  createNewGeofence(data): Observable<any> {
    return this.api.post("geofences/", data);
  }
  editGeofenceData(geofenceId: number, data: any): Observable<any> {
    return this.api.put("geofences/" + geofenceId, data);
  }

  deleteGeofence(geofenceId: number): Observable<any> {
    return this.api.delete("geofences/" + geofenceId);
  }

  deleteParkingGeofence(trakkerId: number): Observable<any> {
    return this.api.delete("vehicles/" + trakkerId + "/parking-geofence");
  }

  addGeofenceContact(geofenceId: number, contactId: number): Observable<any> {
    return this.api.post("geofences/" + geofenceId + "/contact/", contactId);
  }
  removeGeofenceContact(geofenceId: number, contactId: number): Observable<any> {
    return this.api.delete("geofences/" + geofenceId + "/contact/" + contactId);
  }
  createNewGeofenceSchedule(geofenceId: number, data): Observable<any> {
    return this.api.post("geofences/" + geofenceId + "/schedules/", data);
  }
  editGeofenceSchedule(geofenceId: number, geofenceScheduleId: number, data: any): Observable<any> {
    return this.api.put("geofences/" + geofenceId + "/schedules/" + geofenceScheduleId, data);
  }
  removeGeofenceSchedule(geofenceId: number, geofenceScheduleId: number): Observable<any> {
    return this.api.delete("geofences/" + geofenceId + "/schedules/" + geofenceScheduleId);
  }


    // Function to fetch Excel or PDF report
  // POST /api/report/geofence-event
  getGeofenceEventReport(filter) {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/geofence-event/", filter, newParams);
  }

    // POST /api/report/geofence-event
    getGeofenceEventSummaryReport(filter) {
      const newParams: any = Object.assign({});
      newParams.responseType = "blob";
      return this.api.post("report/geofence-event-summary/", filter, newParams);
    }
}
