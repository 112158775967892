<div class="site-under-maintenance-container">
    <div>
        <h1 class="site-under-maintenance-title mt-4">{{'Page.Labels.SITE_UNDER-MAINTENACE_H1' | translate}}</h1>
        <br>
        <p>{{'Page.Labels.SITE_UNDER-MAINTENACE_TEXT' | translate}}</p>
        <br>
        <h6>-TRAKK</h6>
        <div>
            <img src="assets\images\cables-unplugged.png" width="800">
        </div>
    </div>
</div>

