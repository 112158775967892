import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'colorIcon',
  templateUrl: './color-icon.component.html',
  styleUrls: ['./color-icon.component.scss']
})
export class ColorIconComponent implements OnInit {
  @Input() type: any;
  @Input() title: any;
  @Input() disabled: any;
  @Input() size: any;
  @Input() dissed: boolean;

  colorIconUrl: any;
  baseUrl = 'assets/icons/svg/';
  addUrl = '';
  colorClass = '';
  sizeClass = '';

  constructor() { }

  ngOnInit() {

    if (this.size) {
      this.sizeClass = this.size;
    }

    if (this.type) {
      switch (this.type.toLowerCase()) {
        case 'contact':
          this.addUrl = 'letter.svg';
          break;
        case 'trakker':
          this.addUrl = 'trakker.svg';
          break;
        case 'geofence':
          this.addUrl = 'geofence.svg';
          break;
        case 'group':
          this.addUrl = 'group.svg';
          break;
        case 'account':
          this.addUrl = 'account.svg';
          break;
        case 'offgrid':
          this.addUrl = 'trakker-events/off-grid.svg';
          break;
        case 'parking':
        case 'parked':
          this.addUrl = 'trakker-events/parking.svg';
          break;
        case 'position':
          this.addUrl = 'trakker-events/position.svg';
          this.colorClass = 'svg-green';
          break;
        case 'alert':
          this.addUrl = 'trakker-events/alert.svg';
          break;
        case 'newtrakker':
          this.addUrl = 'new-trakker.svg';
          break;
        case 'active':
          this.addUrl = 'trakker-events/active.svg';
          break;
        case 'idle':
          this.addUrl = 'trakker-events/idle.svg';
          break;
        case 'clock':
          this.addUrl = 'calendar.svg';
          break;
        case 'twentyfour-seven':
          this.addUrl = 'infinity.svg';
          break;
        case 'exit-geofence':
          this.addUrl = 'trakker-events/exit-geofence.svg';
          break;
        case 'enter-geofence':
          this.addUrl = 'trakker-events/enter-geofence.svg';
          break;
        case 'notavailable':
          this.addUrl = 'trakker-events/n-a.svg';
          break;
        case 'poweroff':
        case 'poweredoff':
          this.addUrl = 'trakker-events/power-off.svg';
          this.colorClass = 'svg-red';
          break;
        case 'shake':
          this.addUrl = 'trakker-events/shake.svg';
          break;
        case 'poweron':
        case 'poweredon':
          this.addUrl = 'trakker-events/power-on.svg';
          this.colorClass = 'svg-green';
          break;
        case 'sos':
          this.addUrl = 'trakker-events/sos.svg';
          break;
        case 'lowbattery':
          this.addUrl = 'trakker-events/low-battery.svg';
          break;
        case 'moving':
          this.addUrl = 'trakker-events/position-in-motion.svg';
          break;
        default:
          this.addUrl = 'no-icon.svg';
        // console.log(this.type)
      }

    }

    if (this.disabled == true) {
      this.colorClass = 'disab';
    }

    // if (this.type == 'contact') {
    //   this.addUrl = 'letter.svg';
    // } else if (this.type == 'trakker') {
    //   this.addUrl = 'trakker.svg';
    // } else if (this.type == 'geofence') {
    //   this.addUrl = 'geofence.svg';
    // } else if (this.type == 'group') {
    //   this.addUrl = 'group.svg';
    // } else if (this.type == 'account') {
    //   this.addUrl = 'account.svg';
    // } else {
    //   // this.addUrl = 'icon-missing.svg';
    //   this.addUrl = '../event-types/icon--03.png';
    // }

    this.colorIconUrl = this.baseUrl + this.addUrl;
  }

  test() {
    console.log(this.colorClass);
    console.log(this.type);
    console.log(this.title);
    console.log(this.disabled);
    console.log(this.dissed);
  }

}
