import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
//import { async } from '@angular/core/testing';
//import * as jquery from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { LocalstoreService } from '../_shared/localstore.service';
import { Constants } from '../app.config';
import { AccountService } from '../_services/account.service';
import { NavService } from '../_services/nav.service';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtService } from '../_services/jwt.service';

//import { SharedModule } from '../_shared/shared.module';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean;
  isBoomrCustomer: boolean;
  accountDetails: any;
  companyId: number;
  customerAdminName: string;
  hostname:string;

  appVersion: string = environment.appVersion;
  language: string;
  @Output()
  featureSelected = new EventEmitter<string>();

  companyUpdateClosed = false;


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  subscriptions: any = [];

  onSelect(feature: string) {
    this.featureSelected.emit(feature);
  }


  constructor(
    public translate: TranslateService,
    private localStore: LocalstoreService,
    public appConstants: Constants,
    private accountService: AccountService,
    public navService: NavService,
    private breakpointObserver: BreakpointObserver,
    private jwtService: JwtService
  ) {
    this.hostname = window.location.hostname;
   
    this.subscriptions.push(this.accountService.language.subscribe(_language => {
      if (this.accountDetails)
        this.accountDetails.Language = _language;
      this.language = _language;
    }));

    this.subscriptions.push(this.navService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.appConstants.accountDetails = this.localStore.get('accountDetails');

        if(this.appConstants.accountDetails.CustomerAdminName === "Logout"){
            this.isBoomrCustomer = true;
        }
      }

      if (isLoggedIn === false) {
        this.appConstants.accountDetails = null;
        switch(window.location.hostname)
        { 
          case "boomr.trakk.se":
            this.appConstants.SetTheme(3);
            break;
          default:
            this.appConstants.SetTheme(0);
          break;
        }
      }

      this.accountDetails = this.appConstants.accountDetails;

      if (this.accountDetails) {
        this.accountService.language.next(this.accountDetails.Language);
        this.language = this.accountDetails.Language;
        // TODO: Dirty fix..
        if (this.language === 'Undefined') {
          this.language = 'English';
        }
        this.customerAdminName = this.accountDetails.CustomerAdminName;
        
        switch(this.customerAdminName)
        { 
          case "Boomr":
            this.appConstants.SetTheme(3);
            break;
          default:
            this.appConstants.SetTheme(0);
          break;
        }
        
      }

    }));
  }

  ngOnInit() {
    if (this.accountDetails) {
      this.companyId = this.accountDetails.CustomerId;
    }
    this.companyUpdateClosed = this.localStore.get('companyUpdateClosed');

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }

  closeCompanyUpdate() {
    this.companyUpdateClosed = true;
    this.localStore.store('companyUpdateClosed', true);
  }


  switchLanguage(language: string) {

    // console.log("TLOG (nav-component): Switch Language to " + language);
    this.translate.use(language);
    var longLanguage;
    switch (language) {
      case "sv":
        longLanguage = "Svenska";

        break;
      case "en":
      default:
        longLanguage = "English";
        break;
    }

    if (this.accountDetails == null) {
      this.language = longLanguage;
      return;
    }

    this.subscriptions.push(this.accountService.setLanguage(longLanguage).subscribe(result => {
      //console.log("TLOG (nav-component): Language (" + longLanguage + ") stored");
      this.language = longLanguage;
      this.accountService.language.next(longLanguage);
      // this.userInfo.Language = longLanguage;
    }));

  }

  logout() {
    this.isLoggedIn = false;
    localStorage.clear();
    this.jwtService.destroyToken();
  }



}
