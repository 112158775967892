import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { AccountService } from '../../_services/account.service';
// import { LocalstoreService } from '../../_shared/localstore.service';

//import 'rxjs/add/operator/share';

/**
 * Service to start/stop preloader and set preloader messages
 *
 * @export
 * @class Preloader
 */

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  public status: Subject<Object> = new Subject();
  private _active = false;
  private defaultMessage = 'MESSAGE.LOADING';
  public message: string;
  private counter = 0;
  subscriptions: any = [];

  /**
   * Creates an instance of Preloader.
   *
   *
   * @memberOf Preloader
   */

  constructor(
    // private localStore: LocalstoreService,
    // public accountService: AccountService,
    ) {
    // console.log("TLOG (preloader): Running constructor");

    this.message = this.defaultMessage;



  }

  /**
   * Method to get status of the preloader
   *
   * @type {boolean}
   * @memberOf Preloader
   */
  public get active(): boolean {
    return this._active;
  }

  /**
   * Method to set the preloader status to true/false
   *
   *
   * @memberOf Preloader
   */
  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  /**
   * Method to start preloader by setting status to true
   *
   * @param {string} [m]
   *
   * @memberOf Preloader
   */
  public start(m?: string) {
    this.counter++;
    this.active = true;
    this.message = m ? m : (this.message ? this.message : this.defaultMessage);
  }

  /**
   * Method to stop preloader by setting status to false
   *
   *
   * @memberOf Preloader
   */
  public stop(): void {
    this.counter--;
    if (this.counter <= 0) {
      this.resetMessage();
      this.active = false;
      this.counter = 0;
    }
  }

  /**
   * Method to set message of the preloader, if message is not passed with start method
   *
   * @param {any} message
   *
   * @memberOf Preloader
   */
  public setMessage(message): void {
    this.message = message;
  }

  /**
   * Method to reset the message
   *
   *
   * @memberOf Preloader
   */
  public resetMessage(): void {
    this.message = null;
  }

  // tslint:disable-next-line:eofline


}
