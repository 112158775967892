<div  class="boomr-redirect-container">

    <div >
        <img src="assets\images\boomr.png" width="400">
        <!-- <h1 >{{'Page.Labels.SITE_UNDER-MAINTENACE_H1' | translate}}</h1> -->

        <div style="margin-top: 50px;">
            <h1 class="boomr-redirect-title">{{ 'Page.Labels.BOOMR_REDIRECT_TITLE' | translate }}</h1>
            <br>
            <h5>
                <a [href]="boomrLink">
                  <p> {{ 'Page.Labels.BOOMR_REDIRECT_SUBTITLE' | translate }} </p>
                </a>
            </h5>
        </div>
    </div>

</div>


