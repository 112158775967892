import { Injectable, Inject } from '@angular/core'; // ADDED INJECT
import { CryptoService } from './crypto.service'; // ADDED FROM CS
import * as _ from 'lodash'; // ADDED FROM CS
// import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LocalstoreService {

  private cryptoService: CryptoService;

  /**
   * Store data to local storage after encryption
   *
   * @param {string} key
   * @param {string} data
   *
   * @memberOf LocalStorage
   */
  store(key, data) {
    const encryptedData = this.cryptoService.encrypt(JSON.stringify(data)).toString();
    const keyHashed = this.cryptoService.md5(key);
    window.localStorage.setItem(keyHashed, encryptedData);
  }

  /**
   * Get data from local storage after decryption
   *
   * @param {string} key
   * @returns
   *
   * @memberOf LocalStorage
   */
  get(key) {
    const keyHashed = this.cryptoService.md5(key);
    const encryptedData = window.localStorage.getItem(keyHashed);
    if (!_.isNull(encryptedData)) {
      if (!_.isEmpty(this.cryptoService.decrypt(encryptedData))) {
        return JSON.parse(this.cryptoService.decrypt(encryptedData));

      }

    }

    return null;
  }


  /**
   * Method to clear all local storage items from browser (destructive!)
   *
   *
   * @memberOf LocalStorage
   */
  clear() {
    window.localStorage.clear();
  }

  /**
   * Method to clear particular local storage items from browser (destructive!) using key specified
   *
   *
   * @memberOf LocalStorage
   */
  remove(key) {
    const keyHashed = this.cryptoService.md5(key);
    localStorage.removeItem(keyHashed);
  }

  constructor(@Inject(CryptoService) cryptoService: CryptoService) {
		//console.log("TLOG (localstore-service): Running constructor");
    this.cryptoService = cryptoService;
	}



}
