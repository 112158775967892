<div class="box-container" [ngClass]="getBackgroundClass()">
    <div *ngIf="type != 'alert'" class="box-header">
        <p [translate]>{{ header }}</p>
    </div>
    <div class="box-body" [ngClass]="{'no-border': type === 'alert'}">
        <div class="box-content">
            <!-- <p>{{ body }}</p> -->
            <ng-content select="[content]"></ng-content>
        </div>
        <div *ngIf="type != 'alert'" class="box-action">
            <ng-content select="[action]"></ng-content>

            <!-- <p>info-box works!</p> -->
        </div>
    </div>
</div>