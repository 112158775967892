// import { Component, OnInit, AfterViewInit  } from '@angular/core';
// import { LoaderService } from '../loader.service';

// @Component({
//   selector: 'app-spinner',
//   templateUrl: './spinner.component.html',
//   styleUrls: ['./spinner.component.scss']
// })
// export class SpinnerComponent implements AfterViewInit {

//   constructor(public loader: LoaderService) { }

//   ngOnInit(): void {
//     console.log("spinner:");

//     console.log(this.loader.getLoading());
//   }

//   ngAfterViewInit(): void {
//     console.log("Spinner component initialized");
//     console.log(this.loader.getLoading());
//   }

// }
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewInit, OnDestroy {
  @Input() display:boolean;
  @Input() isSmallSize:boolean;

  loading = false;
  private loaderSubscription: Subscription;

  constructor(public loader: LoaderService, private cdRef: ChangeDetectorRef) {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loaderSubscription = this.loader.getLoading().subscribe((loading: boolean) => {
      this.loading = loading;
      // Trigger change detection explicitly
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    this.loaderSubscription.unsubscribe();
  }
}
