import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
  HttpResponse,
} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiErrorHandlerService } from "./apierrorhandler/apierrorhandler.service";
import { IApiError } from "../_interfaces/apierrors.interface";
import { Router } from "@angular/router";
import { element } from "protractor";

@Injectable()
export class ApiService {

  constructor(private http: HttpClient, private apiErrorHandler: ApiErrorHandlerService, private router: Router) {}

  /** Api call to external sources
   *  Disabling CORS check
   */
  getExternal(path: string): Observable<any> {
    const data$ = new Observable((observer) => {
      fetch(path)
        .then((response) => response.json()) // or text() or blob() etc.
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    });

    return data$;
  }

  get(path: string, params: HttpParams = new HttpParams(), internalCall = true, handleError = true): Observable<any> {
    const url = internalCall ? `${environment.APIBasePath}` : "";
    return this.http.get<any>(`${url}${path}`, { params }).pipe(catchError(e => this.formatErrors(e, handleError )));
  }

  put(path: string, body: Object = {}, handleError = true): Observable<any> {
    return this.http.put(`${environment.APIBasePath}${path}`, JSON.stringify(body)).pipe(catchError(e => this.formatErrors(e, handleError )));
  }

  post(path: string, body: Object = {}, options: Object = {}, handleError = true): Observable<any> {
    return this.http
      .post(`${environment.APIBasePath}${path}`, JSON.stringify(body), options)
      .pipe(catchError(e => this.formatErrors(e, handleError )));
  }

  delete(path, handleError = true): Observable<any> {
    return this.http.delete(`${environment.APIBasePath}${path}`).pipe(catchError(e => this.formatErrors(e, handleError )));
  }


  private formatErrors = (error: any, handleError = true) => {
    if (error.error instanceof ErrorEvent && handleError) {
        // Client-side error
        this.apiErrorHandler.handleError({
            ErrorCode: 500,
            ErrorName: "Undefined",
            ErrorMessage: error.error.message,
            isShowingMessage: true,
        });
    } else {
        // Server-side error
        const err = this.formatErrorResponse(error);
        if (handleError) this.apiErrorHandler.handleError(err);
    }
    return throwError(error);
  };

  private formatErrorResponse(error: any) {
    let err: IApiError;
    
    if (error && Array.isArray(error.error) && error.error.length > 0) {
        // Handle array of errors from backend
        err = error.error[0];
        err.isShowingMessage = true; 
    } else if (error && error.error && typeof error.error === 'object') {
        // Handle a single error object
        err = error.error;
        err.isShowingMessage = true;
    } else {
        // Handle general server errors
        switch (error?.status) {
            case 0:
                error.statusText = "UnknownError";
                break;
            case 400:
                error.statusText = "BadRequest";
                break;
            case 401:
                error.statusText = "Unauthorized";
                break;
            case 403:
                error.statusText = "Forbidden";
                break;
            case 500:
                error.statusText = "Internal Server Error";
                break;
            case 502:
            case 503:
            case 504:
                this.router.navigate(['/site-under-maintenance']);
                break;
            default:
                error.statusText = error.statusText || "UnknownError";
        }
        err = {
            ErrorCode: error?.status,
            ErrorName: error?.statusText,
            ErrorMessage: error?.message,
            isShowingMessage: error?.status === 0 ? false : true,
        };
    }
    return err;
  }
}
