<ng-container *ngIf="event != null && event.SafetyZone  != true">
  <p *ngIf="isPrivate && event.DriverLocked == false"
     [translate]>Page.Labels.PRIVATE</p>
  <ng-container *ngIf="isPrivate == false || event.DriverLocked">
    <ng-container *ngIf="address != null && address.Street == null">
      <p *ngIf="address != 'Private'">{{address}}</p>
    </ng-container>
    <ng-container *ngIf="address != null && address.Street!=null">
      <p class="street">{{address.Street}} {{address.HouseNumber}}</p>
      <p class="zipcode">{{address.PostalCode}} {{address.City}} {{address.Country}}</p>
    </ng-container>
    <ng-container *ngIf="address == null && position != null">
      <p>
        {{position.Latitude}} {{position.Longitude}}
        <a *ngIf="reverseGeocode == false"
           title="{{ 'Page.ToolTips.GET_ADDRESS' | translate }}"
           (click)="resolveThisAddress()"><img src="assets/icons/svg/magnifying-glass.svg"
               class="svg"></a>
      </p>
      <p *ngIf="reverseGeocode == true">{{ 'Page.Labels.FETCHING_ADDRESS' | translate }}</p>
    </ng-container>

    <ng-container *ngIf="address == null && position == null">
      <ng-container [ngSwitch]="true">
        <p *ngSwitchCase="address == null && position == null && event.DriverLocked && isPrivate && userInfo.AccountId != event.AccountId">{{ 'Page.Labels.PRIVATE' | translate }}</p>
        <p *ngSwitchDefault>{{ 'Page.Labels.NO_ADDRESS_FOUND' | translate }}</p>      
      </ng-container>
    </ng-container>

  </ng-container>
</ng-container>

<ng-container *ngIf="event?.SafetyZone == true">
  <p>{{ 'Page.Labels.SAFETY_ZONE' | translate }}</p>
</ng-container>

<p *ngIf="event == null">{{ 'Page.Labels.NO_POSITION' | translate }}</p>