import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
// import { async } from '@angular/core/testing';
// import * as jquery from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { LocalstoreService } from '../_shared/localstore.service';
import { Constants } from '../app.config';
import { AccountService } from '../_services/account.service';
import { NavService } from '../_services/nav.service';
import { JwtService } from '../_services/jwt.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;

  appTitle: string = 'TrakkApp';

  companyDetails: any;
  accountDetails: any;
  language: string;
  @Output()
  featureSelected = new EventEmitter<string>();
  subscriptions: any = [];



  // https://dzone.com/articles/understanding-output-and-eventemitter-in-angular
  // @Output() valueChange = new EventEmitter();
  // counter = 0;
  // valueChanged() { // You can give any function name
  //     this.counter = this.counter + 1;
  //     this.valueChange.emit(this.counter);
  // }


  onSelect(feature: string) {
    this.featureSelected.emit(feature);
  }

  constructor(
    public translate: TranslateService,
    private localStore: LocalstoreService,
    public appConstants: Constants,
    private loginService: AccountService,
    public navService: NavService,
    private jwtService: JwtService
  ) {
    //console.log("TLOG (nav.component): Running constructor");

    this.subscriptions.push(this.loginService.language.subscribe(_language => {
      if (this.accountDetails)
        this.accountDetails.Language = _language;
      this.language = _language;
    }));

    this.subscriptions.push(this.navService.isLoggedIn.subscribe(isLoggedIn => {
      // console.log("TLOG (nav-component): isLoggedIn = " + isLoggedIn);
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this.appConstants.companyDetails = this.localStore.get('companyDetails');
        this.appConstants.accountDetails = this.localStore.get('accountDetails');
      }

      if (isLoggedIn === false) {
        this.appConstants.companyDetails = null;
        this.appConstants.accountDetails = null;
      }

      this.companyDetails = this.appConstants.companyDetails;
      this.accountDetails = this.appConstants.accountDetails;

      if (this.accountDetails) {
        this.loginService.language.next(this.accountDetails.Language);
        this.language = this.accountDetails.Language;
      }

    }));
  }

  ngOnInit() {
    this.appConstants.accountDetails = this.localStore.get('accountDetails');
    this.accountDetails = this.appConstants.accountDetails;

    // //this.accountDetails = this.appConstants.accountDetails;//.localStore.get('accountDetails');
    // this.accountDetails = this.localStore.get('accountDetails');

    // NEW CODE FOR EMIT. update nav automatically
    // https://stackoverflow.com/questions/42987293/refresh-header-after-login-in-angular2

    // https://www.quora.com/How-do-I-update-a-username-on-Navbar-after-a-user-login-without-refreshing-the-page-with-Angular

    // this.authService.getEmitter().subscribe((customobject) => {
    //   console.log("Component is notified of successfull login!");
    // });

    //this.language = "English";


  }

  switchLanguage(language: string) {
    //console.log("TLOG (nav-component): Switch Language to " + language);
    this.translate.use(language);
    var longLanguage;
    switch (language) {
      case "sv":
        longLanguage = "Svenska";
        break;
      case "en":
      default:
        longLanguage = "English";
        break;
    }

    if (this.accountDetails == null) {
      this.language = longLanguage;
      return;
    }

    this.subscriptions.push(this.loginService.setLanguage(longLanguage).subscribe(result => {
      this.language = longLanguage;
      this.loginService.language.next(longLanguage);
    }));
  }

  logout() {
    this.isLoggedIn = false;
    localStorage.clear();
    this.jwtService.destroyToken();
  }

  // loginStatus$ : Observable<boolean>;

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }

}
