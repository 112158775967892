import { Component, OnInit, Input } from '@angular/core';
import { Constants } from '../../app.config';

@Component({
  selector: 'error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {
  // @Input() isShowingMessage: boolean;
  // @Input() messageText: string;

  constructor(public appConstants: Constants) { }

  ngOnInit() {
  }

}
