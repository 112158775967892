import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LocalstoreService } from './_shared/localstore.service';

@Injectable()
export class Constants {
	/*********************************************************/
	/*                                                       */
	/*                         TRAKK                         */
	/*                                                       */
	/*********************************************************/
	currentThemeId: number = 0;

	webAppTitle: string = "Taggr";

	initialAutomaticRefreshEventsActivated: boolean = false;
	automaticRefreshEventsInterval: number = 60000; // 60sec

	offGridHoursThreshold: number = 24;

	deafaultColorThemeId = 0;
	colorThemes = [
		{
			Name: "Trakk Default",
			Id: 0,
			Constants: {
				BodyFontFamily: 'Quicksand',
				Menu: {
					Background: "#0d1627", // trakk color: #263238
					LogotypeImageUrl: "/assets/logos/Taggr_White-and-Light-Blue-on-Transparent.svg", // trakk logo: text-logo-transp-white.png
					Link: {
						TextColor: "#fff",
						Background: "",
						Font: {
							Size: "14px",
							Weight: "500",
							// LetterSpacing: "3px",
							// FontVariantCaps: "all-petite-caps"
						},
						Hover: {
							TextColor: "#fff",
							Background: "rgba(0, 0, 0, 0.2)"
						},
						Active: {
							TextColor: "#fff",
							BackgroundActive: "rgba(0, 0, 0, 0.2)"
						}
					}
				},
				ContentBackground: "#fff",
				ContentTextColor: "#555",
				ContentLinkColor: "#555",
				ContentLinkColorHover: "#555",
				DefaultButtonBackground: "#263238",
				DefaultButtonTextColor: "#fff",
				DefaultButtonBackgroundHover: "#192125",
				DefaultButtonTextColorHover: "#fff",
				DefaultButtonBackgroundActive: "#131a1d",
				DefaultButtonTextColorActive: "#fff",
				DefaultButtonFocus: "rgba(0,123,255,.25)",
				FormFocus: "rgba(0,123,255,.25)",
				MapBackground: "lightblue",
				MapClusterBackground: "rgba(74, 101, 114, 0.8)",
				MapClusterTextColor: "#fff",
				SvgFilter: "invert(99%) sepia(0%) saturate(2393%) hue-rotate(275deg) brightness(110%) contrast(101%)"
			}
		},
		{
			Name: "Dark theme",
			Id: 1,
			Constants: {
				BodyFontFamily: 'Quicksand',
				Menu: {
					Background: "rgb(0, 0, 0)",
					LogotypeImageUrl: "/assets/logos/Taggr_White-and-Light-Blue-on-Transparent.svg",
					Link: {
						TextColor: "#777",
					}
				},
				ContentBackground: "#333",
				ContentTextColor: "#eee",
				DefaultButtonBackground: "#222",
				DefaultButtonTextColor: "#999",
				DefaultButtonBackgroundHover: "#111",
				DefaultButtonTextColorHover: "#ccc",
				DefaultButtonBackgroundActive: "#111",
				DefaultButtonTextColorActive: "#ddd",
				DefaultButtonFocus: "rgba(101, 101, 101,.25)",
				FormFocus: "rgba(101, 101, 101,.25)",
				MapBackground: "#222",
				MapClusterBackground: "rgba(101, 101, 101, 0.6)",
				MapClusterTextColor: "#222",
				SvgFilter: "invert(99%) sepia(0%) saturate(2393%) hue-rotate(275deg) brightness(110%) contrast(101%)"
			}
		},
		{
			Name: "Ramirent theme",
			Id: 2,
			Constants: {
				BodyFontFamily: 'Quicksand',
				Menu: {
					Background: "rgb(255, 220, 0)",
					// LogotypeImageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Inkscape.logo.svg/390px-Inkscape.logo.svg.png",
					LogotypeImageUrl: "https://www.inderes.fi/sites/default/files/ramirent_logo_black.png",
					Link: {
						TextColor: "#333",
					}
				},
				ContentBackground: "#fff",
				ContentTextColor: "#555",
				DefaultButtonBackground: "rgb(255, 220, 0)",
				DefaultButtonTextColor: "#555",
				DefaultButtonBackgroundHover: "rgb(255, 230, 10)",
				DefaultButtonTextColorHover: "#444",
				DefaultButtonBackgroundActive: "rgb(255, 230, 10)",
				DefaultButtonTextColorActive: "#444",
				DefaultButtonFocus: "rgba(255, 220, 0,.25)",
				FormFocus: "rgba(101, 214, 224,.25)",
				MapBackground: "lightblue",
				MapClusterBackground: "rgba(255, 220, 0, 0.6)",
				MapClusterTextColor: "#555",
				SvgFilter: "invert(99%) sepia(0%) saturate(2393%) hue-rotate(275deg) brightness(110%) contrast(101%)"
			}
		},
		{
			Name: "Boomr theme",
			Id: 3,
			Constants: {
				BodyFontFamily: '"Quicksand',
				Menu: {
					Background: "#fff",
					LogotypeImageUrl: "/assets/logos/boomr.png",
					Link: {
						TextColor: "#263238",
					}
				},
				ContentBackground: "#fff",
				ContentTextColor: "#555",
				ContentLinkColor: "#555",
				ContentLinkColorHover: "#555",
				DefaultButtonBackground: "#263238",
				DefaultButtonTextColor: "#fff",
				DefaultButtonBackgroundHover: "#192125",
				DefaultButtonTextColorHover: "#fff",
				DefaultButtonBackgroundActive: "#131a1d",
				DefaultButtonTextColorActive: "#fff",
				DefaultButtonFocus: "rgba(0,123,255,.25)",
				FormFocus: "rgba(0,123,255,.25)",
				MapBackground: "lightblue",
				MapClusterBackground: "rgba(74, 101, 114, 0.8)",
				MapClusterTextColor: "#fff",
				SvgFilter: "none"
			}
		}
	];

	// global colors?
	// logo image?
	// web page title
	// api url
	// themes
	// default
	// dark
	// high contrast
	// bright
	// avaliable languages
	// default language
	// information and error message duration

	/*********************************************************/
	/*                                                       */
	/*                         CS24                          */
	/*                                                       */
	/*********************************************************/
	//#region CS24

	EncryptKey: string;
	IV: string;
	APIBasePath: string;
	APIBasePathIdentity: string;
	APITypeApplication: string;
	APITypeIdentity: string;
	ValidationMessageKeys: Object;
	defaultLanguageId: number;
	notificationTimeout: number;
	commonAPIErrorMessage: string;
	validationMessages: any;
	APIRequestTimeout: number;
	unauthorizedAccessPage: string;
	windowDimensionParams: Object;
	googleAPIUrl: string;
	defaultLanguage: string;
	// appClientToken: string;
	timeFormat12H: string;
	timeFormat24H: string;
	momentDateFormat: string;
	datePickerFormat: string;
	dateConversionFormatForDatePicker: string;
	// gidColumnHeaderHeight: number;
	maxDays: number;
	minDay: number;
	momentFullDate: string;
	pageSize: number;
	defaultLanguageObj: any;
	companyDetails: any;
	accountDetails: any;

	//#endregion

	isShowingMessage: boolean;
	errorMessageText: string;
	errorMessageHeader: string;

	constructor(
		private localStore: LocalstoreService,
	) {
		var storedThemeId = this.localStore.get('ThemeId');
		if (storedThemeId != null) {
			this.currentThemeId = storedThemeId;
		}
		else {
			this.currentThemeId = this.deafaultColorThemeId;
		}

		this.UpdateCssConstants();

		this.EncryptKey = '##cargo@@l~24~2018##';
		this.IV = 'cargo@@24';
		this.APIBasePath = environment.APIBasePath;
		this.APITypeApplication = 'APPLICATION';
		this.APITypeIdentity = 'IDENTITY';
		this.ValidationMessageKeys = {
			'required': 'Page.Labels.REQUIRED',
			'invalidEmail': 'Invalid email address',
			'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
			'minlength': 'Minimum length',
			'passwordMismatch': 'Passwords do not match',
		};
		this.defaultLanguageId = 1;
		this.notificationTimeout = 12000;
		this.commonAPIErrorMessage = 'Server Error';
		this.APIRequestTimeout = 25000000;;
		this.unauthorizedAccessPage = '/unauthorized-access';
		this.defaultLanguage = 'en';
		// this.appClientToken = '';
		this.timeFormat12H = 'hh:mm tt';
		this.timeFormat24H = 'HH:mm';
		this.momentDateFormat = 'DD/MMM/YYYY';
		this.datePickerFormat = 'dd/MMM/yyyy';
		this.dateConversionFormatForDatePicker = 'YYYY-MM-DD';
		this.momentFullDate = 'DD/MMM/YYYY HH:mm';
		this.datePickerFormat = 'dd/MMM/yyyy';
		this.maxDays = 28;
		this.minDay = 1;
		// this.gidColumnHeaderHeight = 60;
		this.pageSize = 10; // default pagination page size..
		this.defaultLanguageObj = { id: 1, name: 'English', cultureCode: 'en', isRtl: false };


		this.isShowingMessage = false;
		this.errorMessageText = "";
		this.errorMessageHeader = "";
		// tslint:disable-next-line:max-line-length
	}

	public SetTheme(themeId) {
		this.currentThemeId = themeId;
		this.localStore.store("ThemeId", themeId);
		this.UpdateCssConstants();
	}

	public UpdateCssConstants() {
		let root = document.documentElement;

		root.style.setProperty('--trakk-dark-blue', this.colorThemes[this.currentThemeId].Constants.Menu.Background);

		root.style.setProperty('--menu-background', this.colorThemes[this.currentThemeId].Constants.Menu.Background);
		root.style.setProperty('--menu-link-textcolor', this.colorThemes[this.currentThemeId].Constants.Menu.Link.TextColor);

		root.style.setProperty('--content-background', this.colorThemes[this.currentThemeId].Constants.ContentBackground);
		root.style.setProperty('--content-text-color', this.colorThemes[this.currentThemeId].Constants.ContentTextColor);

		root.style.setProperty('--default-button-background', this.colorThemes[this.currentThemeId].Constants.DefaultButtonBackground);
		root.style.setProperty('--default-button-text-color', this.colorThemes[this.currentThemeId].Constants.DefaultButtonTextColor);
		root.style.setProperty('--default-button-background-hover', this.colorThemes[this.currentThemeId].Constants.DefaultButtonBackgroundHover);
		root.style.setProperty('--default-button-text-color-hover', this.colorThemes[this.currentThemeId].Constants.DefaultButtonTextColorHover);
		root.style.setProperty('--default-button-background-active', this.colorThemes[this.currentThemeId].Constants.DefaultButtonBackgroundActive);
		root.style.setProperty('--default-button-text-color-active', this.colorThemes[this.currentThemeId].Constants.DefaultButtonTextColorActive);
		root.style.setProperty('--default-button-focus', this.colorThemes[this.currentThemeId].Constants.DefaultButtonFocus);

		root.style.setProperty('--form-focus', this.colorThemes[this.currentThemeId].Constants.DefaultButtonFocus);

		root.style.setProperty('--map-background', this.colorThemes[this.currentThemeId].Constants.MapBackground);
		root.style.setProperty('--map-cluster-background', this.colorThemes[this.currentThemeId].Constants.MapClusterBackground);
		root.style.setProperty('--map-cluster-text-color', this.colorThemes[this.currentThemeId].Constants.MapClusterTextColor);
		root.style.setProperty('--svg-filter', this.colorThemes[this.currentThemeId].Constants.SvgFilter);

	}
}
