import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtService } from './jwt.service';
import { LocalstoreService } from '../_shared/localstore.service';

/**
 * Guard Service that redirect non-logged in users to Login page
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
		private router: Router,
    private jwtService: JwtService,
  //  private localStore: LocalstoreService
  ) {}

  canActivate(): Promise<boolean> | boolean {

    if (this.jwtService.getToken() !== null){
      return true;
    }
      
    //Clear tokens
    localStorage.clear();

    // User is not logged in = redirect to login page
    this.router.navigate(['/account/login']);
    return false;
  }
}
