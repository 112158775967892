import { Pipe, PipeTransform } from "@angular/core";

/**
 * Takes seconds and converts it to readable format
 * @Input seconds
 * @Output string
 */

@Pipe({
  name: "otDurationPipe2",
})
export class OtDurationPipe2 implements PipeTransform {
  transform(value: number): string {
    const duration = Math.round(value);
    if (duration < 1) return "-";

    const hours: number = Math.floor(duration / 3600);
    const minutes: number = Math.floor((duration % 3600) / 60);
    const seconds: number = duration % 60;

    const hourStr = hours ? `${hours}h ` : "";
    const minuteStr = minutes || hours ? `${minutes}m ` : "";
    const secondStr = seconds || hours || minutes ? `${seconds}s` : "";

    return `${hourStr}${minuteStr}${secondStr}`;
  }
}
