import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leftPad'
})
export class LeftPadPipe implements PipeTransform {

  transform(value: number, size: number, character: string): any {
    if(value == null) { value = 0; }
    if(!isNaN(value))
    {
      if(size == undefined) { size = 6; }
      if(character == undefined) { character = '0'; }
      var s = value+"";
      while (s.length < size) s = character + s;
      return s;
    }
    return value;
  }

}
