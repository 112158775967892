import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable  } from 'rxjs';
import { map  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  private loadingSubject = new BehaviorSubject<number>(0);

  setLoading(loading: boolean) {
    const currentCount = this.loadingSubject.value;
    const updatedCount = loading ? currentCount + 1 : Math.max(currentCount - 1, 0);
    this.loadingSubject.next(updatedCount);
  }

  getLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable().pipe(
      map(count => count > 0)
    );
  }
}
