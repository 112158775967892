import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationService } from './validation.service';


@Component({
  selector: 'app-control-messages',
  template: `<span  #message class="has-error" [hidden]="errorMessage == null">{{errorMessage}}</span>`
})
export class ControlMessages {

  @Input() control: FormControl;
	@Input() messageKeys: Object;
	@ViewChild('message', { static: true }) element;

  constructor(private validation: ValidationService) { }

  get errorMessage() {
		for (const propertyName in this.control.errors) {
			if (this.control.errors.hasOwnProperty(propertyName) && (!this.control.pristine || this.control.touched)) {
				// this.element.nativeElement.closest('.form-group').classList.add("has-error");
				return this.validation.getValidatorErrorMessage(propertyName, this.messageKeys, this.control.errors[propertyName]);
			}
		}
		// this.element.nativeElement.closest('.form-group').classList.remove("has-error");
		return null;
	}



}
