import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: 'app-usermanual',
  templateUrl: './usermanual.component.html',
  styleUrls: ['./usermanual.component.scss']

})


export class UsermanualComponent implements OnInit, OnDestroy {

  isActive: boolean = false

  // A dom element to apply scrollspy on
  DOM_ELEMENT: any = '#usermanual-container'

  constructor(public translate: TranslateService) {

  }

  ngOnDestroy() {

    this.disposeScroll()

    // Reset the padding of main section
    this.setMainSectionPadding("2rem")

  }

  ngOnInit(): void {

    // Set the padding of main section to make the scroll of div is the scroll of the whole page
    this.setMainSectionPadding("0");

    // launch scrollSpy
    this.activateScroll()

    // identifiy if scrollspy active
    this.isScrollSpyActive()

  }


  // activate scrollspy
  activateScroll() {
    (<any>jQuery(this.DOM_ELEMENT)).scrollspy({ target: '#sidebar-navigation', offset: 100 })
  }

  // dispose scrollspy
  disposeScroll() {
    (<any>jQuery(this.DOM_ELEMENT)).scrollspy('dispose')

  }

  // Adjust the padding of the  *main parent section to allow usermanual container to expand to whole page width
  // *main section exists in App Component 
  setMainSectionPadding(value: string) {

    let mainElement = document.getElementById("main-section")

    mainElement.style.padding = value;

  }

  //A click handler to scroll to manuall section by its ID
  scrollTo(id) {

    if (this.isActive == false) {

      this.disposeScroll()

      this.activateScroll()

      this.isScrollSpyActive()

    }

    let element = document.getElementById(id);

    if (element) {

      element.scrollIntoView(); // scroll to a particular element

    }

    return false

  }
  // Indicate if scroll spy active 

  isScrollSpyActive() {

    if (typeof jQuery != 'undefined') {

      // listen to scrollspy activate event to indicate if it is active 
      <any>jQuery(this.DOM_ELEMENT).on('activate.bs.scrollspy', function () {

        this.isActive = true

      }.bind(this))

    }

  }

}




