<div id="scheduleEditor">
  <div class="headers noselect">
    <div>
      <h5>{{ 'Page.Labels.TIME' | translate }}</h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.MONDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.MON' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.TUESDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.TUE' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.WEDNESDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.WED' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.THURSDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.THU' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.FRIDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.FRI' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.SATURDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.SAT' | translate }}</span> </h5>
    </div>
    <div>
      <h5><span class="expansion">{{ 'Page.Labels.SUNDAY' | translate }}</span><span class="abbreviation">{{ 'Page.Labels.SUN' | translate }}</span> </h5>
    </div>
  </div>
  <div class="schedule">
    <div>
      <div *ngFor="let h of hours" class="H{{ h }}">{{ h | time }}</div>
      <div>{{ 0 | time }}</div>
    </div>
    <div *ngFor="let day of days" (click)="StartCreateEvent(day.name, $event)">
      <ng-container *ngFor="let e of schedule?.Entries">
        <div class="H{{e.StartTime.Hour}} event" *ngIf="e.Day == day.name" [style.margin-top.px]="GetMarginTop(e)" [style.height.px]="GetHeight(e)" [class.smalEvent]="GetHeight(e) < 140" [class.miniEvent]="GetHeight(e) < 80" [class.microEvent]="GetHeight(e) < 40" [class.overlapping]="e.overlapping" (click)="StartEditEvent($event, e);">
          <div class="deleteEvent"><a (click)="DeleteEvent(e, true, $event);"><img src="assets/icons/svg/delete.svg" class="svg"></a></div>
          <div class="eventInfo">
            <h5><span>{{ e.StartTime.Hour | number:'2.0' }}:{{e.StartTime.Minute | number:'2.0' }}</span> -
              <span>{{ e.StopTime.Hour | number:'2.0' }}:{{ e.StopTime.Minute | number:'2.0' }}</span>
            </h5>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modalWrapper" [class.visible]="modal!=null" (click)="CancelModal()">

  <div class="modal" [class.visible]="modal == 'editEvent' || modal == 'createEvent'" (click)="$event.stopPropagation()">
    <h2 *ngIf="modal == 'editEvent'">{{ 'Page.Labels.EDIT_EVENT' | translate }}</h2>
    <h2 *ngIf="modal == 'createEvent'">{{ 'Page.Labels.CREATE_EVENT' | translate }}</h2>
    <ng-container>
      <div *ngIf="newEntryForm" [formGroup]="newEntryForm" (ngSubmit)="SaveEvent()" class="modalForm">
        <h6>{{ 'Page.Labels.ACTIVE_DAYS' | translate }}</h6>
        <div *ngFor="let day of days" class="form-check form-check-inline">
          <div>
            <!-- <input type="checkbox" class="dayCheckbox" class="form-control" formControlName="ActiveDays" [class.error]="weekValid == false" type="checkbox" [checked]="day.checked" (change)="checkDay(day)"> -->
            <input type="checkbox" class="dayCheckbox" class="form-control" formControlName="Id" [class.error]="weekValid == false" type="checkbox" [checked]="day.checked" (change)="checkDay(day)">
            <label for="checkBox{{day}}" class="noselect">{{ 'Page.Labels.'+ ( day.name | uppercase ) | translate }}</label>
          </div>
        </div>
        <div class="twoCols">
          <div>
            <h6>{{ 'Page.Labels.START_TIME' | translate }}</h6>
            <input class="form-control" type="time" [class.error]="newEntryForm.controls['StartTime'].errors" formControlName="StartTime" [value]="newEntryForm.controls['StartTime'].value" (change)="setTime($event)">
          </div>
          <div>
            <h6>{{ 'Page.Labels.END_TIME' | translate }}</h6>
            <input style="margin-bottom: 0; padding-bottom: 0;" class="form-control" type="time" formControlName="StopTime" [class.error]="newEntryForm.controls['StopTime'].errors" [value]="newEntryForm.controls['StopTime'].value" (change)="setTime($event)">
          </div>
          <div class="overlap-error">
            <p *ngIf="newEntryForm.controls['Id']?.errors?.overlaps">{{ 'MESSAGE.OVERLAPPING_SCHEDULEENTRY' | translate }}</p>
          </div>
        </div>
      </div>
    </ng-container>
    <button class="btn" [disabled]="newEntryForm.controls['StartTime'].errors || newEntryForm.controls['StopTime'].errors" (click)="SaveEvent()">{{ 'Page.Buttons.SAVE' | translate }}</button>
    <button class="btn" *ngIf="modal == 'editEvent'" (click)="DeleteEvent(null, true, $event)">{{ 'Page.Buttons.DELETE' | translate }}</button>

    <button class="btn" (click)="CancelModal()">{{ 'Page.Buttons.CANCEL' | translate }}</button>
  </div>

</div>