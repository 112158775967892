import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonService } from "./../_services/common.service";
import { ControlMessages } from "./control-messages.component";
import { Constants } from "./../app.config";
import { LocalstoreService } from "./localstore.service";
import { LanguageService } from "../_services/language.service";
import { AuthGuardService } from "../_services/auth-guard.service";
import { ValidationService } from "./validation.service";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionConfig } from "ngx-bootstrap/accordion";

import { ShortenPipe } from "../_pipes/shorten.pipe";
import { SortPipe } from "../_pipes/sort.pipe";
import { Sort2Pipe } from "../_pipes/sort2.pipe";
import { FilterPipe } from "../_pipes/filter.pipe";
import { LeftPadPipe } from "../_pipes/left-pad.pipe";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FilterTrakkerPipe } from "../_pipes/filterTrakker.pipe";
import { FilterGeofencePipe } from "../_pipes/filterGeofence.pipe";

@NgModule({
  declarations: [
    ControlMessages,
    FilterPipe,
    ShortenPipe,
    SortPipe,
    Sort2Pipe,
    DatepickerComponent,
    BatteryLevelComponent,
    EventTypeComponent,
    HeadingComponent,
    AddressComponent,
    DateTimeComponent,
    IconpickerComponent,
    ScheduleEditorComponent,
    ColorIconComponent,
    SpeedPipe,
    TimePipe,
    StarComponent,
    ErrorPopupComponent,
    IsSOSPipe,
    AgoPipe,
    StringFormatPipe,
    DurationPipe,
    EventDurationPipe,
    OtDurationPipe,
    OtDurationPipe2,
    OtDurationPipe3,
    AdditionalDaysPipe,
    TranslateAssignmentPipe,
    LeftPadPipe,
    AlertTypeComponent,
    PositionMapComponent,
    FilterTrakkerPipe,
    FilterGeofencePipe,
    FilterSubscriptionPipe,
    ObjectPickerComponent,
    OperationTimeComponent,
    SheduleEntryDirective,
    InfoBoxComponent,
    SiteUnderMaintenanceComponent,
    BoomrRedirectComponent,
    SpinnerComponent,
  ],
  imports: [CommonModule, TranslateModule, BsDatepickerModule.forRoot(), NgbModule],
  exports: [
    CommonModule,
    ControlMessages,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule,
    FilterPipe,
    ShortenPipe,
    SortPipe,
    Sort2Pipe,
    LeftPadPipe,
    BsDatepickerModule,
    // CamelcaseToStringspacePipe
    BatteryLevelComponent,
    EventTypeComponent,
    HeadingComponent,
    AddressComponent,
    DateTimeComponent,
    IconpickerComponent,
    ScheduleEditorComponent,
    ColorIconComponent,
    SpeedPipe,
    TimePipe,
    StarComponent,
    ErrorPopupComponent,
    AgoPipe,
    StringFormatPipe,
    DurationPipe,
    EventDurationPipe,
    OtDurationPipe,
    OtDurationPipe2,
    OtDurationPipe3,
    AdditionalDaysPipe,
    TranslateAssignmentPipe,
    IsSOSPipe,
    NgbModule,
    PositionMapComponent,
    FilterTrakkerPipe,
    FilterGeofencePipe,
    FilterSubscriptionPipe,
    ObjectPickerComponent,
    OperationTimeComponent,
    InfoBoxComponent,
    SpinnerComponent,
  ],
  providers: [
    Constants,

    // NotifyService,
    CommonService,
    LocalstoreService,
    // CryptoService,
    LanguageService,
    AuthGuardService,
    ValidationService,
    AccordionConfig,
    // MapHelper
  ],
})
export class SharedModule {}

import { DatepickerComponent } from "./datepicker/datepicker.component";
import { BatteryLevelComponent } from "./battery-level/battery-level.component";
import { EventTypeComponent } from "./event-type/event-type.component";
import { HeadingComponent } from "./heading/heading.component";
import { AddressComponent } from "./address/address.component";
import { DateTimeComponent } from "./date-time/date-time.component";
import { IconpickerComponent } from "./iconpicker/iconpicker.component";
import { ScheduleEditorComponent } from "./schedule-editor/schedule-editor.component";
import { ColorIconComponent } from "./color-icon/color-icon.component";
import { SpeedPipe } from "../_pipes/speed.pipe";
import { TimePipe } from "../_pipes/time.pipe";
import { StarComponent } from "./star/star.component";
import { ErrorPopupComponent } from "./error-popup/error-popup.component";
import { IsSOSPipe } from "../_pipes/is-sos.pipe";
import { AlertTypeComponent } from "./alert-type/alert-type.component";
import { AgoPipe } from "../_pipes/ago.pipe";
import { DurationPipe } from "../_pipes/duration.pipe";
import { EventDurationPipe } from "../_pipes/event-duration.pipe";
import { OtDurationPipe } from "../_pipes/ot-duration.pipe";
import { AdditionalDaysPipe } from "../_pipes/additional-days.pipe";
import { StringFormatPipe } from "../_pipes/string-format.pipe";
import { PositionMapComponent } from "./position-map/position-map.component";
import { ObjectPickerComponent } from "./object-picker/object-picker.component";
import { OperationTimeComponent } from "./operation-time/operation-time.component";
import { SheduleEntryDirective } from "./schedule-editor/shedule-entry.directive";
import { InfoBoxComponent } from "./info-box/info-box.component";
import { OtDurationPipe2 } from "../_pipes/otDuration2.pipe";
import { OtDurationPipe3 } from "../_pipes/otDuration3.pipe ";
import { TranslateAssignmentPipe } from "../_pipes/translateAssignment.pipe";
import { SiteUnderMaintenanceComponent } from './site-under-maintenance/site-under-maintenance.component';
import { BoomrRedirectComponent } from './boomr-redirect/boomr-redirect.component';
import { FilterSubscriptionPipe } from "../_pipes/filterSubscription.pipe";
import { SpinnerComponent } from "../spinner/spinner.component";

