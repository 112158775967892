import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speed'
})
export class SpeedPipe implements PipeTransform {

  transform(value: any, speedUnit: any, includeUnit: boolean = false): any {
    if(value == null)
      return "N/A";

    var returnValue = value;

    if(speedUnit=="mph")
      returnValue = Math.round(value * 0.6213711922 * 10) / 10;

    if(speedUnit=="knot")
      returnValue = Math.round(value * 0.5399568035 * 10) / 10;

    if(includeUnit == true){
      switch(speedUnit){
        case 'mph':
          return returnValue + " mph";
        case 'kmh':
            return returnValue + " km/h";
        case 'knot':
            return returnValue + " knot";
      }
    }
      
    return returnValue;
  }

}
