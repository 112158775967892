import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'additionalDays'
})
export class AdditionalDaysPipe implements PipeTransform {

  transform(event: any, translations: any[] = [], FromDateTime: any): any {

    // POSITIONS
    if (FromDateTime != null && event.hasOwnProperty("OccuredOn") && event.OccuredOn != null) {

      var startUtcDateTime = FromDateTime.toUpperCase();
      if(startUtcDateTime[startUtcDateTime.length-1] != "Z")
      startUtcDateTime+="Z";

      var realStartDateTime = new Date(Date.parse(startUtcDateTime));
      var StopUtcDateTime = event.OccuredOn.toUpperCase();
      if(StopUtcDateTime) {
        StopUtcDateTime = StopUtcDateTime.toUpperCase();
        if(StopUtcDateTime[StopUtcDateTime.length-1] != "Z")
        StopUtcDateTime+="Z";

        var realStopDateTime = new Date(Date.parse(StopUtcDateTime));

        var seconds = Math.floor((new Date(realStopDateTime).getTime() - realStartDateTime.getTime()) / 1000);
        var days = Math.floor(seconds / 86400);

        var remainingSeconds = seconds - days*86400;
        var testDate = new Date(realStartDateTime.getTime() + remainingSeconds*1000);

        //check if start time of day < stop time of day then add one day
        if(testDate.getDate() != realStartDateTime.getDate()){
          days++;
        }

        if(days == 0)
          return "";

        if(days == 1){
          return "(+1 " + translations.find(t=>t.key=="DAY").value + ")";
        }

        return "(+" + days + " " + translations.find(t=>t.key=="DAYS").value + ")";
      }

    }

    // VEHICLE EVENT
    if(event.hasOwnProperty("FromDateTime") && event.FromDateTime != null &&
    event.hasOwnProperty("ToDateTime") && event.ToDateTime != null)  {
        var startUtcDateTime = event.FromDateTime.toUpperCase();
        if(startUtcDateTime[startUtcDateTime.length-1] != "Z")
        startUtcDateTime+="Z";

      var realStartDateTime = new Date(Date.parse(startUtcDateTime));
      var StopUtcDateTime = event.ToDateTime.toUpperCase();
      if(StopUtcDateTime) {
        StopUtcDateTime = StopUtcDateTime.toUpperCase();
        if(StopUtcDateTime[StopUtcDateTime.length-1] != "Z")
        StopUtcDateTime+="Z";

        var realStopDateTime = new Date(Date.parse(StopUtcDateTime));

        var seconds = Math.floor((new Date(realStopDateTime).getTime() - realStartDateTime.getTime()) / 1000);
        var days = Math.floor(seconds / 86400);

        var remainingSeconds = seconds - days*86400;
        var testDate = new Date(realStartDateTime.getTime() + remainingSeconds*1000);

        //check if start time of day < stop time of day then add one day
        if(testDate.getDate() != realStartDateTime.getDate()){
          days++;
        }

        if(days == 0)
          return "";

        if(days == 1){
          return "(+1 " + translations.find(t=>t.key=="DAY").value + ")";
        }

        return "(+" + days + " " + translations.find(t=>t.key=="DAYS").value + ")";
      }
    }


    return "";
  }
}
