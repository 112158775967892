import { Directive } from '@angular/core';

@Directive({
  selector: '[appSheduleEntry]'
})
export class SheduleEntryDirective {

  constructor() { }

}
