import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Day, Schedule, ScheduleEntry, TimeInput } from "src/app/_models/schedule.model";
import { ScheduleService } from "src/app/_services/schedule.service";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

const defaultStarTime = new TimeInput("07", "00");
const defaultStopTime = new TimeInput("16", "00");
@Component({
  selector: "scheduleEditor",
  templateUrl: "./schedule-editor.component.html",
  styleUrls: ["./schedule-editor.component.scss"],
})
export class ScheduleEditorComponent implements OnInit, OnDestroy {
  @Input() id: number;

  schedule: Schedule;
  newEntryForm: FormGroup;
  days: any;
  hours: number[] = Array.from(Array(24).keys());

  modal: any = null;
  subscriptions: any = [];

  constructor(
    private scheduleService: ScheduleService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.scheduleService.getSchedule(this.id).subscribe((res) => {
        this.schedule = res;
        this.cdr.detectChanges();
      })
    );

    this.days = Object.values(Day).map((d) => {
      return { name: d, checked: false };
    });

    this.newEntryForm = this.formBuilder.group({
      Id: new FormControl(-1),
      StartTime: new FormControl(defaultStarTime),
      StopTime: new FormControl(defaultStopTime),
    });
    this.newEntryForm.setValidators(this.timeValidator());
    this.subscriptions.push(
      this.newEntryForm.valueChanges.subscribe((x) => {
        this.clearOverlapError();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  SaveEvent() {
    if (!this.schedule) {
      return;
    }
    const days = this.days.filter((d) => d.checked).map((d) => d.name);
    const entries = days.map((d) => {
      return { ...this.newEntryForm.value, Day: d };
    });

    const control = this.newEntryForm.controls["Id"];
    control.setErrors(this.checkOverlaps(entries) ? { overlaps: this.checkOverlaps(entries) } : null);
    if (!this.newEntryForm.valid) {
      return;
    }

    if (entries[0]?.Id >= 0) {
      this.DeleteEvent(null, false, null);
    }

    entries.forEach((e) => {
      this.subscriptions.push(
        this.scheduleService.createScheduleEntry(this.id, e).subscribe((res) => {
          //this.schedule.Entries.push(res);
          if (res && res.Id) {
            this.schedule.Entries.push(res);
          }

          this.cdr.detectChanges();
        })
      );
    });

    this.CancelModal();
  }

  DeleteEvent(event, closeModal, mouseevent) {
    this.preventMouseEvents(mouseevent);

    const { Id } = event ? event : this.newEntryForm.value;

    this.subscriptions.push(
      this.scheduleService.deleteScheduleEntry(this.id, Id).subscribe((res) => {
        const index = this.schedule.Entries.findIndex((e) => e.Id === Id);
        this.schedule.Entries.splice(index, 1);
        this.cdr.detectChanges();
      })
    );

    if (closeModal) {
      this.CancelModal();
    }
  }

  checkOverlaps(entries: ScheduleEntry[]) {
    let overlaps = false;
    const newSchedule = JSON.parse(JSON.stringify(this.schedule));

    if (entries[0].Id >= 0) {
      newSchedule.Entries = newSchedule.Entries.map((e) => (e.Id != entries[0].Id ? e : null)).filter((x) => x != null);
    }

    entries.forEach((e) => {
      newSchedule.Entries.push({ ...e });
    });

    let days = Object.keys(Day).map((d) => {
      return newSchedule.Entries.filter((e) => e.Day == d);
    });

    days.forEach((d) => {
      d.sort((a, b) =>
        a.StartTime.Hour * 60 + a.StartTime.Minute < b.StartTime.Hour * 60 + b.StartTime.Minute ? 0 : 1
      );
      for (let i = 1; i < d.length; i++) {
        if (
          d[i - 1].StopTime.Hour * 60 + d[i - 1].StopTime.Minute >=
          d[i].StartTime.Hour * 60 + d[i].StartTime.Minute
        ) {
          overlaps = true;
        }
      }
    });

    return overlaps;
  }

  GetClass(event) {
    var h = event.startTime.split(":")[0];
    console.log("H" + h);
    return "H" + h;
  }

  GetMarginTop(event) {
    return (event.StartTime.Minute + 30) * 0.5;
  }

  GetHeight(event) {
    var h = (event.StopTime.Hour - event.StartTime.Hour) * 60;
    var m = h + event.StopTime.Minute - event.StartTime.Minute;
    var hours = Math.floor(m / 60);
    var minutes = m % 60;
    var duration = hours + minutes / 60;
    return duration * 30;
  }

  Duration(event) {
    var h = (event.StopTime.Hour - event.StartTime.Hour) * 60;
    var m = h + event.StopTime.Minute - event.StartTime.Minute;
    var hours = Math.floor(m / 60);
    var minutes = m % 60;

    var durationMinutes = hours * 60 + minutes;

    return durationMinutes / 60;
  }

  StartEditEvent(mouseevent, event) {
    this.preventMouseEvents(mouseevent);
    this.days.map((d) => {
      d.checked = d.name === event.Day;
    });

    this.newEntryForm.patchValue({
      Id: event.Id,
      StartTime: new TimeInput(event.StartTime.Hour, event.StartTime.Minute),
      StopTime: new TimeInput(event.StopTime.Hour, event.StopTime.Minute),
    });

    this.modal = "editEvent";
  }
  StartCreateEvent(day, mouseevent) {
    this.preventMouseEvents(mouseevent);
    this.days.map((d) => {
      d.checked = d.name === day;
    });
    this.modal = "createEvent";
  }

  checkDay(day) {
    this.days.find((d) => d.name == day.name).checked = !day.checked;
    this.clearOverlapError();
  }

  setTime = (event) => {
    let value = event?.target?.value?.toString()?.split(":") || "";
    let target = event.target.getAttribute("formControlName");
    this.newEntryForm.patchValue({
      [target]: new TimeInput(value[0] || "0", value[1] || "0"),
    });
  };

  public timeValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls["StartTime"];
      const control2 = group.controls["StopTime"];
      const startTime = control1?.value;
      const stopTime = control2?.value;
      if (startTime.Hour * 60 + startTime.Minute >= stopTime.Hour * 60 + stopTime.Minute) {
        control2.setErrors({ isBefore: true });
      }
      return;
    };
  }

  clearOverlapError() {
    const control = this.newEntryForm.controls["Id"];
    control.setErrors(null);
  }

  clearEntryForm(): void {
    this.days.map((d) => (d.checked = false));
    this.newEntryForm.setValue({
      Id: -1,
      StartTime: defaultStarTime,
      StopTime: defaultStopTime,
    });
  }

  CancelModal() {
    this.modal = null;
    this.clearEntryForm();
  }

  preventMouseEvents(mouseevent) {
    if (mouseevent?.preventDefault) {
      mouseevent.preventDefault();
    }
    if (mouseevent?.stopPropagation) {
      mouseevent.stopPropagation();
    }
  }
}
