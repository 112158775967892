import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTrakker'
  // This next option lets all pipes be reloaded on any page change
  // It requires a lot of performance
  // Use to have live added objects added in list even if you are in the filter and doesn't make a change
  // pure: false
})


export class FilterTrakkerPipe implements PipeTransform {
  transform(items: any[], searchTrakkers: string): any[] {
    searchTrakkers = searchTrakkers ? searchTrakkers.toLocaleLowerCase() : null;
    return searchTrakkers ? items.filter((item: any) =>
      item.Name.toLocaleLowerCase().indexOf(searchTrakkers) !== -1 ||
      (item.Hardware && item.Hardware.GlobalDeviceId && item.Hardware.GlobalDeviceId.toLocaleLowerCase().indexOf(searchTrakkers) !== -1) ||
      (item.Vehicles.length > 0 && item.Vehicles[0].VehicleRegistrationNumber && item.Vehicles[0].VehicleRegistrationNumber.toLocaleLowerCase().indexOf(searchTrakkers) !== -1) ||
      (item.EquipmentNumber!= null && item.EquipmentNumber.toLocaleLowerCase().indexOf(searchTrakkers) !== -1)
    ) : items;

  }
}