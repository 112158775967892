<div id="usermanual-container" class="usermanual-container" appScrollSpy  [lang]="translate.currentLang" [activeElementId]="'#introduction'"
    style="position:relative; height:100%; overflow-y:scroll;">

    <!-- Side bar start -->
    <div class="sidebar-container">
        <ul id="sidebar-navigation" class="nav">
            <li class="nav-item">
                <a class="nav-link" href="#introduction" (click)="scrollTo('introduction')">
                    <i class="fa fa-home" aria-hidden="true"></i> {{ 'Page.UserManual.UserManual_Introduction_Menu' |
                    translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#trips" (click)="scrollTo('trips')">
                    <i class="fa fa-tachometer" aria-hidden="true"></i> {{ 'Page.UserManual.UserManual_Trips_Menu' |
                    translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#settings" (click)="scrollTo('settings')">
                    <i class="fa fa-users" aria-hidden="true"></i> {{ 'Page.UserManual.UserManual_Settings_Menu' |
                    translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#map-geofence" (click)="scrollTo('map-geofence')">
                    <i class="fa fa-cog" aria-hidden="true"></i> {{ 'Page.UserManual.UserManual_Map_Geofence_Menu' |
                    translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#information" (click)="scrollTo('information')">
                    <i class="fa fa-info-circle" aria-hidden="true"></i> {{
                    'Page.UserManual.UserManual_Information_Menu' | translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#app" (click)="scrollTo('app')">
                    <i class="fa fa-info-circle" aria-hidden="true"></i> {{ 'Page.UserManual.UserManual_App_Menu' |
                    translate }}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#installation-guide" (click)="scrollTo('installation-guide')">
                    <i class="fa fa-info-circle" aria-hidden="true"></i> {{
                    'Page.UserManual.UserManual_Installation_Guide_Menu' | translate }}
                </a>
            </li>
        </ul>
    </div>
    <!-- Side bar end -->

    <div class="content-container">
        <div class="container-fluid">

            <!-- Content text start -->

            <div class="col-md-9 mx-auto">

                <h1 [translate]>Page.UserManual.UserManual_Usermanual-h1</h1>

                <div class="continer" id="introduction">
                    <div class="introduction container-col-5">
                        <h3 [translate]>Page.UserManual.UserManual_Introduction-h3</h3>
                        <p [translate]>Page.UserManual.UserManual_Introduction-p</p>
                    </div>
                    <section class="introduction-img">
                        <img src="assets/usermanual/taggrApp-dashboard.png" class="img-fluid" alt="introduction">
                    </section>
                </div>

                <div class="continer" id="trips">
                    <div class="trips container-col-5">
                        <h3 [translate]>Page.UserManual.UserManual_Trips-h3</h3>
                        <p [translate]>Page.UserManual.UserManual_Trips-p</p>
                    </div>
                    <section class="trips-img">
                        <img src="assets/usermanual/taggrApp-reports.png" class="img-fluid" alt="trips">
                    </section>
                </div>

                <div class="continer" id="settings">
                    <div class="row">
                        <div class="settings container-col-5">
                            <h3 id="settings" [translate]>Page.UserManual.UserManual_Settings-h3</h3>
                            <p [translate]>Page.UserManual.UserManual_Settings-p</p>
                        </div>
                        <section class="settings-img col-6">
                            <img src="assets/usermanual/taggrApp-unitDetails.png" class="img-fluid" alt="settings">
                        </section>
                    </div>
                </div>

                <div class="continer" id="geofence">
                    <div class="row">
                        <div class="map-geofence container-col-5">
                            <h3 id="map-geofence" [translate]>Page.UserManual.UserManual_Map-Geofence-h3</h3>
                            <p [translate]>Page.UserManual.UserManual_Map-Geofence-p</p>

                            <h3 id="information" [translate]>Page.UserManual.UserManual_Information-h3</h3>
                            <p [translate]>Page.UserManual.UserManual_Information-p</p>
                        </div>
                        <section class="map-geofence-img col-6">
                            <img src="assets/usermanual/taggrApp-map.png" class="img-fluid" alt="geofence">
                        </section>
                    </div>
                </div>

                <div class="continer" id="app">
                    <div class="row">
                        <section class="app-img col-3">
                            <img src="assets/usermanual/app.png" class="img-fluid" alt="app">
                        </section>
                        <div class="app container-col-8">
                            <h3 [translate]>Page.UserManual.UserManual_App-h3</h3>
                            <p [translate]>Page.UserManual.UserManual_App-p</p>
                        </div>



                    </div>
                </div>

                <div class="continer" id="installation-guide">
                    <h3 [translate]>Page.UserManual.UserManual_TRAKK-Unit-h3</h3>

                    <h5 [translate]>Page.UserManual.UserManual_TRAKK-Unit-1-h5</h5>
                    <p [translate]>Page.UserManual.UserManual_TRAKK-Unit-1-p</p>

                    <h5 [translate]>Page.UserManual.UserManual_TRAKK-Unit-2-h5</h5>
                    <p [translate]>Page.UserManual.UserManual_TRAKK-Unit-2-p</p>

                    <h5 [translate]>Page.UserManual.UserManual_TRAKK-Unit-3-h5</h5>
                    <p [translate]>Page.UserManual.UserManual_TRAKK-Unit-3-p</p>

                    <h5 [translate]>Page.UserManual.UserManual_TRAKK-Unit-4-h5</h5>
                    <p [translate]>Page.UserManual.UserManual_TRAKK-Unit-4-p</p>
                </div>
                <!-- Content text end -->
            </div>

        </div>

    </div>
</div>