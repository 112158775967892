import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { ApiService } from "./_services/api.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavComponent } from "./nav/nav.component";
import { AboutComponent } from "./about/about.component";
import { PreloaderComponent } from "./_shared/preloader/preloader.component";
import { UnauthorizedAccessComponent } from "./_shared/unauthorized-access/unauthorized-access.component";
import { DesktopComponent } from "./desktop/desktop.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SharedModule } from "./_shared/shared.module";
import { HttpTokenInterceptor } from "./_helpers/http.token.interceptor";
import { UsermanualComponent } from "./usermanual/usermanual.component";

import { AppMaterialModule } from "./_modules/app-material/app-material.module";

// SERVICES
import { NavService } from "./_services/nav.service";

// 3RD PARTY MODULES
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AccordionModule } from "ngx-bootstrap/accordion";

import { CountUpModule } from "ngx-countup";
import { AssetsComponent } from "./assets/assets.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatDatepickerModule } from "@angular/material/datepicker"; // import required for datepicker
import { MatNativeDateModule } from "@angular/material/core";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";

import { MatMomentDateModule } from "@angular/material-moment-adapter"; // import for date formating adapted to more locales(optional)
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";

import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatListModule } from "@angular/material/list";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { MainNavComponent } from "./main-nav/main-nav.component";
import { LayoutModule } from "@angular/cdk/layout";
import { RedirectComponent } from "./redirect/redirect.component";
import { JwtService } from "./_services/jwt.service";
import { ScrollSpyDirective } from './usermanual/scroll-spy.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from "./loading.interceptor";
import { QrHardwareInfoComponent } from './qr-hardware-info/qr-hardware-info.component';
import { DashboardModule } from "./dashboard/dashboard.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const MaterialComponents = [
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatMomentDateModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatExpansionModule,
  MatButtonModule,
  MatSelectModule,
  MatMenuModule,
  MatSlideToggleModule,
];

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    AboutComponent,
    PreloaderComponent,
    UnauthorizedAccessComponent,
    DesktopComponent,
    //DashboardComponent,
    AssetsComponent,
    MainNavComponent,
    RedirectComponent,
    UsermanualComponent,
    ScrollSpyDirective,
  //  SpinnerComponent,
    QrHardwareInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    DashboardModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatTabsModule,
    CountUpModule,
    AccordionModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    BrowserAnimationsModule,
    LayoutModule,
    MaterialComponents,
  ],
  exports: [ScrollSpyDirective],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    JwtService,
    ApiService,
    NavService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
