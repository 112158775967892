<header>
  <div class="container">
    <a routerLink="/" class="logo"><img src="/assets/logos/text-logo-transp-white.png"></a>

    <nav>
      <ul class="topmenu">
        <!-- <li>{{ companyInfo.Name }}</li> -->

        <li>

            <!-- <select class="dropp" *ngIf="accountDetails!=null">
              <option class="dropption" routerLink="/dashboard" routerLinkActive="alink" selected [translate]>Page.Labels.DASHBOARD</option> -->
              <!-- <option class="dropption" routerLink="/about" routerLinkActive="alink" [translate]>Page.Labels.ABOUT</option> -->
              <!-- <option class="dropption" routerLink="/login" routerLinkActive="alink" [translate]>Login</option> -->
              <!-- <option class="dropption" routerLink="/profile" routerLinkActive="alink" [translate]>Page.Labels.PROFILE</option>
              <option class="dropption" routerLink="/vehicles" routerLinkActive="alink" [translate]>Page.Labels.VEHICLES</option>
              <option class="dropption" routerLink="/trakkers" routerLinkActive="alink" [translate]>Page.Labels.TRAKKERS</option>
              <option class="dropption" routerLink="/map" routerLinkActive="alink" [translate]>Page.Labels.MAP</option>
              <option class="dropption" routerLink="/assets" routerLinkActive="alink" [translate]>Page.Labels.ASSETS</option>
              <option class="dropption incomplete" routerLink="/users" routerLinkActive="alink" [translate]>Page.Labels.ACCOUNTS</option>
              <option class="dropption incomplete" routerLink="/groups" routerLinkActive="alink" [translate]>Page.Labels.GROUPS</option>
              <option class="dropption" routerLink="/contacts" routerLinkActive="alink" [translate]>Page.Labels.CONTACTS</option>
              <option class="dropption" routerLink="/drivers" routerLinkActive="alink" [translate]>Page.Labels.DRIVERS</option>
            </select>-->


            <!-- <div class="dropdown">
                <button class="dropbtn">Please Select From List</button>
             <ul class="dropdown-menu dropdown-content">
               <li><a tabindex="-1" routerLink="/dashboard" routerLinkActive="alink">Dash</a></li>
               <li><a tabindex="-1" routerLink="/about" routerLinkActive="alink">About</a></li>
               <li><a tabindex="-1" routerLink="/login" routerLinkActive="alink">Login</a></li>
               <li class="divider"></li>
               <li><a tabindex="-1" href="#">Other</a></li>
             </ul>
            </div> -->


          <!--<div class="dropdown">
            <button class="dropbtn">Drop it!</button>
            <div class="dropdown-content">
              <a routerLink="/" routerLinkActive="alink">Home</a>
              <a routerLink="/about" routerLinkActive="alink">About</a>
              <a routerLink="/login" routerLinkActive="alink">Login</a>
              <a routerLink="/profile" routerLinkActive="alink">Profile</a>
              <a routerLink="/register" routerLinkActive="alink">Register</a>
              <a routerLink="/reset-password" routerLinkActive="alink">Reset Password</a>
              <a routerLink="/forgot-password" routerLinkActive="alink">Forgot Password</a>
              <a routerLink="/confirm-email" routerLinkActive="alink">Confirm Email</a>
            </div>
          </div>-->

        </li>
        <!-- <li><a routerLink="/" routerLinkActive="alink">Home</a></li> -->
        <li><a *ngIf="isLoggedIn" routerLink="/dashboard" routerLinkActive="alink" [translate]>Page.Labels.DASHBOARD</a></li>
        <li><a *ngIf="isLoggedIn" routerLink="/map" routerLinkActive="alink" [translate]>Page.Labels.MAP</a></li>
        <li><a *ngIf="isLoggedIn" routerLink="/trakkers" routerLinkActive="alink" [translate]>Page.Labels.TRAKKERS</a></li>
        <li><a *ngIf="isLoggedIn" routerLink="/reports" routerLinkActive="alink" [translate]>Page.Labels.REPORTS</a></li>
        <li><a *ngIf="isLoggedIn" routerLink="account/profile" routerLinkActive="alink" [translate]>Page.Labels.PROFILE</a></li>

        <li><a routerLink="/about" routerLinkActive="alink" [translate]>Page.Buttons.ABOUT</a></li>
        <li><a *ngIf="!isLoggedIn" routerLink="account/login" routerLinkActive="alink" [translate]>Page.Buttons.LOGIN</a></li>
        <li><a *ngIf="!isLoggedIn" routerLink="account/register" routerLinkActive="alink" [translate]>Page.Buttons.REGISTER</a></li>
        <li><a *ngIf="isLoggedIn" routerLink="account/login" routerLinkActive="alink" (click)="logout()" title="{{'Page.Labels.LOGOUT' | translate}}" [translate]>Page.Buttons.LOGOUT</a></li>
        <!-- <i class="fa fa-sign-out"></i><div class="linktext"><div>Log</div><div>Out</div></div> -->



        <!-- <li><a routerLink="/reset-password" routerLinkActive="alink">Reset Password</a></li> -->
        <!-- <li><a routerLink="/forgot-password" routerLinkActive="alink">Forgot Password</a></li> -->
        <!-- <li><a routerLink="/confirm-email" routerLinkActive="alink">confirm email</a></li> -->
      </ul>
      <div class="lang">
        <button class="flag" (click)="switchLanguage('en')" [ngClass]="{'active': language === 'English'}" ><img src="/assets/flags/uk.png"></button>
        <button class="flag" (click)="switchLanguage('sv')" [ngClass]="{'active': language === 'Svenska'}" ><img src="/assets/flags/swe.png"></button>
      </div>
    </nav>

  </div>
</header>
