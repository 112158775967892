import { Injectable, Inject } from '@angular/core';

import { Constants } from './../app.config'; // FROM CS
import { LocalstoreService } from './../_shared/localstore.service'; // FROM CS
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'; // FROM CS
import * as _ from 'lodash'; // FROM CS
import { Subject } from 'rxjs'; // FROM CS


@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  public selectedLanguage: any;
	public flowDirection: any;
	public languageUpdated: Subject<Object> = new Subject();

	constructor(private localStore: LocalstoreService,
		private translate: TranslateService,
		private appConstants: Constants) {

		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.languageUpdated.next(this.getLanguageByCultureCode(event.lang));
		});
	}


	getLanguage(id) {
		const languages = this.localStore.get('languages');
		return _.find(languages, { id: id });
	}


	getLanguageByCultureCode(cultureCode) {
		const languages = this.localStore.get('languages');
		return _.find(languages, { cultureCode: cultureCode });
	}


	setApplicationLanguage(id) {
		// if passed id is null, set default language id
		if (_.isNull(id) || id === 0) {
			id = this.appConstants.defaultLanguageId;
		}
		// Return if same language
		if (this.selectedLanguage) {
			if (id === this.selectedLanguage.id) {
				return;
			}
		}
		this.selectedLanguage = this.getLanguage(id);
		this.localStore.store('selectedLanguage', this.selectedLanguage);
		this.translate.use(this.selectedLanguage.cultureCode);
		this.flowDirection = this.selectedLanguage.isRtl ? 'rtl' : 'ltr';
		document.dir = this.flowDirection;
	}


	public getSelectedLanguage() {
		const currentLang = this.localStore.get('selectedLanguage');
		if (currentLang != null) {
			return currentLang;
		} else {
			return this.appConstants.defaultLanguageObj;
		}
	}


	public setDefaultApplicationLanguage() {
		this.selectedLanguage = this.appConstants.defaultLanguage;
		this.translate.use(this.selectedLanguage);
		this.flowDirection = this.selectedLanguage.flowDirection ? 'rtl' : 'ltr';
		document.dir = this.flowDirection;
	}



}
