import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/internal/operators/take";
import { TrakkerService } from "../../_services/trakker.service";

@Component({
  selector: "app-operation-time",
  templateUrl: "./operation-time.component.html",
  styleUrls: ["./operation-time.component.scss"],
})
export class OperationTimeComponent implements OnInit {
  @Input() LatestEvent: any;

  // dateTimeLocales: string = "en-gb";
  dateTimeOptions: any = { timeZone: "Europe/Stockholm" };
  translationsAgo: any[] = [];
  subscriptions: any = [];
  isEditingComment: boolean = false;
  // currentTrakkerStatus: any;

  constructor(
    private translate: TranslateService,
    private trakkerService: TrakkerService
  ) {
    this.translationsAgo.push({
      key: "ABOUT_YEARS",
      value: "about { value } years",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_YEAR",
      value: "about 1 year ",
    });
    this.translationsAgo.push({
      key: "ABOUT_MONTHS",
      value: "about { value } months",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MONTH",
      value: "about 1 month",
    });
    this.translationsAgo.push({
      key: "ABOUT_DAYS",
      value: "about { value } days",
    });
    this.translationsAgo.push({ key: "ABOUT_ONE_DAY", value: "about 1 day" });
    this.translationsAgo.push({
      key: "ABOUT_HOURS",
      value: "about { value } hours",
    });
    this.translationsAgo.push({ key: "ABOUT_ONE_HOUR", value: "about 1 hour" });
    this.translationsAgo.push({
      key: "ABOUT_MINUTES",
      value: "about { value } minutes",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MINUTE",
      value: "about 1 minute",
    });
    this.translationsAgo.push({ key: "A_FEW_SECONDS", value: "a few seconds" });
    this.translationsAgo.push({ key: "DAY", value: "day" });
    this.translationsAgo.push({ key: "DAYS", value: "days" });
    this.translationsAgo.push({ key: "HOUR", value: "hour" });
    this.translationsAgo.push({ key: "HOURS", value: "hours" });
    this.translationsAgo.push({ key: "MINUTE", value: "minute" });
    this.translationsAgo.push({ key: "MINUTES", value: "minutes" });
    this.translationsAgo.push({ key: "SECOND", value: "second" });
    this.translationsAgo.push({ key: "SECONDS", value: "seconds" });

    this.translationsAgo.push({
      key: "ABOUT_YEARS_AGO",
      value: "about { value } years ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_YEAR_AGO",
      value: "about 1 year ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MONTHS_AGO",
      value: "about { value } months ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MONTH_AGO",
      value: "about 1 month ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_DAYS_AGO",
      value: "about { value } days ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_DAY_AGO",
      value: "about 1 day ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_HOURS_AGO",
      value: "about { value } hours ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_HOUR_AGO",
      value: "about 1 hour ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MINUTES_AGO",
      value: "about { value } minutes ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MINUTE_AGO",
      value: "about 1 minute ago",
    });
    this.translationsAgo.push({
      key: "A_FEW_SECONDS_AGO",
      value: "a few seconds ago",
    });

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_YEAR").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MONTH").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_HOUR").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_MINUTE").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS").value =
            result;
        })
    );

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.DAY")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "DAY").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.DAYS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "DAYS").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.HOUR")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "HOUR").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.HOURS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "HOURS").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.MINUTE")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "MINUTE").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.MINUTES")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "MINUTES").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.SECOND")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "SECOND").value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.SECONDS")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "SECONDS").value = result;
        })
    );

    // AGO
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_YEAR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MONTH_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_HOUR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MINUTE_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          //console.log(result);
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS_AGO").value =
            result;
        })
    );
  }

  ngOnInit(): void {}

  StartEditOperationTimeComment() {
    this.isEditingComment = true;
  }

  saveComment(eventId) {
    var comment = $("#comment-input").val();
    if (comment === null) {
      console.log();
      this.isEditingComment = false;
      return;
    }
    var data = { Comment: comment };
    this.subscriptions.push(
      this.trakkerService
        .editOperationTimeEventComment(eventId, data)
        .subscribe((result) => {
          this.LatestEvent.Comment = comment;
          // this.getTrakker(this.trakker.TrakkerId);
          this.isEditingComment = false;
        })
    );
  }

  // getTrakker(trakkerId) {
  //   this.trakkerService.getTrakkerNew(trakkerId).subscribe((result) => {
  //     this.trakker = result;
  //     this.currentTrakkerStatus = this.GetTrakkerStatus(result);
  //     // if (this.trakker.Vehicles[0] != null) {
  //     //   this.vehicle = this.trakker.Vehicles[0];
  //     // }

  //     if (
  //       this.trakker.OperationTimeUnit &&
  //       this.trakker.OperationTimeUnit.LatestEvent
  //     ) {
  //       if (
  //         this.trakker.OperationTimeUnit.LatestEvent.StartDateTime &&
  //         this.trakker.OperationTimeUnit.LatestEvent.StopDateTime
  //       ) {
  //       }
  //     }
  //   });
  // }

  // GetTrakkerStatus(trakker) {
  //   //Todo if active alert SOS return SOS
  //   if (trakker) {
  //     if (
  //       trakker.ActiveAlerts &&
  //       trakker.ActiveAlerts.find((a) => a.TrakkerEvent.AlertType == "SOS")
  //     )
  //       return "SOS";
  //     switch (trakker.Status) {
  //       case "NotAvailable":
  //         return "NewTrakker";
  //       case "Alert":
  //         switch (trakker.LatestEvent.AlertType) {
  //           case "PowerOff":
  //             return "PowerOff";
  //           case "PowerOn":
  //             return "PowerOn";
  //           case "SOS":
  //             return "SOS";
  //         }
  //       case "OffGrid":
  //         if (
  //           trakker.LatestEvent != null &&
  //           trakker.LatestEvent.AlertType == "PowerOff"
  //         ) {
  //           return "PowerOff";
  //         }
  //     }
  //     return trakker.Status;
  //   }
  // }
}
