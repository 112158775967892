import { Component, Input, OnDestroy, AfterViewChecked } from '@angular/core';
import { LocalstoreService } from '../localstore.service';

import * as L from 'leaflet';
var mapsPlaceholder = [];
@Component({
  selector: 'positionMap',
  templateUrl: './position-map.component.html',
  styleUrls: ['./position-map.component.scss']
})
export class PositionMapComponent implements OnDestroy, AfterViewChecked {
  @Input() positions: any[];
  @Input() eventId: any;
  @Input() isStop: any;

  subscriptions: any = [];
  map: any;
  positionMarkers: any = L.featureGroup();
  opsm: any;
  opsmdt: any;

  constructor(
    private localStore: LocalstoreService
  ) { }


  ngAfterViewChecked(): void {
    if (!this.map) {
      this.CreateMapTilesLayers();
      this.InitMap();
    }
  }

  ngOnDestroy(): void {
    if (mapsPlaceholder.length > 0) {
      for (let m of mapsPlaceholder) {
        m.off();
        m.remove();
      }
      mapsPlaceholder = [];
    }

    this.subscriptions.forEach(s => { s.unsubscribe(); });

  }


  InitMap() {
    var mapId = 'map' + this.eventId;

    if (this.map) {
      this.map.off()
      this.map.remove();
    }

    var baseMaps = {
      "Open street map": this.opsm,
      "Open street map Dark theme": this.opsmdt
    };

    this.map = L.map(mapId, {
      zoomControl: false

    });
    L.control.layers(baseMaps).addTo(this.map);

    var storedThemeId = this.localStore.get('ThemeId');
    if (storedThemeId == 1)
      this.opsmdt.addTo(this.map);
    else
      this.opsm.addTo(this.map);

    this.map.addLayer(this.positionMarkers);

    var polylinePoints = [];
    this.positions.forEach(p => {
      polylinePoints.push([p.Position.Latitude, p.Position.Longitude]);
    });

    // CHECK IF TRIP OR STOP TO APPLY THE CORRECT ICON TO THE MAP
    if (this.isStop) {
      // console.log("Probably a stop");
      this.CreateMapMarkerStop(polylinePoints[polylinePoints.length - 1]);
      this.map.setView(polylinePoints[polylinePoints.length - 1], 15, { padding: [10, 10] });
    } else {
      // console.log("Probably a trip");
      var eventRoute = L.polyline(polylinePoints); // .addTo(this.positionMarkers);
      this.map.fitBounds(eventRoute.getBounds(), { padding: [10, 10] });
      this.CreateMapMarkers(polylinePoints[0], polylinePoints[polylinePoints.length - 1]);
    }
    if (mapsPlaceholder.length > 0) {
      mapsPlaceholder[0].invalidateSize();
    }

    $(window).on('resize', function () {
      if (mapsPlaceholder.length > 0) {
        mapsPlaceholder[0].invalidateSize();
      }
    });

  }
  private CreateMapTilesLayers() {
    this.opsm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© Trakk'
    });
    this.opsmdt = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      attribution: '© Trakk'
    });
  }


  CreateMapMarkers(startpos, stoppos) {
    var marker = null;
    marker = new L.Marker(startpos, { icon: this.GetMarkerIcon('marker-a.png') });
    this.positionMarkers.addLayer(marker);
    marker = new L.Marker(stoppos, { icon: this.GetMarkerIcon('marker-b.png') });
    this.positionMarkers.addLayer(marker);
  }

  CreateMapMarkerStop(stoppos) {
    var marker = null;
    marker = new L.Marker(stoppos, { icon: this.GetMarkerIcon('trakker-events/parking.svg') });
    this.positionMarkers.addLayer(marker);
  }

  GetMarkerIcon(url) {
    var iconSize = 32;
    var iconImage = "/assets/icons/svg/" + url;
    var trakkerIcon = L.icon({
      iconUrl: iconImage,
      iconSize: new L.Point(iconSize, iconSize),
      iconAnchor: new L.Point(iconSize / 2, iconSize),
      popupAnchor: new L.Point(-3, -iconSize / 2)
    });
    return trakkerIcon;
  }


}
