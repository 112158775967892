import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Schedule, ScheduleEntry } from "../_models/schedule.model";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor(private api: ApiService) {}

  getSchedule = (id: number): Observable<Schedule | null> => {
    return this.api.get(`schedule/${id}`);
  };

  createScheduleEntry = (parentId: number, entry: ScheduleEntry): Observable<ScheduleEntry> => {
    const { StartTime, StopTime, Day } = entry;
    return this.api.post(`schedule/${parentId}/entries`, { StartTime, StopTime, Day });
  };

  deleteScheduleEntry = (parentId: number, entryId: number): Observable<any> => {
    return this.api.delete(`schedule/${parentId}/entries/${entryId}`);
  };
}
