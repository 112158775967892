import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from "src/environments/environment";

@Injectable()
export class MapCommonFunctionService {
    apiKey = `${environment.HereApiKey}`;
    //baseUrl = 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=8juRomaVihyxt1ygirb2&app_code=cXefpKlLMVLM48kWl-gLfA&maxresults=5&query=';
    autocompleteUrl = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=' + this.apiKey + '&beginHighlight=<b>&endHighlight=</b>&maxresults=5&query=';
    locationDetailsUrl = 'https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=' + this.apiKey + '&locationid=';

    public hereLocationValuesChanged: Subject<Object> = new Subject();
    public hereLocationValuesChanged$ = this.hereLocationValuesChanged.asObservable();

    constructor(private api: ApiService) {
    }

    addressSearch(searchText): Observable<any> {
         return this.api.getExternal(this.autocompleteUrl + searchText)
    }

    locationDetails(locationId): Observable<any> {
        return this.api.getExternal(this.locationDetailsUrl + locationId);
    }
}
