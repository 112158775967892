import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { PositionService } from "../../_services/position.service";

@Component({
  selector: "address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input() event: any;
  @Input() from: any;
  @Input() to: any;
  @Input() resolveAddress: boolean;
  @Input() userInfo: any;
  @Input() handleError = true;

  reverseGeocode: boolean = false;
  address: any = null;
  position: any = null;
  isPrivate: boolean = false;
  subscriptions: any = [];
  constructor(
    public positionService: PositionService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.event != null && this.event.hasOwnProperty("TrakkerEventId")) {
      if (this.event.GeocodedPosition != null) {
        this.address = this.event.GeocodedPosition;

        this.address.Country = this.GetCountryString(this.address.Country);
        this.cdr.detectChanges();
        return;
      } else {
        this.address = null;
        if (this.event.Position) {
          // console.log("Trakker is receiving new GeocodedAddress");
          this.position = this.event.Position;
          this.ReverseGeocode();
        }
      }
      this.position = this.event.Position;
      return;
    }
    if (this.event != null && this.event.hasOwnProperty("VehicleEventId")) {
      this.isPrivate = this.event.WorkingStatus == "PrivateUse";
      if (this.from == true) {
        if (this.event.FromAddress != null) {
          this.address = this.event.FromAddress;
          return;
        }
        if (this.event.FromGeocoded != null) {
          this.address = this.event.FromGeocoded;
          this.address.Country = this.GetCountryString(this.address.Country);
          this.cdr.detectChanges();
          return;
        } else {
          if (this.event.FromPosition) {
            // console.log("Vehicle is receiving new GeocodedAddress (FromAddress)");
            this.position = this.event.FromPosition;
            this.ReverseGeocode();
          }
        }

        this.position = this.event.FromPosition;
        return;
      }
      if (this.to == true) {
        if (this.event.ToAddress != null) {
          this.address = this.event.ToAddress;
          return;
        }
        if (this.event.ToGeocoded != null) {
          this.address = this.event.ToGeocoded;
          this.address.Country = this.GetCountryString(this.address.Country);
          this.cdr.detectChanges();
          return;
        } else {
          if (this.event.ToPosition) {
            // console.log("Vehicle is receiving new GeocodedAddress (ToAddress)");
            this.position = this.event.ToPosition;
            this.ReverseGeocode();
          }
        }
        this.position = this.event.ToPosition;
        return;
      }
    }
  }

  test() {
    console.log(this.address);
  }

  GetCountryString(code: string) {
    // ESCAPE FUNCTION WHEN COUNTRY IS NULL
    if (!code) {
      return "";
    }

    var localeObject = this.GetLocaleObject("en");
    var countryString = localeObject.countries[code.toLocaleUpperCase()];
    if (countryString) return countryString;

    return code;
  }

  GetLocaleObject(lang: string) {
    if (lang == "en")
      return {
        locale: "en",
        countries: {
          AF: "Afghanistan",
          AL: "Albania",
          DZ: "Algeria",
          AS: "American Samoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarctica",
          AG: "Antigua and Barbuda",
          AR: "Argentina",
          AM: "Armenia",
          AW: "Aruba",
          AU: "Australia",
          AT: "Austria",
          AZ: "Azerbaijan",
          BS: "Bahamas",
          BH: "Bahrain",
          BD: "Bangladesh",
          BB: "Barbados",
          BY: "Belarus",
          BE: "Belgium",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhutan",
          BO: "Bolivia",
          BA: "Bosnia and Herzegovina",
          BW: "Botswana",
          BV: "Bouvet Island",
          BR: "Brazil",
          IO: "British Indian Ocean Territory",
          BN: "Brunei Darussalam",
          BG: "Bulgaria",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Cambodia",
          CM: "Cameroon",
          CA: "Canada",
          CV: "Cape Verde",
          KY: "Cayman Islands",
          CF: "Central African Republic",
          TD: "Chad",
          CL: "Chile",
          CN: "China",
          CX: "Christmas Island",
          CC: "Cocos (Keeling) Islands",
          CO: "Colombia",
          KM: "Comoros",
          CG: "Congo",
          CD: "Congo, the Democratic Republic of the",
          CK: "Cook Islands",
          CR: "Costa Rica",
          CI: "Cote D'Ivoire",
          HR: "Croatia",
          CU: "Cuba",
          CY: "Cyprus",
          CZ: "Czech Republic",
          DK: "Denmark",
          DJ: "Djibouti",
          DM: "Dominica",
          DO: "Dominican Republic",
          EC: "Ecuador",
          EG: "Egypt",
          SV: "El Salvador",
          GQ: "Equatorial Guinea",
          ER: "Eritrea",
          EE: "Estonia",
          ET: "Ethiopia",
          FK: "Falkland Islands (Malvinas)",
          FO: "Faroe Islands",
          FJ: "Fiji",
          FI: "Finland",
          FR: "France",
          GF: "French Guiana",
          PF: "French Polynesia",
          TF: "French Southern Territories",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Georgia",
          DE: "Germany",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Greece",
          GL: "Greenland",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heard Island and Mcdonald Islands",
          VA: "Holy See (Vatican City State)",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hungary",
          IS: "Iceland",
          IN: "India",
          ID: "Indonesia",
          IR: "Iran, Islamic Republic of",
          IQ: "Iraq",
          IE: "Ireland",
          IL: "Israel",
          IT: "Italy",
          JM: "Jamaica",
          JP: "Japan",
          JO: "Jordan",
          KZ: "Kazakhstan",
          KE: "Kenya",
          KI: "Kiribati",
          KP: "North Korea",
          KR: "South Korea",
          KW: "Kuwait",
          KG: "Kyrgyzstan",
          LA: "Lao People's Democratic Republic",
          LV: "Latvia",
          LB: "Lebanon",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libya",
          LI: "Liechtenstein",
          LT: "Lithuania",
          LU: "Luxembourg",
          MO: "Macao",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malaysia",
          MV: "Maldives",
          ML: "Mali",
          MT: "Malta",
          MH: "Marshall Islands",
          MQ: "Martinique",
          MR: "Mauritania",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Mexico",
          FM: "Micronesia, Federated States of",
          MD: "Moldova, Republic of",
          MC: "Monaco",
          MN: "Mongolia",
          MS: "Montserrat",
          MA: "Morocco",
          MZ: "Mozambique",
          MM: "Myanmar",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Netherlands",
          NC: "New Caledonia",
          NZ: "New Zealand",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolk Island",
          MK: "North Macedonia, Republic of",
          MP: "Northern Mariana Islands",
          NO: "Norway",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Palestinian Territory, Occupied",
          PA: "Panama",
          PG: "Papua New Guinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Philippines",
          PN: "Pitcairn",
          PL: "Poland",
          PT: "Portugal",
          PR: "Puerto Rico",
          QA: "Qatar",
          RE: "Reunion",
          RO: "Romania",
          RU: "Russian Federation",
          RW: "Rwanda",
          SH: "Saint Helena",
          KN: "Saint Kitts and Nevis",
          LC: "Saint Lucia",
          PM: "Saint Pierre and Miquelon",
          VC: "Saint Vincent and the Grenadines",
          WS: "Samoa",
          SM: "San Marino",
          ST: "Sao Tome and Principe",
          SA: "Saudi Arabia",
          SN: "Senegal",
          SC: "Seychelles",
          SL: "Sierra Leone",
          SG: "Singapore",
          SK: "Slovakia",
          SI: "Slovenia",
          SB: "Solomon Islands",
          SO: "Somalia",
          ZA: "South Africa",
          GS: "South Georgia and the South Sandwich Islands",
          ES: "Spain",
          LK: "Sri Lanka",
          SD: "Sudan",
          SR: "Suriname",
          SJ: "Svalbard and Jan Mayen",
          SZ: "Swaziland",
          SE: "Sweden",
          CH: "Switzerland",
          SY: "Syrian Arab Republic",
          TW: "Taiwan",
          TJ: "Tajikistan",
          TZ: "Tanzania, United Republic of",
          TH: "Thailand",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad and Tobago",
          TN: "Tunisia",
          TR: "Turkey",
          TM: "Turkmenistan",
          TC: "Turks and Caicos Islands",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukraine",
          AE: "United Arab Emirates",
          GB: "United Kingdom",
          US: "United States of America",
          UM: "United States Minor Outlying Islands",
          UY: "Uruguay",
          UZ: "Uzbekistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Viet Nam",
          VG: "Virgin Islands, British",
          VI: "Virgin Islands, U.S.",
          WF: "Wallis and Futuna",
          EH: "Western Sahara",
          YE: "Yemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
          AX: "Åland Islands",
          BQ: "Bonaire, Sint Eustatius and Saba",
          CW: "Curaçao",
          GG: "Guernsey",
          IM: "Isle of Man",
          JE: "Jersey",
          ME: "Montenegro",
          BL: "Saint Barthélemy",
          MF: "Saint Martin (French part)",
          RS: "Serbia",
          SX: "Sint Maarten (Dutch part)",
          SS: "South Sudan",
          XK: "Kosovo",
        },
      };

    if (lang == "sv")
      return {
        locale: "sv",
        countries: {
          AD: "Andorra",
          AE: "Förenade Arabemiraten",
          AF: "Afghanistan",
          AG: "Antigua och Barbuda",
          AI: "Anguilla",
          AL: "Albanien",
          AM: "Armenien",
          AO: "Angola",
          AQ: "Antarktis",
          AR: "Argentina",
          AS: "Amerikanska Samoa",
          AT: "Österrike",
          AU: "Australien",
          AW: "Aruba",
          AX: "Åland",
          AZ: "Azerbajdzjan",
          BA: "Bosnien och Hercegovina",
          BB: "Barbados",
          BD: "Bangladesh",
          BE: "Belgien",
          BF: "Burkina Faso",
          BG: "Bulgarien",
          BH: "Bahrain",
          BI: "Burundi",
          BJ: "Benin",
          BL: "Saint-Barthélemy",
          BM: "Bermuda",
          BN: "Brunei",
          BO: "Bolivia",
          BQ: "Bonaire, Saint Eustatius och Saba",
          BR: "Brasilien",
          BS: "Bahamas",
          BT: "Bhutan",
          BV: "Bouvetön",
          BW: "Botswana",
          BY: "Vitryssland",
          BZ: "Belize",
          CA: "Kanada",
          CC: "Kokosöarna",
          CD: "Demokratiska republiken Kongo",
          CF: "Centralafrikanska republiken",
          CG: "Kongo-Brazzaville",
          CH: "Schweiz",
          CI: "Elfenbenskusten",
          CK: "Cooköarna",
          CL: "Chile",
          CM: "Kamerun",
          CN: "Kina",
          CO: "Colombia",
          CR: "Costa Rica",
          CU: "Kuba",
          CV: "Kap Verde",
          CW: "Curacao",
          CX: "Julön",
          CY: "Cypern",
          CZ: "Tjeckien",
          DE: "Tyskland",
          DJ: "Djibouti",
          DK: "Danmark",
          DM: "Dominica",
          DO: "Dominikanska republiken",
          DZ: "Algeriet",
          EC: "Ecuador",
          EE: "Estland",
          EG: "Egypten",
          EH: "Västsahara",
          ER: "Eritrea",
          ES: "Spanien",
          ET: "Etiopien",
          FI: "Finland",
          FJ: "Fiji",
          FK: "Falklandsöarna",
          FM: "Mikronesiska federationen",
          FO: "Färöarna",
          FR: "Frankrike",
          GA: "Gabon",
          GB: "Storbritannien",
          GD: "Grenada",
          GE: "Georgien",
          GF: "Franska Guyana",
          GG: "Guernsey",
          GH: "Ghana",
          GI: "Gibraltar",
          GL: "Grönland",
          GM: "Gambia",
          GN: "Guinea",
          GP: "Guadeloupe",
          GQ: "Ekvatorialguinea",
          GR: "Grekland",
          GS: "Sydgeorgien och Sydsandwichöarna",
          GT: "Guatemala",
          GU: "Guam",
          GW: "Guinea Bissau",
          GY: "Guyana",
          HK: "Hongkong",
          HM: "Heard- och McDonaldsöarna",
          HN: "Honduras",
          HR: "Kroatien",
          HT: "Haiti",
          HU: "Ungern",
          ID: "Indonesien",
          IE: "Irland",
          IL: "Israel",
          IM: "Isle of Man",
          IN: "Indien",
          IO: "Brittiska territoriet i Indiska Oceanen",
          IQ: "Irak",
          IR: "Iran",
          IS: "Island",
          IT: "Italien",
          JE: "Jersey",
          JM: "Jamaica",
          JO: "Jordanien",
          JP: "Japan",
          KE: "Kenya",
          KG: "Kirgizistan",
          KH: "Kambodja",
          KI: "Kiribati",
          KM: "Komorerna",
          KN: "Saint Kitts och Nevis",
          KP: "Nordkorea",
          KR: "Sydkorea",
          KW: "Kuwait",
          KY: "Caymanöarna",
          KZ: "Kazakstan",
          LA: "Laos",
          LB: "Libanon",
          LC: "Saint Lucia",
          LI: "Liechtenstein",
          LK: "Sri Lanka",
          LR: "Liberia",
          LS: "Lesotho",
          LT: "Litauen",
          LU: "Luxemburg",
          LV: "Lettland",
          LY: "Libyen",
          MA: "Marocko",
          MC: "Monaco",
          MD: "Moldavien",
          ME: "Montenegro",
          MF: "Saint Martin (franska delen)",
          MG: "Madagaskar",
          MH: "Marshallöarna",
          MK: "Nordmakedonien",
          ML: "Mali",
          MM: "Burma",
          MN: "Mongoliet",
          MO: "Macau",
          MP: "Nordmarianerna",
          MQ: "Martinique",
          MR: "Mauretanien",
          MS: "Montserrat",
          MT: "Malta",
          MU: "Mauritius",
          MV: "Maldiverna",
          MW: "Malawi",
          MX: "Mexiko",
          MY: "Malaysia",
          MZ: "Moçambique",
          NA: "Namibia",
          NC: "Nya Kaledonien",
          NE: "Niger",
          NF: "Norfolkön",
          NG: "Nigeria",
          NI: "Nicaragua",
          NL: "Nederländerna",
          NO: "Norge",
          NP: "Nepal",
          NR: "Nauru",
          NU: "Niue",
          NZ: "Nya Zeeland",
          OM: "Oman",
          PA: "Panama",
          PE: "Peru",
          PF: "Franska Polynesien",
          PG: "Papua Nya Guinea",
          PH: "Filippinerna",
          PK: "Pakistan",
          PL: "Polen",
          PM: "Saint-Pierre och Miquelon",
          PN: "Pitcairnöarna",
          PR: "Puerto Rico",
          PS: "Palestinska territoriet, ockuperade",
          PT: "Portugal",
          PW: "Palau",
          PY: "Paraguay",
          QA: "Qatar",
          RE: "Réunion",
          RO: "Rumänien",
          RS: "Serbien",
          RU: "Ryssland",
          RW: "Rwanda",
          SA: "Saudiarabien",
          SB: "Salomonöarna",
          SC: "Seychellerna",
          SD: "Sudan",
          SE: "Sverige",
          SG: "Singapore",
          SH: "Sankta Helena",
          SI: "Slovenien",
          SJ: "Svalbard och Jan Mayen",
          SK: "Slovakien",
          SL: "Sierra Leone",
          SM: "San Marino",
          SN: "Senegal",
          SO: "Somalia",
          SR: "Surinam",
          SS: "Sydsudan",
          ST: "São Tomé och Príncipe",
          SV: "El Salvador",
          SX: "Sint Maarten (nederländska delen)",
          SY: "Syrien",
          SZ: "Swaziland",
          TC: "Turks- och Caicosöarna",
          TD: "Tchad",
          TF: "Franska södra territorierna",
          TG: "Togo",
          TH: "Thailand",
          TJ: "Tadzjikistan",
          TK: "Tokelauöarna",
          TL: "Östtimor",
          TM: "Turkmenistan",
          TN: "Tunisien",
          TO: "Tonga",
          TR: "Turkiet",
          TT: "Trinidad och Tobago",
          TV: "Tuvalu",
          TW: "Taiwan",
          TZ: "Tanzania",
          UA: "Ukraina",
          UG: "Uganda",
          UM: "USA:s yttre öar",
          US: "USA",
          UY: "Uruguay",
          UZ: "Uzbekistan",
          VA: "Vatikanstaten",
          VC: "Saint Vincent och Grenadinerna",
          VE: "Venezuela",
          VG: "Brittiska Jungfruöarna",
          VI: "Amerikanska Jungfruöarna",
          VN: "Vietnam",
          VU: "Vanuatu",
          WF: "Wallis- och Futunaöarna",
          WS: "Samoa",
          YE: "Jemen",
          YT: "Mayotte",
          ZA: "Sydafrika",
          ZM: "Zambia",
          ZW: "Zimbabwe",
          XK: "Kosovo",
        },
      };
  }

  resolveThisAddress() {
    this.resolveAddress = true;
    this.ReverseGeocode();
  }

  ReverseGeocode() {
    if (this.reverseGeocode == true) {
      // console.log("Already reverseGeocoding...");
      return;
    }

    if (this.resolveAddress == false) {
      // console.log("This address is asked not to be resolved");
      return;
    }

    // console.log("ReverseGeocode...");
    this.reverseGeocode = true;

    this.subscriptions.push(
      this.positionService
        .geocodePosition(this.position.PositionId, this.handleError)
        .subscribe((result) => {
          // console.log(result);

          //       GeocodedPosition:
          // GeocodedPositionId: 1
          // Latitude: 57.6506
          // Longitude: 11.9477
          // Street: "Lona Knapes Gata"
          // HouseNumber: "5"
          // City: "Västra Frölunda"
          // PostalCode: "421 32"
          // Country: "Sweden"
          // Label: null
          // CreatedOn: "2019-10-07T08:38:00"

          //       this.address = {
          //         Street: "Street",
          //         HouseNumber: "HouseNumber",
          //         PostalCode: "PostalCode",
          //         City: "City",
          //         Country: "CO"
          //       };
          if (this.event.hasOwnProperty("TrakkerEventId")) {
            this.event.GeocodedPosition = result;
          }
          this.address = result;
          this.address.Country = this.GetCountryString(this.address.Country);
          this.cdr.detectChanges();
        }, err =>  {
          console.log("No address found at " + this.position.Latitude + " " + this.position.Longitude);
          this.resolveAddress = false;
          this.reverseGeocode = false;
          this.cdr.detectChanges();
        })
    );

    // setTimeout(() => {
    //   this.address = {
    //     Street: "Street",
    //     HouseNumber: "HouseNumber",
    //     PostalCode: "PostalCode",
    //     City: "City",
    //     Country: "CO"
    //   };
    //   this.event.GeocodedPosition = this.address;
    //   this.address.Country = this.GetCountryString(this.address.Country);
    //   }, 2500);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
