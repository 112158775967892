import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Constants } from '../../app.config';
import { AgoPipe } from '../../_pipes/ago.pipe';

@Component({
  selector: 'eventType',
  templateUrl: './event-type.component.html',
  styleUrls: ['./event-type.component.scss']
})
export class EventTypeComponent implements OnInit, OnChanges {
  @Input() event: any;
  @Input() outputType: string;
  @Input() checkForOffGrid: boolean;

  objectType :string = "trakker";
  isOffGrid:boolean = false;
  outputText:string;
  agoPipe:any;

  constructor(
    public appConstants: Constants
  ) {
    this.agoPipe = new AgoPipe();
   }

  ngOnInit() {
    if(this.event != null && this.event.hasOwnProperty("TrakkerEventType")){
      this.objectType = "trakker";
      this.isOffGrid = this.GetIsOffGridStatus();   
    }
    if(this.event != null && this.event.hasOwnProperty("VehicleEventType")){
      this.objectType = "vehicle";
    }


    if(this.outputType == null)
    {
      this.outputType="icon";
    }
    this.outputType = this.outputType.toLocaleLowerCase();
    if(this.outputType == "text")
    {
      this.outputText = this.GetEventTypeText();
    }
  }
  GetIsOffGridStatus(): boolean {
    if(this.event && this.checkForOffGrid == true)
    {
      switch(this.event.TrakkerEventType)
      {
        case "Parking":
          return false;
        case "Alert":
          switch(this.event.AlertType)
          {
            case "PowerOff":
              return false;
            case "OffGrid":
              return true;
          }
      }

      var ago = this.agoPipe.transform(this.event.OccuredOn, [], true) / 3600;
      if(ago >= this.appConstants.offGridHoursThreshold)
        return true;
    }
    return false;
  }

  ngOnChanges() {
    //console.log("Changes detected");
  }
  GetEventTypeText(){
    if(this.isOffGrid == true)
    {
      return "OffGrid";
    }
    if(this.event != null){
      switch(this.event.TrakkerEventType){
        case "Position":
          //console.log("Position");
          return "Position";
        case "Battery":
            return "Battery";
        case "Parking":
          //console.log("Parking");
          return "Parking";
        case "Alert":
          // switch(this.event.AlertType)
          // {
          //   case "SOS":
          //   case "LowBattery":
              return this.event.AlertType;
          // }
          // return "Alert";
        case 'Active':
          return 'Active';
      }
    }
    // console.log("N/A");
    return "NA";
  }
}
