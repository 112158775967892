<ng-container *ngIf="event != null && outputType == 'icon'">
  <ng-container *ngIf="event.Heading !== null" class="heading">
      <img
        src="assets/icons/svg/heading.svg"
        [style.transform]="'rotate('+(event.Heading) + 'deg)'"
        [ngbTooltip]="( GetHeadingText() | translate )"/>
  </ng-container>
  <ng-container *ngIf="event.Heading === null">
      <img
      style="opacity: 0.2; "
      src="assets/icons/svg/heading.svg"
      [ngbTooltip]="( GetHeadingText() | translate )"/>
  </ng-container>
</ng-container>
<ng-container *ngIf="event === null && outputType == 'icon'">
    <img
    style="opacity: 0.2;"
    src="assets/icons/svg/heading.svg"
    [ngbTooltip]="( GetHeadingText() | translate )"/>
</ng-container>
<ng-container *ngIf="outputType == 'text'">
  {{ GetHeadingText() | translate }}
</ng-container>
