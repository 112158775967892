import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ago'
})
export class AgoPipe implements PipeTransform {

  transform(utcDateTime: string, translations: any[] = [], outputInSeconds = false ): any {
      if(utcDateTime) {
        utcDateTime = utcDateTime.toUpperCase();
        if(utcDateTime[utcDateTime.length-1] != "Z")
        utcDateTime+="Z";

      var realDateTime = new Date(Date.parse(utcDateTime));
      var seconds = Math.floor((new Date(Date.now()).getTime() - realDateTime.getTime()) / 1000);
      
      if(outputInSeconds == true)
        return seconds;
      
      var interval = Math.floor(seconds / 31536000);
      
      if (interval == 1) {
        var ABOUT_ONE_YEAR_AGO = translations.find(t=>t.key == "ABOUT_ONE_YEAR_AGO");
        if(ABOUT_ONE_YEAR_AGO){
          return ABOUT_ONE_YEAR_AGO.value;
        }
        return "about 1 year ago";
      }
      if (interval > 1) {
        var ABOUT_YEARS_AGO = translations.find(t=>t.key == "ABOUT_YEARS_AGO");
        if(ABOUT_YEARS_AGO){
          return ABOUT_YEARS_AGO.value.replace("{ value }", interval );
        }
        return "about " + interval + " years ago";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        var ABOUT_ONE_MONTH_AGO = translations.find(t=>t.key == "ABOUT_ONE_MONTH_AGO");
        if(ABOUT_ONE_MONTH_AGO){
          return ABOUT_ONE_MONTH_AGO.value;
        }
        return "about 1 month ago";
      }
      if (interval > 1) {
        var ABOUT_MONTHS_AGO = translations.find(t=>t.key == "ABOUT_MONTHS_AGO");
        if(ABOUT_MONTHS_AGO){
          return ABOUT_MONTHS_AGO.value.replace("{ value }", interval );
        }
        return "about " + interval + " months ago";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        var ABOUT_ONE_DAY_AGO = translations.find(t=>t.key == "ABOUT_ONE_DAY_AGO");
        if(ABOUT_ONE_DAY_AGO){
          return ABOUT_ONE_DAY_AGO.value;
        }
        return "about 1 day ago";
      }
      if (interval > 1) {
        var ABOUT_DAYS_AGO = translations.find(t=>t.key == "ABOUT_DAYS_AGO");
        if(ABOUT_DAYS_AGO){
          return ABOUT_DAYS_AGO.value.replace("{ value }", interval );
        }
        return "about " + interval + " days ago";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        var ABOUT_ONE_HOUR_AGO = translations.find(t=>t.key == "ABOUT_ONE_HOUR_AGO");
        if(ABOUT_ONE_HOUR_AGO){
          return ABOUT_ONE_HOUR_AGO.value;
        }
        return "about 1 hour ago";
      }
      if (interval > 1) {
        var ABOUT_HOURS_AGO = translations.find(t=>t.key == "ABOUT_HOURS_AGO");
        if(ABOUT_HOURS_AGO){
          return ABOUT_HOURS_AGO.value.replace("{ value }", interval );
        }
        return "about " + interval + " hours ago";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        var ABOUT_ONE_MINUTE_AGO = translations.find(t=>t.key == "ABOUT_ONE_MINUTE_AGO");
        if(ABOUT_ONE_MINUTE_AGO){
          return ABOUT_ONE_MINUTE_AGO.value;
        }
        return "about 1 minute ago";
      }
      if (interval > 1) {
        var ABOUT_MINUTES_AGO = translations.find(t=>t.key == "ABOUT_MINUTES_AGO");
        if(ABOUT_MINUTES_AGO){
          return ABOUT_MINUTES_AGO.value.replace("{ value }", interval );
        }
        return "about " + interval + " minutes ago";
      }
      var A_FEW_SECONDS_AGO = translations.find(t=>t.key == "A_FEW_SECONDS_AGO");
      if(A_FEW_SECONDS_AGO){
        return A_FEW_SECONDS_AGO.value;
      }
      return "a few seconds ago";
      // return Math.floor(seconds) + " seconds" + " " + "ago";
    }
    return "";
  }
}
