import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, CanActivate } from "@angular/router";
import { AccountService } from '../_services/account.service';
import { take } from 'rxjs/internal/operators/take';
import { Constants } from '../app.config';
import { LocalstoreService } from '../_shared/localstore.service';
import { NavService } from 'src/app/_services/nav.service';
import { JwtService } from '../_services/jwt.service';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.sass']
})
export class RedirectComponent implements OnInit {

  guid: any;
  state: any;
  isWaitingForNewToken: boolean = false;
  token: any;
  subscriptions: any = [];

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private router: Router,
              public appConstants: Constants,
              private localStore: LocalstoreService,
              private navService: NavService,
              private jwtService: JwtService
  ) { }


  ngOnInit() {

    this.guid = this.route.snapshot.paramMap.get("guid");

    if(this.guid) {
      this.getTokenByGuid(this.guid);
      // console.log("we now have this token : " + this.token)
    }

    if(this.token != null) {
      console.log("Token finns!")
    }


  }


  getTokenByGuid(guid) {
    // this.state.push(this.accountService.getTokenByGuid(guid)

    // this.accountService.getTokenByGuid(guid);

    this.isWaitingForNewToken = true;
    this.accountService.getTokenByGuid(guid).pipe(take(1)).subscribe(result => {
      const response: any = result;
      if(response){
        this.jwtService.saveToken(response)
        this.getAccountDetails();
      }
      
  });


  }


  sendToken(result) {
    console.log("we have token: " + result);
    // this.router.navigate(['/dashboard']);
    this.checkLogin(result);
  }


  checkLogin(token?) {
      this.jwtService.destroyToken();
      this.subscriptions.push(this.accountService
        .getTokenForExternalAccount(token)
        .subscribe(tokenDetails => {
          if (tokenDetails) {
            this.jwtService.saveToken(tokenDetails)
            this.getAccountDetails();
          }
        }));
  }


  getAccountDetails() {
    // Get Account login details
    this.subscriptions.push(this.accountService
      .getAccount()
      .subscribe(loginDetails => {
        this.navService.isLoggedIn.next(true);
        this.router.navigate(
          ['/dashboard']
        );
      }));
  }
}
