import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IconService } from 'src/app/_services/icon.service';
import { TrakkerService } from 'src/app/_services/trakker.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { take } from 'rxjs/internal/operators/take';
import { LoaderService } from '../../loader.service';

@Component({
  selector: 'iconpicker',
  templateUrl: './iconpicker.component.html',
  styleUrls: ['./iconpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconpickerComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: any[] = [];
  @Input() object: any = null;
  @Output() result = new EventEmitter<any>();
  iconList: any[] = [];
  categoryList: any[] = [];

  constructor(
    private iconService: IconService,
    private trakkerService: TrakkerService,
    private vehiclesService: VehiclesService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoaderService

  ) {
  }

  ngOnInit() {
    //console.log("IconPicker ngOnInit running");

    // setTimeout(() => { this.GetAllIcons(); },1000);
     //this.GetAllIcons();
  }
  ngAfterViewInit(): void {
    //console.log("IconPicker ngAfterViewInit running");

    this.GetAllIcons();
  }
  ngOnDestroy(): void {
    //console.log("IconPicker ngOnDestroy running");
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }
  GetAllIcons() {
    this.subscriptions.push(this.iconService.getAllIcons().pipe(take(1)).subscribe(result => {
      this.iconList = result;
      this.iconList.sort(function (a, b) {
        if (a.Name > b.Name) {
          return 1;
        }

        if (a.Name < b.Name) {
          return -1;
        }

        return 0;

      });
      this.GetCategoryList();
      this.loadingService.setLoading(false);
    }));
  }

  GetCategoryList(): any {
    this.iconList.forEach(icon => {
      var url = icon.Url;
      var category: string = url.split("/")[1].toUpperCase();
      if (this.categoryList.includes(category) == false) {
        this.categoryList.push(category);
      }
    });
    this.categoryList.sort(function (a, b) {
      if (a > b) {
        return 1;
      }

      if (a < b) {
        return -1;
      }

      return 0;

    });
    this.cdr.detectChanges();
  }

  SetIcon(icon) {

    var vehicleHandled = true;

    // if (this.object.hasOwnProperty("VehicleId")) {
    //   var vehicleData =
    //   {
    //     "Name": this.object.Name,
    //     "IconId": icon.IconId,
    //     "VehicleRegistrationNumber": this.object.VehicleRegistrationNumber,
    //     "Vin": this.object.Vin,
    //     "CO2": this.object.CO2
    //   };
    //   vehicleHandled = true;
    //   this.subscriptions.push(this.vehiclesService.editVehicle(this.object.VehicleId, vehicleData).subscribe(result => {
    //     this.object.Icon = icon;
    //     this.result.emit({ Object: this.object, Icon: icon });
    //   }));
    // }

    if (this.object.hasOwnProperty("TrakkerId") && this.object.TrakkerId) {
      var trakkerData = {
        "Name": this.object.Name,
        "IconId": icon.IconId,
        "EquipmentNumber": this.object.EquipmentNumber
      };
      this.subscriptions.push(this.trakkerService.editTrakkerData(this.object.TrakkerId, trakkerData).subscribe(result => {
        this.object.Icon = icon;
        this.result.emit({ Object: this.object, Icon: icon });
      }));

      // if (vehicleHandled == false && this.object.Vehicles != null && this.object.Vehicles.length > 0) {
      //   var vehicle = this.object.Vehicles[0];
      //   var vehicleData =
      //   {
      //     "Name": vehicle.Name,
      //     "IconId": icon.IconId,
      //     "VehicleRegistrationNumber": vehicle.VehicleRegistrationNumber,
      //     "Vin": vehicle.Vin,
      //     "CO2": vehicle.CO2
      //   };
      //   vehicleHandled = true;
      //   this.subscriptions.push(this.vehiclesService.editVehicle(vehicle.VehicleId, vehicleData).subscribe(result => {
      //     this.object.Vehicles[0].Icon = icon;
      //   }));
      // }

    }
  }

}
