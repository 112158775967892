import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { retry, catchError, map } from "rxjs/operators";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class VehiclesService {
  constructor(public translate: TranslateService, private api: ApiService) {}

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // GET /api/vehicles/{trakkerId}  (Get a vehicle)
  getVehicle(trakkerId): Observable<any> {
    return this.api.get("vehicles/" + trakkerId);
  }

  // PUT /api/vehicles/{trakkerId}  (Update a vehicle)
  editVehicle(trakkerId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId, data);
  }

  
  // PUT /api/assets/{assetId}  (Update co2 per min for an asset)
  editAssetCO2PerMin(assetId, data): Observable<any> {
    return this.api.put("assets/"+ assetId+ "/co2bymin", data);
  }

  // GET /api/vehicles  (Get all vehicles)
  getAllVehicles(): Observable<any> {
    return this.api.get("vehicles/");
  }

  // OLD -- NOW POST, see below
  // GET /api/vehicles/summary  (Get Vehicle Summary data)
  // getVehiclesSummary(input): Observable<any> {
  //   return this.api.get(
  //     'vehicles/summary?' + input,
  //     this.parameters
  //   )
  // }

  // POST /api/vehicles/summary  (Get Vehicle Summary data)
  getVehiclesSummary(data): Observable<any> {
    return this.api.post("vehicles/summary", data);
  }

  // POST /api/vehicles/compact-summary  (Get Vehicle Summary data)
  getCompactVehiclesSummary(data): Observable<any> {
    return this.api.post("vehicles/compact-summary", data);
  }

  // GET /api/vehicles/{trakkerId}/event  (Get vehicle events)
  getVehicleEvents(trakkerId, filterUrl, pagerUrl): Observable<any> {
    return this.api.get("vehicles/" + trakkerId + "/event" + filterUrl + pagerUrl);
  }

  // PUT /api/vehicles/{trakkerId}/odometer  (Update odometer)
  updateOdometer(trakkerId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/odometer", data);
  }

  // PUT /api/vehicles/{trakkerId}/driver  (Check in driver)
  updateDriver(trakkerId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/driver", data);
  }

  // PUT /api/vehicles/{trakkerId}/assign-driver/{driver}  (Check in custom driver)
  assignCustomDriver(trakkerId, driverName, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/assign-driver/" + driverName, data);
  }

  // GET /api/vehicles/driver/{accountId} (Get vehicle name for assigned driver)
  GetDriverVehicle(accountId): Observable<any> {
    return this.api.get("vehicles/driver/" + accountId);
  }
  
  // PUT /api/vehicles/{trakkerId}/assign-driver/{accountId}  (Check in driver)
  assignDriver(trakkerId, accountId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/assign-driver/" + accountId, data);
  }

  // PUT /api/vehicles/{trakkerId}/unassign-driver  (Check out driver)
  unassignDriver(trakkerId): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/unassign-driver/");
  }

  // PUT /api/vehicles/{trakkerId}/working-status  (Change working status of Vehicle)
  updateWorkingStatus(trakkerId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/working-status", data).pipe(retry(1), catchError(this.handleError));
  }

  // GET /api/vehicles/fuel-prices  (get fuel prices)
  getFuelPrices(): Observable<any> {
    return this.api.get("vehicles/fuel-prices");
  }

  // POST /api/vehicles/fuel-prices  (update fuel prices)
  updateFuelTypePrices(data): Observable<any> {
    return this.api.post("vehicles/fuel-prices", data);
  }

  // PUT /api/vehicles/{trakkerId}/fuel-type  (update vehicle fuel type)
  updateVehicleFuelType(trakkerId, data): Observable<any> {
    return this.api.put("vehicles/" + trakkerId + "/fuel-type/", data);
  }

  // POST /api/vehicles/{trakkerId}/parking-geofence  (Creates a Parking Geofence around specified Vehicle's latest position)
  createParkingGeofence(trakkerId, data): Observable<any> {
    return this.api.post("vehicles/" + trakkerId + "/parking-geofence", data);
  }

  // GET /api/vehicles/event/{vehicleEventId}  (Get a vehicle event)
  getVehicleEvent(vehicleEventId): Observable<any> {
    return this.api.get("vehicles/event/" + vehicleEventId);
  }

  // GET /api/vehicles/event/{vehicleEventId}/positions  (Get Positions connected to specified Vehicle Event)
  getVehicleEventPositions(vehicleEventId): Observable<any> {
    return this.api.get("vehicles/event/" + vehicleEventId + "/positions");
  }

  // POST /api/vehicles/{vehicleId}/event/trip  (Create a Trip)

  // PUT /api/vehicles/events/{vehicleEventId}/trip  (Update Trip values)

  // PUT /api/vehicles/events/{vehicleEventId}/stop  (Update Stop values)

  // PUT /api/vehicles/events/{vehicleEventId}/comment  (Update comment for specified Vehicle Event)
  updateVehicleEventComment(vehicleEventId, data): Observable<any> {
    return this.api.put("vehicles/events/" + vehicleEventId + "/comment", data);
  }

  // POST /api/vehicles/{trakkerId}/event/merge-trips  (Merge two trips in to one)
  mergeTrips(trakkerId, data): Observable<any> {
    return this.api.post("vehicles/" + trakkerId + "/event/merge-trips", data);
  }

  // POST /api/vehicles/{trakkerId}/event/merge-stops  (Merge two trips in to one)
  mergeStops(vehicleId, data): Observable<any> {
    return this.api.post("vehicles/" + vehicleId + "/event/merge-stops", data);
  }

  // DELETE /api/vehicles/events/{vehicleEventId}  (Delete a vehicle event)
  deleteVehicleEvent(vehicleEventId): Observable<any> {
    return this.api.delete("vehicles/events/" + vehicleEventId);
  }

  // GET /api/vehicles/{trakkerId}/schedules
  getVehicleSchedules(trakkerId): Observable<any> {
    return this.api.get("vehicles/" + trakkerId + "/schedules");
  }

  // POST /api/vehicles/{trakkerId}/schedules
  createNewVehicleSchedule(trakkerId, data): Observable<any> {
    return this.api.post("vehicles/" + trakkerId + "/schedules", data);
  }

  // PUT /api/vehicles/schedules/{scheduleId}
  updateVehicleSchedule(scheduleId, data): Observable<any> {
    return this.api.put("vehicles/schedules/" + scheduleId, data);
  }

  // DELETE /api/vehicles/schedules/{scheduleId}
  removeVehicleSchedule(scheduleId): Observable<any> {
    return this.api.delete("vehicles/schedules/" + scheduleId);
  }

  // GET /api/vehicles/schedules/{scheduleId}
  getSchedule(scheduleId): Observable<any> {
    return this.api.get("vehicles/schedules/" + scheduleId);
  }

  // GET /api/vehicles/favorites  (Get an array of favorite Vehicles, represented by Vehicle IDs)
  getFavoriteVehicles(): Observable<any> {
    return this.api.get("vehicles/favorites");
  }

  // POST /api/vehicles/favorites  (Update Vehicle favorite status for logged in user)
  updateVehicleFavorites(data): Observable<any> {
    return this.api.post("vehicles/favorites", data);
  }

  // Function to fetch Excel or PDF report
  // POST /api/report/vehicle/{vehicleId}/events (WRONG?)
  // POST /api/report/vehicle-events
  getVehicleEventReport(data): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/vehicle-events", data, newParams);
  }

  // POST /api/report/vehicle-summary
  getVehicleSummaryReport(data): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/vehicle-summary", data, newParams);
  }
  // // POST /api/report/vehicle-summary
  // getVehicleSummaryReport(data): Observable<any>  {
  //   return this.api.post(
  //     'report/vehicle-summary',
  //     data,
  //     this.parameters
  //     // newParams
  //   );
  // }

  // POST /api/report/operation-time-unit-events  (Generate and download an Event Report for an Operation Time Unit)
  getOperationTimeUnitEventsReport(data): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/operation-time-unit-events", data, newParams);
  }

  // POST /api/report/operation-time-summary  (Generate and download a Summary Report for multiple Operation Time Units)
  getOperationTimeUnitSummaryReport(data): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/operation-time-summary", data, newParams);
  }

  // POST /api/report/inventory  (Generate an Inventory Report)
  getExportInventoryReport(): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/inventory", null, newParams);
  }

  // PUT /api/vehicles/event/{vehicleEventId}/comment
  saveEventComment(vehicleEventId, data) {
    return this.api.put("vehicles/events/" + vehicleEventId + "/comment", data);
  }

  // PUT /api/vehicles/event/{vehicleEventId}/working-status
  saveEventWorkingStatus(vehicleEventId, data) {
    return this.api.put("vehicles/events/" + vehicleEventId + "/working-status", data);
  }

  // PUT /api/vehicles/event/{vehicleEventId}/driver
  saveEventDriver(vehicleEventId, data) {
    return this.api.put("vehicles/events/" + vehicleEventId + "/driver", data);
  }

  // DELETE /api/vehicles/event/{vehicleEventId}/driver
  deleteEventDriver(vehicleEventId){
    return this.api.delete("vehicles/events/" + vehicleEventId + "/driver");
  }

  // PUT /api/vehicles/event/{vehicleEventId}/trip
  updateTrip(vehicleEventId, data) {
    return this.api.put("vehicles/events/" + vehicleEventId + "/trip", data);
  }

  // PUT /api/vehicles/event/{vehicleEventId}/stop
  updateStop(vehicleEventId, data) {
    return this.api.put("vehicles/events/" + vehicleEventId + "/stop", data);
  }

  // POST /api/vehicles/{trakkerId}/event/trip
  createTrip(trakkerId, data) {
    return this.api.post("vehicles/" + trakkerId + "/event/trip", data);
  }

  // POST /api/vehicles/{trakkerId}/event/stop
  createStop(trakkerId, data) {
    return this.api.post("vehicles/" + trakkerId + "/event/stop", data);
  }
} // END SERVICE
