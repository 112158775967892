import { Injectable, ChangeDetectorRef } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LoaderService } from './loader.service';
import { mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor( private loadingService: LoaderService

    ) {}


    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      let loadingTimeout: ReturnType<typeof setTimeout>;
       
      // Set the loading state after 50 millisec
      const setLoading = () => {
        loadingTimeout = setTimeout(() => {
          this.loadingService.setLoading(true);
        }, 50 );
      };
      
      // Clear the loading timeout and unset loading state
      const clearLoading = () => {
        clearTimeout(loadingTimeout);
        this.loadingService.setLoading(false);
      };
  
      //setLoading();  
      
      return next.handle(request).pipe(
        mergeMap((event: HttpEvent<any>) => {
          if(request.url.endsWith("assets/summary") || request.url.endsWith("vehicles/summary"))
          clearLoading();
          else if (event instanceof HttpResponse) 
          clearLoading();
          else 
          setLoading();

          return of(event); 

        }),
        catchError(error => {
          clearLoading();
          throw error; // Rethrow the error after setting loading to false
        })
      );
      
    }
}
