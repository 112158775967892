<div class="cont">
<main class="qrMain">
  <section class="trakklogo">
    <div><img src="/assets/icons/trakk.png"></div>
  </section>
<section [translate]>Page.QRLandingPage.Overview</section>
<section id="action" [translate]>Page.QRLandingPage.Action</section>
<section class="apgSec" >

<a target="_blank" href="https://apps.apple.com/se/app/trakklora/id6449740787"><img src="/assets/icons/applestore.png" alt="Trakk Apple Store"></a>
  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.trakklora"><img src="/assets/icons/gplay.png" alt="Trakk Google Play"></a>
</section>
<section class="city">
  <img src="/assets/icons/city.jpg" alt="trakk">
</section>
</main>
</div>
