import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2 } from "@angular/core";
import { LocalstoreService } from "../_shared/localstore.service";
import { VehicleService } from "../_services/vehicle.service";
import { TrakkerService } from "../_services/trakker.service";
import { AccountService } from "../_services/account.service";
import { LoaderService }  from "../loader.service";

import { Chart } from "chart.js";
import { Constants } from "../app.config";
import { VehiclesService } from "../_services/vehicles.service";
import { take, delay } from "rxjs/internal/operators";

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { registerLocaleData } from "@angular/common";
import locale from '@angular/common/locales/sv';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { now, utc } from "moment";
import { Vehicle } from "../_models/vehicle.model";
import { Console } from "console";

let dashboardSelf = null;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {

  currentPage: number = 1;
  // CHART.JS
  chart;
  chart2 = [];
  pie: any;
  doughnut: any;
  data1 = [];
  chartuse = [];
  stackedbar: any;
  currentPositions: any;
  currentlyExpandedPositionsId: any;

  userInfo: any;

  accountDetails: any;

  totalVehicleCount: any;
  trakkercount: any;
  trakkersRecentlyCount: number = 0;
  accountcount: any;
  geofencecount: any;
  groupcount: any;

  alertcount: number = 0;
  activeLoraTotal: number = 0;

  soscount: number = 0;
  batcount: number = 0;
  geocount: number = 0;
  entergeocount: number = 0;
  exitgeocount: number = 0;
  intersectgeocount: number = 0;
  vehiclecount: number = 0;
  operationtimecount: number = 0;
  inactivecount: number = 0;
  congestioncount: number = 0;
  alerts: Array<any>;

  trakkers: any;
  subscriptions: any = [];
  vehicleList: any;

  fuelPrices: any = [];
  currentMonthTotalFuelCost: number = 0;
  TotalAssetsCo2Kg: number = 0;
  currentMonthFleet: number = 0;
  currentMonthAssets: number = 0;
  showTotalCo2Loader:boolean = false;
  showTotalFuelCostLoader:boolean = false;

  editFuelPricesForm: FormGroup = new FormGroup({
    petrolFuelConsumptionInput: new FormControl("", Validators.required),
    dieselFuelConsumptionInput: new FormControl("", Validators.required),
    e85FuelConsumptionInput: new FormControl("", Validators.required),
    electricFuelConsumptionInput: new FormControl("", Validators.required),
    gasFuelConsumptionInput: new FormControl("", Validators.required),
    hvo100FuelConsumptionInput: new FormControl("", Validators.required)
  });

  get petrolFuelConsumptionInput() { return this.editFuelPricesForm.get("petrolFuelConsumptionInput"); }
  get dieselFuelConsumptionInput() { return this.editFuelPricesForm.get("dieselFuelConsumptionInput"); }
  get e85FuelConsumptionInput() { return this.editFuelPricesForm.get("e85FuelConsumptionInput"); }
  get electricFuelConsumptionInput() { return this.editFuelPricesForm.get("electricFuelConsumptionInput"); }
  get gasFuelConsumptionInput() { return this.editFuelPricesForm.get("gasFuelConsumptionInput"); }
  get hvo100FuelConsumptionInput() { return this.editFuelPricesForm.get("hvo100FuelConsumptionInput"); }

  isSubmitted: Boolean = false;
  operationTimes: any;

  vehicleSummary: any;
  inactiveVehicles: any;
  totalDistanceWorkKm: number = null;
  totalDistancePrivateKm: number = null;
  totalDistanceTotal: number = null;
  totalVehCo2Total: number = null;
  TotalCo2Kg: number = null;

  totalVehCo2TotalKg: number = null;
  totalCongestionTotal: number = null;

  hasYearlies: boolean = true;
  yearlyWorkArray: Array<number> = [];
  yearlyPrivateArray: Array<number> = [];
  activeLora = { today: 0, yesterday: 0, earlier: 0 }

  locale: string;
  monthlyVehicleSummary: any;
  monthNames: Array<string> = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  todaysmonth: number = null;

  isEditingDriverName: boolean = false;
  isEditingComment: boolean = false;
  isEditingOdometer: boolean = false;

  pickedVehicle: any;
  revealedVehicleEventId: any = null;
  lastStopEventId: any = null;

  distributeDifference: boolean = false;

  translationsAgo: any[] = [];
  dateTimeLocales: string = "en-gb";
  dateTimeOptions: any = { timeZone: "Europe/Stockholm" };

  // STEFAN MODAL CODE
  public modalTimer: any = null;
  public modal: string = null;
  selectedObject: any = null;
  trips: any = null;
  lastStop: any = null;

  color = {
    // TESTING
    // duty: '#233238', // navbar-gray
    // private: '#e0c568' // Cream Gold
    // private: '#f2aa4c', // ORANGE
    // private: '#5b84b1' // LIGHT BLUE
    // private: '#244896', // TRAKK BLUE
    // private: '#97bc62' // MOSS GREEN
    // private: '#00539c' // Royal Blue
    // 00203f // DARK GRAY
    // private: '#406B58' // Brown Sugar (#A07855FF)
    // private: '#964f43' // middle red
    // private: '#643e46' // dark red

    // NAVBAR BASED
    // duty: '#233238', // navbar-gray
    // private: '#556B73' // lighter tint

    // TRAKK BLUE AND LIGHTER TINT
    duty: "#244896", // trakk-blue
    private: "#617CB7", // lighter tint

    // private: '#4B5D61', // dark-grayish-blue
    // duty: '#5E7AB7' // pastel-blue
  };

  constructor(
    private localStore: LocalstoreService,
    private vehicleService: VehicleService,
    private trakkerService: TrakkerService,
    private accountService: AccountService,
    private vehiclesService: VehiclesService,
    public appConstants: Constants,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    //private loadingService: LoaderService
  ) {
    this.subscriptions.push(
      this.accountService.language.subscribe((lang) => {
        if (lang == "English") this.dateTimeLocales = "en-gb";
        if (lang == "Svenska") this.dateTimeLocales = "sv-se";
        this.UpdateTranslations();
        this.updateChartYearlyArray();
       // this.updateChartWorkingStatus();

       // update lora dougnut labels translation
       var loraLabels=null;
       this.subscriptions.push(
         this.translate.get(["Page.Labels.TODAY","Page.Labels.YESTERDAY","Page.Labels.EARLIER"]).subscribe((result) => {
           loraLabels= result;
           this.doughnut.data.labels = [loraLabels["Page.Labels.TODAY"], loraLabels["Page.Labels.YESTERDAY"], loraLabels["Page.Labels.EARLIER"]];
           this.doughnut.update();
         })
         );
        this.cdr.detectChanges();
      })
    );



    registerLocaleData(locale, 'sv');

    this.translationsAgo.push({
      key: "ABOUT_YEARS_AGO",
      value: "about { value } years ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_YEAR_AGO",
      value: "about 1 year ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MONTHS_AGO",
      value: "about { value } months ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MONTH_AGO",
      value: "about 1 month ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_DAYS_AGO",
      value: "about { value } days ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_DAY_AGO",
      value: "about 1 day ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_HOURS_AGO",
      value: "about { value } hours ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_HOUR_AGO",
      value: "about 1 hour ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_MINUTES_AGO",
      value: "about { value } minutes ago",
    });
    this.translationsAgo.push({
      key: "ABOUT_ONE_MINUTE_AGO",
      value: "about 1 minute ago",
    });
    this.translationsAgo.push({
      key: "A_FEW_SECONDS_AGO",
      value: "a few seconds ago",
    });

    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_YEAR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MONTH_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_HOUR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MINUTE_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS_AGO").value =
            result;
        })
    );
  }

  UpdateTranslations() {
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_YEARS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_YEARS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_YEAR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_YEAR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MONTHS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_MONTHS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MONTH_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MONTH_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_DAYS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_DAYS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_DAY_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_ONE_DAY_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_HOURS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_HOURS_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_HOUR_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_HOUR_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_MINUTES_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "ABOUT_MINUTES_AGO").value =
            result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.ABOUT_ONE_MINUTE_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find(
            (t) => t.key == "ABOUT_ONE_MINUTE_AGO"
          ).value = result;
        })
    );
    this.subscriptions.push(
      this.translate
        .get("Page.Messages.A_FEW_SECONDS_AGO")
        .pipe(take(1))
        .subscribe((result) => {
          this.translationsAgo.find((t) => t.key == "A_FEW_SECONDS_AGO").value =
            result;
        })
    );
    this.cdr.detectChanges();
  }

  ngOnInit() {
    let alarmboxelement = document.getElementById('alarmboxelement');
    this.renderer.listen(alarmboxelement, 'scroll', this.OnScroll);
    dashboardSelf = this;

    this.locale = this.translate.currentLang;
    this.translate.onLangChange
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
      })
    let options = {
      // aspectRatio: 1,
      // legend: false,
      tooltips: false,

      elements: {
        point: {
          borderWidth: function (context) {
            return Math.min(Math.max(1, context.datasetIndex + 1), 8);
          },
          hoverBackgroundColor: "transparent",
          hoverBorderColor: function (context) {
            return "red";
          },
          hoverBorderWidth: function (context) {
            var value = context.dataset.data[context.dataIndex];
            return Math.round((8 * value.v) / 1000);
          },
          radius: function (context) {
            var value = context.dataset.data[context.dataIndex];
            var size = context.chart.width;
            var base = Math.abs(value.v) / 1000;
            return (size / 24) * base;
          },
        },
      },
    };

    // if (this.translate.store.translations) {
    //   if (this.translate.currentLang == "sv") {
    //     this.pie = new Chart("pie", {
    //       type: "pie",
    //       options: {
    //         responsive: true,
    //         title: {
    //           display: true,
    //         },
    //         legend: {
    //           position: "top",
    //         },
    //         animation: {
    //           animateScale: true,
    //           animateRotate: true,
    //         },
    //       },
    //       data: {
    //         datasets: [
    //           {
    //             data: [1, 1],
    //             backgroundColor: [this.color.duty, this.color.private],
    //             label: "Dataset 1",
    //           },
    //         ],
    //         labels: ["Tjänst", "Privat"],
    //       },
    //     });
    //   }

    //   if (this.translate.currentLang == "en") {
    //     this.pie = new Chart("pie", {
    //       type: "pie",
    //       options: {
    //         responsive: true,
    //         title: {
    //           display: true,
    //         },
    //         legend: {
    //           position: "top",
    //         },
    //         animation: {
    //           animateScale: true,
    //           animateRotate: true,
    //         },
    //       },
    //       data: {
    //         datasets: [
    //           {
    //             data: [1, 1],
    //             backgroundColor: [this.color.duty, this.color.private],
    //             label: "Dataset 1",
    //           },
    //         ],
    //         labels: [
    //           this.translate.instant("Page.Labels.WORKING"),
    //           this.translate.instant("Page.Labels.PRIVATE"),
    //         ],
    //       },
    //     });
    //   }
    // }

    if (this.translate.store.translations) {
      this.stackedbar = new Chart("stackedbar", {
        type: "bar",
        data: {
          labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
          datasets: [
            {
              label: this.translate.instant("Page.Labels.WORKING"),
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: this.color.duty,
            },
            {
              label: this.translate.instant("Page.Labels.PRIVATE"),
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: this.color.private,
            },
          ],
        },
        options: {
          title: {},
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value + " km";
                  },
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      var loraLabels=null;
      this.subscriptions.push(
        this.translate.get(["Page.Labels.TODAY","Page.Labels.YESTERDAY","Page.Labels.EARLIER"]).subscribe((result) => {
          loraLabels= result;
          this.doughnut = new Chart("doughnut", {
            type: "doughnut",
            options: {
              responsive: true,
              maintainAspectRatio: true,
              cutoutPercentage: 70
            },
            data: {
              labels: [
                loraLabels["Page.Labels.TODAY"],
                loraLabels["Page.Labels.YESTERDAY"],
                loraLabels["Page.Labels.EARLIER"],
              ],
              datasets: [{
                data: [this.activeLora.today, this.activeLora.yesterday, this.activeLora.earlier],
                backgroundColor: [
                  'rgb(59, 207, 72)',
                  'rgb(236, 226, 78)',
                  'rgb(223, 164, 28)'
                ],
                hoverOffset: 4,
              }],
    
            }
          });
        })
      );   
    }

    // END STACKED BAR CHART

    this.accountDetails = this.localStore.get("accountDetails");

    // THE FOLLOWING TWO ROWS SHOULD NOT BE COMMENTED
    this.userInfo = this.localStore.get("accountDetails");
    // console.log("TLOG (dashboard-component): userinfo = accountDetails");

    // GET TRAKKER COUNT
    this.getTrakkerCount();
    // GET ACCOUNTS COUNT
    this.getAccountCount();
    // GET GEOFENCE COUNT
    this.getGeofenceCount();
    // GET GROUP COUNT
    this.getGroupCount();

    // GET FUEL PRICES
    this.getVehicleFuelTypePrices();


    //GET VEHICLES AND TOTALS FROM GET-VEHICLE-SUMMARY
    //this.getVehiclesWithTotals();

    //this.getOperationTimeUnits();

  }

  getOperationTimeUnits() {
    this.subscriptions.push(
      this.trakkerService.getAllOperationTimeUnits().subscribe((result) => {
        this.operationTimes = result;
        this.getOperationTimeCount(this.operationTimes);
      })
    );
  }

  getVehicleSummary() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // NEW POST CALL
    let trakkerIds = this.vehicleList.map(vehicle => vehicle.TrakkerId);
    var data = {
      FromDateTime: firstDay.toISOString(),
      ToDateTime: lastDay.toISOString(),
      TrakkerIds: trakkerIds,
    };
    // console.log(JSON.stringify(data));

    this.subscriptions.push(
      this.vehiclesService.getCompactVehiclesSummary(data).subscribe((result) => {
        this.vehicleSummary = result;
        this.summarizeVehicles();
        this.setSummaryPillar(11, result)
        this.getInactives();
        this.getCongestionTaxCount();
        this.getYearlyVehicleSummary();
      })
    );
  }

  getInactives() {
    this.inactivecount = 0;

    this.inactiveVehicles = [];
    if (this.vehicleSummary) {
      this.inactivecount = this.vehicleSummary.InactiveVehicles;
    }
  }

  getCongestionTaxCount() {
    this.congestioncount = 0;
    if (this.vehicleSummary) {
      this.congestioncount = this.vehicleSummary.TotalVehiclesWithCongestion
    }
  }

  getYearlyVehicleSummary() {
    var today = new Date();
    for (let index = 10; index >= 0; index--) {
      var month = new Date(
        today.getFullYear(),
        today.getMonth() - 11 + index,
        1
      );
      this.getVehicleSummaryForMonth(month, index);
    }
  }

  // RUNS GET VEHICLES ONCE FOR EACH MONTH (12 TIMES)
  getVehicleSummaryForMonth(fromDate: Date, index: number) {
    var toDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      0,
      23,
      59,
      59
    );
    let trakkerIds = this.vehicleList.map(vehicle => vehicle.TrakkerId);
    var data = {
      FromDateTime: fromDate.toISOString(),
      ToDateTime: toDate.toISOString(),
      TrakkerIds: trakkerIds,
    };

    this.subscriptions.push(
      this.vehiclesService.getCompactVehiclesSummary(data).subscribe((result) => {
        this.setSummaryPillar(index, result)
      })
    );
  }

  setSummaryPillar(index: number, result: any) {
    var monthlyWorkTotal = result.DistanceWorkKm;
    var monthlyPrivateTotal = result.DistancePrivateKm;


    this.yearlyWorkArray[index] = Math.round(monthlyWorkTotal * 10) / 10;
    this.yearlyPrivateArray[index] = Math.round(monthlyPrivateTotal * 10) / 10;

    this.updateChartYearlyArray();
  }

  summarizeVehicles() {
    var localTotalDistanceTotal = this.vehicleSummary.DistancePrivateKm + this.vehicleSummary.DistanceWorkKm;
    var localTotalCo2Total = this.vehicleSummary.Co2Private + this.vehicleSummary.Co2Work;
    var localTotalCongestionTotal = this.vehicleSummary.CongestionPrivate + this.vehicleSummary.CongestionWork;

    this.totalDistanceWorkKm = Math.round(this.vehicleSummary.DistanceWorkKm * 10) / 10;
    this.totalDistancePrivateKm = Math.round(this.vehicleSummary.DistancePrivateKm * 10) / 10;
    this.totalDistanceTotal = Math.round(localTotalDistanceTotal * 10) / 10;
    this.totalVehCo2Total = localTotalCo2Total;
    this.totalVehCo2TotalKg = Math.trunc(localTotalCo2Total / 1000);
    this.totalCongestionTotal = localTotalCongestionTotal;
    // this.updateChartWorkingStatus();
  }

  updateChartWorkingStatus() {
    // BOTH WORK AND PLAY
    if (this.totalDistanceWorkKm && this.totalDistancePrivateKm) {
      this.pie.data.datasets[0].data[0] = this.totalDistanceWorkKm;
      this.pie.data.datasets[0].data[1] = this.totalDistancePrivateKm;
      var working_perc =
        this.totalDistanceWorkKm /
        (this.totalDistanceWorkKm + this.totalDistancePrivateKm);
      var private_perc =
        this.totalDistancePrivateKm /
        (this.totalDistanceWorkKm + this.totalDistancePrivateKm);
      this.pie.data.labels[0] =
        this.translate.instant("Page.Labels.WORKING") +
        " (" +
        Math.round(working_perc * 100) +
        "%)";
      this.pie.data.labels[1] =
        this.translate.instant("Page.Labels.PRIVATE") +
        " (" +
        Math.round(private_perc * 100) +
        "%)";
      this.pie.update();
    }

    // ALL WORK AND NO PLAY
    if (this.totalDistanceWorkKm && !this.totalDistancePrivateKm) {
      if (this.pie) {
        this.pie.data.datasets[0].data[0] = this.totalDistanceWorkKm;
        this.pie.data.datasets[0].data[1] = this.totalDistancePrivateKm;
        var working_perc = 1;
        var private_perc = 0;
        this.pie.data.labels[0] =
          this.translate.instant("Page.Labels.WORKING") +
          " (" +
          Math.round(working_perc * 100) +
          "%)";
        this.pie.data.labels[1] =
          this.translate.instant("Page.Labels.PRIVATE") +
          " (" +
          Math.round(private_perc * 100) +
          "%)";
        this.pie.update();
      }
    }

    // ALL PLAY AND NO WORK
    if (!this.totalDistanceWorkKm && this.totalDistancePrivateKm) {
      this.pie.data.datasets[0].data[0] = this.totalDistanceWorkKm;
      this.pie.data.datasets[0].data[1] = this.totalDistancePrivateKm;
      var working_perc = 0;
      var private_perc = 1;
      this.pie.data.labels[0] =
        this.translate.instant("Page.Labels.WORKING") +
        " (" +
        Math.round(working_perc * 100) +
        "%)";
      this.pie.data.labels[1] =
        this.translate.instant("Page.Labels.PRIVATE") +
        " (" +
        Math.round(private_perc * 100) +
        "%)";
      this.pie.update();
    }
  }

  updateChartYearlyArray() {
    var today = new Date();
    for (let index = 0; index < 12; index++) {
      var monthIndex = new Date(
        today.getFullYear(),
        today.getMonth() - 11 + index,
        1
      ).getMonth();
      this.stackedbar.data.labels[index] = this.translate.instant(
        "Page.Labels." + this.monthNames[monthIndex].toUpperCase()
      );
    }
    this.stackedbar.data.datasets[0].data = this.yearlyWorkArray;
    this.stackedbar.data.datasets[0].label = this.translate.instant(
      "Page.Labels.WORKING"
    );
    this.stackedbar.data.datasets[1].data = this.yearlyPrivateArray;
    this.stackedbar.data.datasets[1].label = this.translate.instant(
      "Page.Labels.PRIVATE"
    );
    this.stackedbar.update();
  }

  getVehicleCount(vehicleList) {
    var vehcount = 0;
    vehicleList.forEach(function (item) {
      vehcount += 1;
    });
    this.vehiclecount = vehcount;
  }

  getOperationTimeCount(operationTimesList) {
    var operationtimecount = 0;
    operationTimesList.forEach(function (item) {
      operationtimecount += 1;
    });
    this.operationtimecount = operationtimecount;
  }

  getTrakkerCount() {
    this.subscriptions.push(
      this.trakkerService.getTrakkers().subscribe((result) => {
        // TRAKKER COUNT
        this.trakkers = result;
        this.trakkercount = result.length;

        // Vehicles
        this.vehicleList = result
          .map(item => item.Vehicles[0])
          .filter(x => x !== undefined)
        this.getVehicleCount(this.vehicleList);

        if (this.vehicleList.length > 0) {
          this.getVehicleSummary();
        } else {
          this.hasYearlies = false;
        }

        // Assets
        this.operationTimes = result.map(t => t.Asset).filter(x => x != null);
        this.getOperationTimeCount(this.operationTimes);

        // Active Loras
        this.CalcActiveLoras(result);
        this.doughnut.data.datasets[0].data[0] = this.activeLora.today;
        this.doughnut.data.datasets[0].data[1] = this.activeLora.yesterday;
        this.doughnut.data.datasets[0].data[2] = this.activeLora.earlier;
        this.activeLoraTotal = this.activeLora.today + this.activeLora.yesterday + this.activeLora.earlier;
        this.doughnut.update();

        //current month fleet
        // this.currentMonthFleet = result.map(r=> r.Vehicles).filter(v=> v != null && v.length > 0 && v.filter(vh=> new Date(vh.CreatedOn).getMonth() == new Date("2020-05-14T19:51:00Z").getMonth() && new Date(vh.CreatedOn).getFullYear() == new Date("2020-05-14T19:51:00Z").getFullYear()).length > 0 )
        if(result && result.length > 0)
        this.currentMonthFleet = result.map(r => r.Vehicles).filter(v => v != null && v.length > 0).length ?? 0;

        //current month units
        if(result && result.length > 0)
        this.currentMonthAssets = result.filter(o => o.Asset != null).length ?? 0;

        //Total fuel cost on current month
        this.CalcTotalFuelCost();
         
        //Total asset co2 on current month
        this.CalcTotalAssetsCo2();
 
        // TRAKKER COUNT (LAST 2 MONTHS)
        var curDate = new Date();
        curDate.setUTCMonth(curDate.getUTCMonth() - 2);
        var stringDate = curDate.toISOString();
        var count = 0;
        result.forEach(function (arrayItem) {
          var x = arrayItem.CreatedOn;
          if (x > stringDate) {
            count++;
          }
        });
        this.trakkersRecentlyCount += count;

        // GET TRAKKER ACTIVE ALERTS
        this.trakkerService.getTrakkersActiveAlertsForAccount().subscribe(activeAlerts => {
          var currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - 60);
          var compareTime = currentDate.getTime();
          activeAlerts = activeAlerts.filter(x => new Date(x.TrakkerEvent.OccuredOn).getTime() > compareTime && x.TrakkerEvent.AlertType != "EnterGeofence");

          // SOS COUNT
          var sCount = 0;
          var batCount = 0;
          var geoCount = 0;
          var enterGeoCount = 0;
          var exitGeoCount = 0;
          var intersectGeoCount = 0;
          let bigArray = [];

          activeAlerts.forEach(function (alertItem) {
            let trakkerAlerts = [];
            if (alertItem.TrakkerEvent.TrakkerEventType == "Alert") {
              if (alertItem.TrakkerEvent.AlertType == "SOS") {
                sCount += 1;
              }
              if (alertItem.TrakkerEvent.AlertType == "LowBattery") {
                batCount += 1;
              }
              if (alertItem.TrakkerEvent.AlertType == "ExitGeofence") {
                exitGeoCount += 1;
              }

              trakkerAlerts = [...trakkerAlerts, alertItem];
            }

            if (trakkerAlerts.length > 0) {
              bigArray = [...bigArray, ...trakkerAlerts];
            }
            if (result) {
              var trakker = result.find(x => x.TrakkerId == alertItem.TrakkerEvent.TrakkerId);
              alertItem.TrakkerName = trakker?.Name;
              alertItem.IconUrl = trakker?.Icon.Url;
            }
          });

          this.soscount += sCount;
          this.batcount += batCount;
          this.entergeocount += enterGeoCount;
          this.exitgeocount += exitGeoCount;
          this.intersectgeocount += intersectGeoCount;
          this.geocount += enterGeoCount + exitGeoCount + intersectGeoCount;

          this.alertcount = sCount + batCount + this.geocount;
          // Sort in time order
          bigArray.sort((a, b) => {
            const timeA = new Date(a.TrakkerEvent.OccuredOn).getTime();
            const timeB = new Date(b.TrakkerEvent.OccuredOn).getTime();
            return (timeA > timeB && -1) || 1;
          });
          this.alerts = bigArray;
        });
      })
    )
  }

  // STEFAN's CODE FROM MAP COMPONENT FOR FINDING WHICH GEOFENCE ARE TRIGGERED
  // CHECK WITH STEFAN; how is TriggeredGeofences fetched?

  // private AddTriggeredGeofenceData(t: any) {
  //         if (t.TriggeredGeofences != null && t.TriggeredGeofences.length > 0) {
  //             t.TriggeredGeofences.forEach(triggeredGeofence => {
  //                 var a = t.ActiveAlerts.find(a => a.TrakkerEventId == triggeredGeofence.TrakkerEventId);
  //                 if (a != null) {
  //                     var geofence = this.geofenceList.find(g => g.GeofenceId == triggeredGeofence.GeofenceId);
  //                     if (geofence != null) {
  //                         a.TriggeredGeofenceName = geofence.Name;
  //                         a.TriggeredGeofenceId = geofence.GeofenceId;
  //                         triggeredGeofence.GeofenceType = geofence.GeofenceType;
  //                         if (geofence.Triggered == null) {
  //                             geofence.Triggered = [];
  //                         }
  //                         if (geofence.Triggered.find(t=>t.TrakkerId == t.TrakkerId) == null)
  //                         {
  //                             geofence.Triggered.push(t);
  //                         }
  //                     }
  //                 }
  //             });
  //         }
  //     }

  getAccountCount() {
    this.subscriptions.push(
      this.accountService.getAccounts().subscribe((result) => {
        this.accountcount = result.length;
      })
    );
  }

  getGeofenceCount() {
    this.subscriptions.push(
      this.accountService.getAllGeofences().subscribe((result) => {
        this.geofencecount = result.length;
      })
    );
  }

  getGroupCount() {
    this.subscriptions.push(
      this.accountService.getAllGroups().subscribe((result) => {
        this.groupcount = result.length;
      })
    );
  }

  startEditDriverName(vehicle) {
    this.selectedObject = vehicle;
    this.modal = "editDriver";
    setTimeout(() => {
      $(".modalWrapper .defaultAction").focus();
    }, 1);

    this.isEditingComment = false;
    this.isEditingOdometer = false;
  }

  saveEditDriverName(vehicle) {
    var form = $("#vehicle-driver");
    var driver = form.find(".inputDriverName").val();
    var comment = form.find(".inputDriverComment").val();
    var data = { Driver: driver, Comment: comment };
    this.subscriptions.push(
      this.vehiclesService
        .updateDriver(this.pickedVehicle.TrakkerId, data)
        .subscribe((result) => {
          this.pickedVehicle.Driver = data.Driver;
        })
    );
    this.isEditingDriverName = false;
    this.modal = null;
  }

  startEditComment() {
    this.isEditingComment = true;
    this.isEditingDriverName = false;
    this.isEditingOdometer = false;
  }

  cancelEditComment() {
    this.isEditingComment = false;
    this.isEditingDriverName = false;
    this.isEditingOdometer = false;
  }

  saveEditComment() {
    this.isEditingComment = false;
    var field = $("#trip-comment");
    var comment = field.find(".inputTripComment").val();
    // console.log(comment);
    var data = { Input: comment };

    // UPDATING LAST TRIP
    this.subscriptions.push(
      this.vehiclesService
        .updateVehicleEventComment(this.revealedVehicleEventId, data)
        .subscribe((result) => {
          this.trips.Data[0].Comment = comment;
        })
    );

    // UPDATING LAST STOP
    this.subscriptions.push(
      this.vehiclesService
        .updateVehicleEventComment(this.lastStopEventId, data)
        .subscribe((result) => {
          this.lastStop.Data[0].Comment = comment;
        })
    );
  }

  getVehicleFuelTypePrices() {
    this.subscriptions.push(
      this.vehiclesService
        .getFuelPrices()
        .subscribe((result) => {
          this.fuelPrices = result.ResponseObject;
        })
    );
  }

  startEditOdometer(pickedVehicle) {
    this.selectedObject = pickedVehicle;
    this.modal = "editOdometer";
    setTimeout(() => {
      $(".modalWrapper .defaultAction").focus();
    }, 1);

    this.isEditingDriverName = false;
    this.isEditingComment = false;
  }

  toggleDistributeDifference() {
    this.distributeDifference = !this.distributeDifference;
  }

  saveEditOdometer(vehicle) {
    var form = $("#vehicle-odometer");
    var odometerKilometer = form.find(".inputOdometer").val();
    var comment = form.find(".inputOdometerComment").val();
    var data = {
      OdometerKilometer: odometerKilometer,
      Comment: comment,
      DistributeDifference: this.distributeDifference,
    };
    this.subscriptions.push(
      this.vehiclesService
        .updateOdometer(this.pickedVehicle.TrakkerId, data)
        .subscribe((result) => {
          this.pickedVehicle.OdometerKilometer = data.OdometerKilometer;
        })
    );
    this.modal = null;
    this.isEditingOdometer = false;
  }

  startEditFuelPrices() {
    this.modal = "editFuelPrices";
    setTimeout(() => {
      $(".modalWrapper .defaultAction").focus();
    }, 1);
  }

  saveEditFuelPrices() {
    this.isSubmitted = true;
    const petrol = parseFloat(this.petrolFuelConsumptionInput.value);
    const diesel = parseFloat(this.dieselFuelConsumptionInput.value);
    const e85 = parseFloat(this.e85FuelConsumptionInput.value);
    const electric = parseFloat(this.electricFuelConsumptionInput.value);
    const gas = parseFloat(this.gasFuelConsumptionInput.value);
    const hvo100 = parseFloat(this.hvo100FuelConsumptionInput.value);

    var data = { Petrol: petrol, Diesel: diesel, E85: e85, Electric: electric, Gas: gas, Hvo100: hvo100 };

    if (!isNaN(petrol) && !isNaN(diesel) && !isNaN(e85) && !isNaN(electric) && !isNaN(gas) && !isNaN(hvo100)) {
      this.subscriptions.push(
        this.vehiclesService
          .updateFuelTypePrices(data)
          .subscribe((result) => {
            this.fuelPrices = result;
            this.clearFuelPricesModal();
          })
      );
    }
  }

  clearFuelPricesModal() {
    this.modal = null;
    this.isSubmitted = false;
  }

  CancelModal() {
    this.modal = null;
    this.clearFuelPricesModal()
  }

  pickVehicle(vehicle) {
    this.trips = null;
    this.lastStop = null;
    this.revealedVehicleEventId = null;
    this.lastStopEventId = null;
    this.pickedVehicle = vehicle;
    this.isEditingDriverName = false;
    this.isEditingComment = false;
    this.isEditingOdometer = false;
    this.getTrips(vehicle.TrakkerId);
    this.getLastStop(vehicle.TrakkerId);
  }
  unpickVehicle() {
    this.pickedVehicle = null;
    this.isEditingDriverName = false;
    this.isEditingComment = false;
    this.isEditingOdometer = false;
    this.trips = null;
    this.lastStop = null;
    this.revealedVehicleEventId = null;
    this.lastStopEventId = null;
  }

  getTrips(trakkerId) {
    var filterUrl = "?filter.vehicleEventTypes=Trip";
    var pagerUrl = "&filter.searchCount=1&filter.page=0";

    this.subscriptions.push(
      this.vehiclesService
        .getVehicleEvents(trakkerId, filterUrl, pagerUrl)
        .subscribe((result) => {
          this.trips = result;
          if (result.Data[0] && result.Data[0].VehicleEventId != null) {
            this.revealedVehicleEventId = result.Data[0].VehicleEventId;
          }
        })
    );
  }

  getLastStop(trakkerId) {
    var filterUrl = "?filter.vehicleEventTypes=Stop";
    var pagerUrl = "&filter.searchCount=1&filter.page=0";

    this.subscriptions.push(
      this.vehiclesService
        .getVehicleEvents(trakkerId, filterUrl, pagerUrl)
        .subscribe((result) => {
          this.lastStop = result;
          if (result.Data[0] && result.Data[0].VehicleEventId != null) {
            this.lastStopEventId = result.Data[0].VehicleEventId;
          }
        })
    );
  }

  tryToggleWorkingStatus(trakkerId, workingStatus) {
    if (workingStatus == "WorkRelatedUse") {
      var data = { Status: "PrivateUse" };
    } else if (workingStatus == "PrivateUse") {
      var data = { Status: "WorkRelatedUse" };
    } else {
      return;
    }
    this.subscriptions.push(
      this.vehiclesService
        .updateWorkingStatus(trakkerId, data)
        .subscribe((result) => {
          if (workingStatus == "WorkRelatedUse") {
            this.pickedVehicle.WorkingStatus = "PrivateUse";
          } else if (workingStatus == "PrivateUse") {
            this.pickedVehicle.WorkingStatus = "WorkRelatedUse";
          }
        })
    );
  }

  hasVehicles() {
    if (this.vehicleList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterInit() { }

  updateLine(line, vehcount) {
    line.data.datasets[0].data[3] = vehcount;
    line.update();
  }

  removeSos(alert) {
    const eventId = alert.TrakkerEvent.TrakkerEventId;

    this.subscriptions.push(
      this.trakkerService.deleteTrakkerAlert(eventId).subscribe((result) => {
        this.alerts.forEach((item, index) => {
          if (
            item.TrakkerEvent.TrakkerEventId ===
            alert.TrakkerEvent.TrakkerEventId
          ) {
            this.alerts.splice(index, 1);
            this.alertcount--;

            if (item.TrakkerEvent.AlertType == "SOS") {
              this.soscount -= 1;
            }
            if (item.TrakkerEvent.AlertType == "LowBattery") {
              this.batcount -= 1;
            }
            if (
              item.TrakkerEvent.AlertType == "ExitGeofence" ||
              item.TrakkerEvent.AlertType == "EnterGeofence" ||
              item.TrakkerEvent.AlertType == "IntersectGeofence"
            ) {
              this.geocount -= 1;
            }
          }
        });
      })
    );
  }

  OnScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight * .9) {
      dashboardSelf.currentPage++;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  changeTheme(id) {
    this.appConstants.SetTheme(id);
  }


  CalcActiveLoras(trakker) {

    if(!trakker || trakker.length == 0)
    return;

    // Get the current date
    let currentDate = new Date();
    let currentDateFormatted = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD

    // Calculate the date for yesterday
    let yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    let yesterdayFormatted = yesterday.toISOString().slice(0, 10);


    // Filter for today, yesterday, and before yesterday
    let todayLoras = trakker.filter(o => o.Asset != null && o.LastContact != null && new Date(o.LastContact).toISOString().slice(0, 10) == currentDateFormatted);
    let yesterdayLoras = trakker.filter(o => o.Asset != null && o.LastContact != null && new Date(o.LastContact).toISOString().slice(0, 10) == yesterdayFormatted);
    let earlierLoras = trakker.filter(o => o.Asset != null && o.LastContact != null && new Date(o.LastContact).toISOString().slice(0, 10) < yesterdayFormatted);

    this.activeLora.today = todayLoras.length ?? 0;
    this.activeLora.yesterday = yesterdayLoras.length ?? 0;
    this.activeLora.earlier = earlierLoras.length ?? 0;

  }

  CalcTotalFuelCost() {
    if (!this.trakkers || this.trakkers.length == 0)
      return;
    this.showTotalFuelCostLoader = true;
    this.cdr.detectChanges();

    var trakkerIds = this.trakkers.map(t => t.TrakkerId);
    var data = {
      FromDateTime: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0).toISOString(),
      ToDateTime: new Date().toISOString(),
      TrakkerIds: trakkerIds,
    };
    this.subscriptions.push(
      this.vehiclesService.getVehiclesSummary(data).subscribe((result) => {
        if (result && result.length > 0)
          this.currentMonthTotalFuelCost = result.reduce((total, r) => total + r.FuelCostTotal, 0);
      },
      (error) => {
        // Handle errors
        console.error('Error occurred:', error);
        this.showTotalFuelCostLoader = false;
        this.cdr.detectChanges();
      },
      () => {
        // Hide the loader when the subscription is complete
        this.showTotalFuelCostLoader = false;
        this.cdr.detectChanges();
      }
      )
    );
  }

  CalcTotalAssetsCo2() {
    if (!this.trakkers || this.trakkers.length == 0)
      return;

      this.showTotalCo2Loader = true;
      this.cdr.detectChanges();

    //  this.LoaderService.setLoading(true);
      let co2G:number =0;
    var assetIds = this.trakkers.filter(t=>t.Asset != null).map(t => t.Asset.AssetId);
    var data = {
      FromDateTime: new Date(new Date().getFullYear(), new Date().getMonth()-1, 1, 0, 0, 0).toISOString(), // To Do get back to current month
      ToDateTime: new Date().toISOString(),
      AssetIds: assetIds,
    };
    this.subscriptions.push(
      this.trakkerService.getOperationTimeSummary(data).subscribe(
        (result) => {
        if (result && result.length > 0){
          co2G = result.reduce((total, r) => total + r.TotalCO2, 0);
          this.TotalAssetsCo2Kg = Math.trunc(co2G / 1000);
       }
      },
      (error) => {
        // Handle errors
        console.error('Error occurred:', error);
        this.showTotalCo2Loader = false;
        this.cdr.detectChanges();
      },
      () => {
        // Hide the loader when the subscription is complete
        this.showTotalCo2Loader = false;
        this.cdr.detectChanges();
      }
      )
    );
  }
}
