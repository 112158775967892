import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-info-box",
  templateUrl: "./info-box.component.html",
  styleUrls: ["./info-box.component.scss"],
})
export class InfoBoxComponent implements OnInit {
  @Input() type: string = "";
  @Input() header: string = "";
  @Input() body: string;
  constructor() {}

  ngOnInit(): void {}

  getBackgroundClass() {
    return {
      'alert': this.type === 'alert',
    };
  }
}
