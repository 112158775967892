<div class="cont">


<h2 [translate]>Page.Labels.ABOUT</h2>

<p [translate]>Page.Messages.TRAKK_PRESENTATION</p>

<div class="centerform">

  <h3 [translate]>Page.Labels.CONTACT_US</h3>

  <div class="basic-container">

    <form [formGroup]="messageForm" (ngSubmit)="onSubmit()" class="form" [translate]>

      <h5 *ngIf="success">Your form is valid!</h5>

      <label [translate]="'Page.Labels.NAME'">
        Name
        <br><input type="text" formControlName="name">
        <div *ngIf="submitted && messageForm.controls.name.errors" class="error">
          <div *ngIf="messageForm.controls.name.errors.required" class="" [translate]>MESSAGE.FIRST_NAME_REQUIRED</div>
        </div>
      </label>

      <br><br>

        <label [translate]="'Page.Labels.MESSAGE'">
        Message
          <br><textarea name="name" rows="8" cols="50" formControlName="message" class="messfield"></textarea>
          <div *ngIf="submitted && messageForm.controls.message.errors" class="error">
            <div *ngIf="messageForm.controls.message.errors.required" class="" [translate]>MESSAGE.MESSAGE_REQUIRED</div>
          </div>
        </label>

        <br><br>

        <button type="submit" class="cta" [translate]="'Page.Buttons.SEND_MESSAGE'">Send message</button>

    </form>


    <div *ngIf="submitted" class="results">
      <strong>Name</strong>
      <span>{{ messageForm.controls.name.value }}</span>
      <strong>Message</strong>
      <span>{{ messageForm.controls.message.value }}</span>
    </div>

  </div>


</div>



</div>
<!-- <div>Angular {{version}}</div> -->