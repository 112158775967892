import { Observable } from "rxjs";


export type ILanguage = 'Svenska' | 'English' | 'Undefined';
export interface IAccount {
        AccountId: number;
        CustomerId: number;
        Name: string;
        Email: string;
        LoginName: string;
        Role: 'AccountOwner'| 'AccountAdministrator'| 'User'| 'Driver'| 'Guest';
        ValidUntil: string;
        IsEmailConfirmed: boolean;
        IsActive: boolean;
        IsLockedOut: boolean;
        IsDeleted: boolean;
        HomeCoordinate: IPosition;
        SpeedUnit: string;
        Language: ILanguage;
        TimeZone: ITimeZone;
        ContactId: number;
        CustomerAdminName: string;
}



export interface IPosition {
    PositionId: number;
    Latitude: number;
    Longitude: number;
    GeocodedPositionId?: number
}

export interface ITimeZone {
    TimeZoneId: string;
    DisplayName: string;
    UtcOffsetHours: number;
    UtcOffsetMinutes: number
}


export abstract class Account {
    abstract getAccount(): Observable<IAccount>;
}
