import { Pipe, PipeTransform } from "@angular/core";

/**
 * Takes seconds and converts it to readable format
 * @Input seconds
 * @Output string
 */

@Pipe({
  name: "otDurationPipe3",
})
export class OtDurationPipe3 implements PipeTransform {
  transform(value: number): string {
    const duration = Math.round(value);
    if (duration < 1) return "-";

    const days: Number = Math.floor((((duration / 60) / 60) / 24));
    const hours: number = Math.floor(((duration / 60) / 60) % 24);
    const minutes: number = Math.floor(((duration / 60) % 60));
    const seconds: number = Math.floor(duration % 60);

    const dayStr = days ? `${days}d ` : "";
    const hourStr = hours ? `${hours}h ` : "";
    const minuteStr = minutes || hours ? `${minutes}m ` : "";
    const secondStr = seconds || hours || minutes ? `${seconds}s` : "";

    return `${dayStr}${hourStr}${minuteStr}${secondStr}`;

  }
}