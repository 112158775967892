import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapRoutingModule } from './map-routing.module';
import { MapComponent } from './map.component';
import { SharedModule } from '../_shared/shared.module';
import { MapCommonFunctionService } from '../_services/map-common-function.service';
import { CreategeofenceComponent } from './create-geofence/creategeofence/creategeofence.component';

@NgModule({
  declarations: [
    MapComponent,
    CreategeofenceComponent
  ],
  imports: [
    CommonModule,
    MapRoutingModule,
    SharedModule,

  ],
  exports: [
    MapComponent,
  ],
  providers: [
    MapCommonFunctionService
  ]
})
export class MapModule { }
