import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterSubscription'
})


export class FilterSubscriptionPipe implements PipeTransform {
  transform(items: any[], searchSubscriptions: string): any[] {
    searchSubscriptions = searchSubscriptions ? searchSubscriptions.toLocaleLowerCase() : null;
    return searchSubscriptions ? items.filter((item: any) =>
      item.Contact?.Name.toLocaleLowerCase().indexOf(searchSubscriptions) !== -1
    ) : items;

   }
}
