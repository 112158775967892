import { Injectable } from '@angular/core';
import { Constants } from './../app.config';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { LocalstoreService } from '../_shared/localstore.service';
//import * as moment from 'moment'; // HMM
import moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	public windowResized: Subject<Object> = new Subject();
	public fareOverlayChanged: Subject<Object> = new Subject();
	public timeDisplayFormat: any;
	public applicationSettings: any;
	public parameters: any;

	constructor(
		private appConstants: Constants,
		private localStorage: LocalstoreService,
	) {
		let params = new HttpParams();
		params = params.append('external', 'COMMON');
		this.parameters = { params: params };
	}


	getBase64ProfilePic(extension, string) {
		let imageMimeType = 'image/png';
		if (extension === 'jpg') {
			imageMimeType = 'image/jpeg';
		} else if (extension === 'svg') {
			imageMimeType = 'image/svg+xml;charset=utf-8';
		}
		return 'data:' + imageMimeType + ';base64,' + string;
	}

	getStringFromBase64(base64) {
		const base64Result = base64.split(',');
		let extension = 'png';
		if (
			base64Result[0] === 'data:image/jpeg;base64' ||
			base64Result[0] === 'data:image/jpg;base64'
		) {
			extension = 'jpg';
		} else if (
			base64Result[0] === 'data:image/svg+xml;base64' ||
			base64Result[0] === 'data:image/svg;base64'
		) {
			extension = 'svg';
		}
		return {
			imageString: base64Result[1],
			mimeType: extension
		};
	}

	// Validating image size
	validateImageSize(file: File, validFileSize: number) {
		if (file) {
			if (file.size > validFileSize) {
				return true;
			}
		}
		return false;
	}

	getDefaultTimeFormat() {
		this.applicationSettings = this.localStorage.get('applicationSettings');
		this.timeDisplayFormat = _.find(this.applicationSettings, {
			key: 'TIME_DISPLAY_FORMAT'
		});
		if (
			this.timeDisplayFormat.value !== '' ||
			this.timeDisplayFormat.value !== undefined ||
			this.timeDisplayFormat.value != null
		) {
			if (this.timeDisplayFormat.value === '12') {
				return 'hh:mm A';
			} else if (this.timeDisplayFormat.value === '24') {
				return 'HH:mm';
			} else {
				return 'HH:mm';
			}
		}
	}

	convertUnixToLocalDate(epoch: number) {
		return moment(
			moment
				.unix(epoch)
				.utc()
				.format(this.appConstants.dateConversionFormatForDatePicker)
		).toDate();
	}

	convertLocalDateToUnix(date: any) {
		return moment
			.utc(
				moment(date).format(
					this.appConstants.dateConversionFormatForDatePicker
				)
			)
			.unix();
	}

	markFormGroupTouched(formGroup: FormGroup) {
		_.forEach(formGroup.controls, (control: any, index: any) => {
			control.markAsTouched();
			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
		formGroup.markAsTouched();
		formGroup.markAsDirty();
		formGroup.updateValueAndValidity();
	}


}
