import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'objectPicker',
  templateUrl: './object-picker.component.html',
  styleUrls: ['./object-picker.component.scss']
})
export class ObjectPickerComponent implements OnInit {

  // Component for adding item to a target

  @Input() items: any; // contact, geofence, unit, account (ContactId, TrakkerId)
  @Input() target: any; // contact, group, job, geofence, unit
  @Input() itemtype: string;
  @Input() targettype: string;

  constructor() { }

  ngOnInit(): void {
    console.log("itemtype ");
    console.log(this.itemtype);
    console.log("items ");
    console.log(this.items);
    console.log("targettype ");
    console.log(this.targettype);
    console.log("target ");
    console.log(this.target);

    // this.itemtype = 'UNIT'
    // this.targettype = 'GROUP'
    if (this.items.length > 0) {

    }
  }

  closeObjectPicker() {
    console.log("Close this window");
    // close popup
  }

}


// CONTACT: ContactId, AccountId ---- 
// GEOFENCE: GeofenceId ---- Check if it has GeofenceId
// ACCOUNT: AccountId, CustomerId, ContactId
// UNIT/TRAKKER: TrakkerId, CustomerId, 
