<ng-container *ngIf="outputType == 'icon'">
  <ng-container *ngIf="event != null && objectType == 'trakker'">
    <img *ngIf="isOffGrid == true" class="svg" src="assets/icons/svg/trakker-events/off-grid.svg" ngbTooltip="{{ 'Page.Labels.OFF_GRID' | translate }}"/>
    <ng-container *ngIf="isOffGrid == false">
      <ng-container *ngIf="event.TrakkerEventType != 'Alert'">
        <ng-container [ngSwitch]="event.TrakkerEventType">
          <img *ngSwitchCase="'Heartbeat'" class="svg" src="assets/icons/svg/trakker-events/heartbeat.svg" ngbTooltip="{{ 'Page.Labels.HEARTBEAT' | translate }}"/>
          <img *ngSwitchCase="'Battery'" class="svg" src="assets/icons/svg/battery-100.svg" ngbTooltip="{{ 'Page.Labels.BATTERY' | translate }}"/>
          <ng-container *ngSwitchCase="'Position'">
            <img *ngIf="event.Speed != null && event.Speed <= 0" class="svg" src="assets/icons/svg/trakker-events/position.svg" ngbTooltip="{{ 'Page.Labels.POSITION' | translate }}"/>
            <img *ngIf="event.Speed != null && event.Speed > 0" class="svg" src="assets/icons/svg/trakker-events/position-in-motion.svg" ngbTooltip="{{ 'Page.Labels.POSITION_IN_MOTION' | translate }}"/>
          </ng-container>
          <img *ngSwitchCase="'Active'" class="svg" src="assets/icons/svg/trakker-events/active.svg" ngbTooltip="{{ 'Page.Labels.ACTIVE' | translate }}"/>
          <img *ngSwitchCase="'Parking'" class="svg" src="assets/icons/svg/trakker-events/parking.svg" ngbTooltip="{{ 'Page.Labels.PARKING' | translate }}"/>
          <img *ngSwitchDefault class="svg" src="assets/icons/svg/trakker-events/position.svg" ngbTooltip="{{ 'Page.Labels.POSITION' | translate }}"/>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="event.TrakkerEventType == 'Alert'">
        <ng-container [ngSwitch]="event.AlertType">
        <img *ngSwitchCase="'SOS'" class="svg " src="assets/icons/svg/trakker-events/sos.svg" ngbTooltip="{{ 'Page.Labels.SOS' | translate }}"/>
        <img *ngSwitchCase="'LowBattery'" class="svg " src="assets/icons/svg/trakker-events/low-battery.svg" ngbTooltip="{{ 'Page.Labels.LOWBATTERY' | translate }}"/>
        <img *ngSwitchCase="'AGPS'" class="svg " src="assets/icons/svg/trakker-events/agps.svg" ngbTooltip="{{ 'Page.Labels.AGPS' | translate }}"/>
        <img *ngSwitchCase="'ExitGeofence'" class="svg " src="assets/icons/svg/trakker-events/exit-geofence.svg" ngbTooltip="{{ 'Page.Labels.EXITGEOFENCE' | translate }}"/>
        <img *ngSwitchCase="'EnterGeofence'" class="svg " src="assets/icons/svg/trakker-events/enter-geofence.svg" ngbTooltip="{{ 'Page.Labels.ENTERGEOFENCE' | translate }}"/>
        <img *ngSwitchCase="'IntersectGeofence'" class="svg " src="assets/icons/svg/trakker-events/intersect-geofence.svg" ngbTooltip="{{ 'Page.Labels.INTERSECTGEOFENCE' | translate }}"/>
        <img *ngSwitchCase="'PowerOn'" class="svg " src="assets/icons/svg/trakker-events/power-on.svg" ngbTooltip="{{ 'Page.Labels.POWER_ON' | translate }}"/>
        <img *ngSwitchCase="'PowerOff'" class="svg " src="assets/icons/svg/trakker-events/power-off.svg" ngbTooltip="{{ 'Page.Labels.POWER_OFF' | translate }}"/>
        <img *ngSwitchCase="'IgnitionOn'" class="svg " src="assets/icons/svg/trakker-events/ignition-on.svg" ngbTooltip="{{ 'Page.Labels.IGNITION_ON' | translate }}"/>
        <img *ngSwitchCase="'IgnitionOff'" class="svg " src="assets/icons/svg/trakker-events/ignition-off.svg" ngbTooltip="{{ 'Page.Labels.IGNITION_OFF' | translate }}"/>
        <img *ngSwitchCase="'PowerSupplyOn'" class="svg " src="assets/icons/svg/trakker-events/power-supply-on.svg" ngbTooltip="{{ 'Page.Labels.POWER_SUPPLY_ON' | translate }}"/>
        <img *ngSwitchCase="'PowerSupplyOff'" class="svg " src="assets/icons/svg/trakker-events/power-supply-off.svg" ngbTooltip="{{ 'Page.Labels.POWER_SUPPLY_OFF' | translate }}"/>
        <img *ngSwitchCase="'LowTemperature'" class="svg " src="assets/icons/svg/trakker-events/low-temperature.svg" ngbTooltip="{{ 'Page.Labels.LOW_TEMPERATURE' | translate }}"/>
        <img *ngSwitchCase="'HighTemperature'" class="svg " src="assets/icons/svg/trakker-events/high-temperature.svg" ngbTooltip="{{ 'Page.Labels.HIGH_TEMPERATURE' | translate }}"/>
        <img *ngSwitchCase="'Temperature'" class="svg " src="assets/icons/svg/trakker-events/temperature.svg" ngbTooltip="{{ 'Page.Labels.TEMPERATURE' | translate }}"/>
        <img *ngSwitchCase="'AlcoholOk'" class="svg " src="assets/icons/svg/trakker-events/alcohol-ok.svg" ngbTooltip="{{ 'Page.Labels.ALCOHOL_OK' | translate }}"/>
        <img *ngSwitchCase="'AlcoholNotOk'" class="svg " src="assets/icons/svg/trakker-events/alcohol-not-ok.svg" ngbTooltip="{{ 'Page.Labels.ALCOHOL_NOT_OK' | translate }}"/>
        <img *ngSwitchCase="'Shake'" class="svg" src="assets/icons/svg/trakker-events/shake.svg" ngbTooltip="{{ 'Page.Labels.SHAKE' | translate }}"/>
        <img *ngSwitchCase="'SquareSensitive'" class="svg" src="assets/icons/svg/trakker-events/sensor.svg" ngbTooltip="{{ 'Page.Labels.SENSOR_MESSAGE' | translate }}"/>
        <img *ngSwitchCase="'PowerCut'" class="svg" src="assets/icons/svg/trakker-events/powercut.svg" ngbTooltip="{{ 'Page.Labels.POWERCUT' | translate }}"/>
        <img *ngSwitchDefault class="svg" src="assets/icons/svg/trakker-events/alert.svg" ngbTooltip="{{ 'Page.Labels.ALERT' | translate }}"/>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="event != null && objectType == 'vehicle'">
    <ng-container [ngSwitch]="event.VehicleEventType">
      <img *ngSwitchCase="'Trip'" class="svg " src="assets/icons/svg/trip.svg" ngbTooltip="{{ 'Page.Labels.TRIP' | translate }}"/>
      <img *ngSwitchCase="'ManualTrip'" class="svg " src="assets/icons/svg/trip.svg" ngbTooltip="{{ 'Page.Labels.MANUALTRIP' | translate }}"/>
      <img *ngSwitchCase="'Stop'" class="svg " src="assets/icons/svg/stop.svg" ngbTooltip="{{ 'Page.Labels.STOP' | translate }}"/>
      <img *ngSwitchCase="'ManualStop'" class="svg " src="assets/icons/svg/stop.svg" ngbTooltip="{{ 'Page.Labels.MANUALSTOP' | translate }}"/>
      <img *ngSwitchCase="'UpdatedOdometer'" class="svg " src="assets/icons/svg/no-icon.svg" ngbTooltip="{{ 'Page.Labels.UPDATEDODOMETER' | translate }}"/>
      <!-- <img *ngSwitchCase="'UpdatedOdometer'" class="svg " src="assets/icons/svg/updated-odometer.svg" ngbTooltip="{{ 'Page.Labels.UPDATEDODOMETER' | translate }}"/> -->
      <img *ngSwitchCase="'ChangedDriver'" class="svg " src="assets/icons/svg/driver.svg" ngbTooltip="{{ 'Page.Labels.CHANGEDDRIVER' | translate }}"/>
      <img *ngSwitchCase="'SentInspectionReminder'" class="svg " src="assets/icons/svg/letter.svg" ngbTooltip="{{ 'Page.Labels.SENTINSPECTIONREMINDER' | translate }}"/>
      <img *ngSwitchCase="'SentServiceReminder'" class="svg " src="assets/icons/svg/letter.svg" ngbTooltip="{{ 'Page.Labels.SENTSERVICEREMINDER' | translate }}"/>
      <img *ngSwitchCase="'WorkRelatedUse'" class="svg " src="assets/icons/svg/business.svg" ngbTooltip="{{ 'Page.Labels.WORKRELATEDUSE' | translate }}"/>
      <img *ngSwitchCase="'PrivateUse'" class="svg " src="assets/icons/svg/leisure.svg" ngbTooltip="{{ 'Page.Labels.PRIVATEUSE' | translate }}"/>
      <img *ngSwitchCase="'CongestionTaxPassage'" class="svg " src="assets/icons/svg/congestion-tax.svg" ngbTooltip="{{ 'Page.Labels.CONGESTIONTAXPASSAGE' | translate }}"/>
      <img *ngSwitchDefault class="svg" src="assets/icons/svg/no-icon.svg" ngbTooltip="{{ 'Page.Labels.NA' | translate }}"/>
    </ng-container>
  </ng-container>
  <img *ngIf="event == null" class="svg" src="assets/icons/svg/trakker-events/n-a.svg" ngbTooltip="{{ 'Page.Labels.EVENT_TYPE_NA' | translate }}"/>
</ng-container>
<ng-container *ngIf="outputType == 'text'">
  <ng-container *ngIf="event == null">
    {{ 'Page.Labels.EVENT_TYPE_NA' | translate }}
  </ng-container>
  <ng-container *ngIf="event != null && objectType == 'trakker'">
    {{ 'Page.Labels.' + (outputText | uppercase) | translate }}
    <ng-container *ngIf="event.TriggerdGeofenceName != null">: {{ event.TriggerdGeofenceName }}</ng-container>
  </ng-container>
</ng-container>
